import React, { useEffect } from 'react'
import AuthManagerLayout from './AuthManagerLayout';
function AuthManager() {
    return (
        <div>
            <AuthManagerLayout />
        </div>
    )
}
export default AuthManager
