import React, { useEffect } from 'react';
import { Panel, StandardListItem, List, Button, Bar, Label, Card, Page, CardHeader, Icon} from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/idea-wall.js";
import "@ui5/webcomponents-icons/dist/gantt-bars.js";
import "@ui5/webcomponents-icons/dist/manager-insight.js";
import "@ui5/webcomponents-icons/dist/provision.js";
import "@ui5/webcomponents-icons/dist/time-account.js";
import "@ui5/webcomponents-icons/dist/employee.js";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import ShellBarComponent from '../../components/ShellBarComponent';



function EventManagerLayout() {
  useEffect(() => {
    document.title = 'CMS - Event Manager';
  }, []);
  const onCreateEvent = () => {
    navigate("/createEvent");
  };
  const onManageEvent = () => {
    navigate("/manageEventNew");
  };
  const onAttendance = () => {
    navigate("/attendance");
  };
  const onGenerateReport = () => {
    navigate("/recordGenerator");
  };
  const onSettings = () => { }
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/")
  };
  return (
    <div >
      <React.Fragment>
        <motion.div initial={{ opacity: 0 }} animate={{ width: "100%", opacity: 1 }} exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}>
          <ShellBarComponent />
          <Page header={<Bar startContent={<Button icon="nav-back" title="Go back" onClick={goHome} />}><Label>Event Manager</Label></Bar>} style={{ height: "90vh" }}>

            <Card style={{ width: '350px', padding: "1%" }}
              header={<CardHeader interactive="true" onClick={onCreateEvent} avatar={<Icon name="idea-wall" />} subtitleText="Add Event, Invite Groups, Members" titleText="Create Events" />}
            >
            </Card>

            <Card style={{ width: '350px', padding: "1%" }}
              header={<CardHeader interactive="true" onClick={onManageEvent} avatar={<Icon name="gantt-bars" />} subtitleText="Reminders, Edit Event etc" titleText="Manage Events" />}
            >
            </Card>
            <Card style={{ width: '350px', padding: "1%" }}
              header={<CardHeader interactive="true" onClick={onAttendance} avatar={<Icon name="employee" />} subtitleText="Take Attendance" titleText="Record Attendance" />}
            >
            </Card>
            <Card style={{ width: '350px', padding: "1%" }}
              header={<CardHeader interactive="true" onClick={onGenerateReport} avatar={<Icon name="manager-insight" />} subtitleText="Inactive Members, New Members" titleText="Report Generator" />}
            >
            </Card>
            {/*
            <Card style={{ width: '200px', padding: "1%" }}
              header={<CardHeader interactive="true" onClick={onSettings} avatar={<Icon name="provision" />} titleText="Settings" />}
            >
            </Card>
            
            <br />
            <Card
              className=""
              header={<CardHeader avatar={<Icon name="time-account" />} subtitleText="Pick up where you left off" titleText="Drafts" />}
              style={{
                width: '300px'
              }}
            >
              <List mode="Delete" onItemClick={()=>{alert("click")}} onItemDelete={()=>{alert("delete")}}>
                <StandardListItem>
                  List Item 1
                </StandardListItem>
                <StandardListItem>
                  List Item 2
                </StandardListItem>
                <StandardListItem>
                  List Item 3
                </StandardListItem>
              </List>
              <ui5-scroll-bar></ui5-scroll-bar>
            </Card>

            */}
            






            {/* <Card style={{padding:"1%"}}
                                header={<CardHeader avatar={<Icon name="person-placeholder" />} subtitleText="This Week" titleText="Upcoming Events"/>}
                                >
                            <Timeline >
                                <TimelineItem
                                    name="Sunday Worship "
                                    subtitleText="Sun, 29 January, 2023"
                                    titleText="Morning Session"
                                />
                                <TimelineItem
                                    name="Sunday Worship "
                                    subtitleText="Sun, 29 January, 2023"
                                    titleText="Afternoon Session"
                                />
                                <TimelineItem
                                    name="Women's Meeting"
                                    subtitleText="Wed, 25 January, 2023"
                                    titleText="Ladies Prayer Group"
                                />
                                <TimelineItem
                                    name="Childrens Bible Study"
                                    subtitleText="Thu, 26 January, 2023"
                                    titleText="Weekly Children Bible Study"
                                >
                                </TimelineItem>
                            </Timeline>
                            </Card>
                            <AnalyticalCard style={{padding:"1%"}} header={<AnalyticalCardHeader description="January 2023" scale="Souls" state="Good" subtitleText="Souls" titleText="Events Conducted" trend="Up" unitOfMeasurement="People" value="23"><NumericSideIndicator number="20" titleText="Target" unit="Souls" /><NumericSideIndicator number="15" state="Critical" titleText="Deviation" unit="%" /></AnalyticalCardHeader>}>
                            <LineChart
                                className="chromatic-ignore"
                                dataset={[
                                    {
                                      name: 'January',
                                      sessions: 300,
                                      users: 100,
                                      volume: 756
                                    },
                                    {
                                      name: 'February',
                                      sessions: 330,
                                      users: 230,
                                      volume: 880
                                    },
                                    {
                                      name: 'March',
                                      sessions: 404,
                                      users: 240,
                                      volume: 700
                                    },
                                    {
                                      name: 'April',
                                      sessions: 80,
                                      users: 280,
                                      volume: 604
                                    },
                                    {
                                      name: 'May',
                                      sessions: 300,
                                      users: 100,
                                      volume: 756
                                    },
                                    {
                                      name: 'June',
                                      sessions: 330,
                                      users: 230,
                                      volume: 880
                                    },
                                    {
                                      name: 'July',
                                      sessions: 470,
                                      users: 20,
                                      volume: 450
                                    },
                                    {
                                      name: 'August',
                                      sessions: 180,
                                      users: 220,
                                      volume: 104
                                    },
                                    {
                                      name: 'September',
                                      sessions: 360,
                                      users: 200,
                                      volume: 879
                                    },
                                    {
                                      name: 'October',
                                      sessions: 500,
                                      users: 250,
                                      volume: 200
                                    },
                                    {
                                      name: 'November',
                                      sessions: 404,
                                      users: 240,
                                      volume: 700
                                    },
                                    {
                                      name: 'December',
                                      sessions: 80,
                                      users: 280,
                                      volume: 604
                                    }
                                  ]}
                                  dimensions={[
                                    {
                                      accessor: 'name',
                                      formatter: function noRefCheck(){},
                                      interval: 0
                                    }
                                  ]}
                                  measures={[
                                    {
                                      accessor: 'users',
                                      formatter: function noRefCheck(){},
                                      label: 'People'
                                    },
                                    {
                                      accessor: 'sessions',
                                      formatter: function noRefCheck(){},
                                      hideDataLabel: true,
                                      label: 'Events'
                                    }
                                  ]}
                            />
                        </AnalyticalCard> */}

          </Page>
        </motion.div>
      </React.Fragment>
    </div>
  )
}
export default EventManagerLayout
