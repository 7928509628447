import React, { useContext, useEffect } from 'react'
import AuthContext from "../../contexts/AuthProvider";
import {motion} from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import AddMemberLayout from '../MemberManager/AddMemberLayout';
import ManageUsersLayout from '../MemberManager/ManageUsersLayout';
import MassCreateMembersLayout from '../MemberManager/MassCreateMembersLayout';
import MassCreateMembersResultsLayout from '../MemberManager/MassCreateMembersResultsLayout'
import { Page, Tab, Label, Button, Bar, TabSeparator, TabContainer } from '@ui5/webcomponents-react';
import { useState } from 'react';
import ShellBarComponent from '../../components/ShellBarComponent';
import apis from '../../apis/api';
function MemberManagerLayout() {
    const authUser = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
      document.title = 'CMS - Member Manager';
    });
    const goHome = ()=>{
      navigate('/');
    };
    return (
        <React.Fragment>
          <motion.div  initial={{opacity:0}} animate={{width:"100%", opacity:1}} exit={{x:window.innerWidth, transition:{duration: 0.5}}}>
               <ShellBarComponent/>
                <Page header={<Bar startContent={<Button icon="nav-back" title="Go back" onClick={goHome}/>}><Label>Membership Manager</Label></Bar>} style={{height: '100vh'}}>
                <TabContainer>
                      <Tab text="Manage Members" >
                          <ManageUsersLayout/>
                      </Tab>
                      <TabSeparator />
                      <Tab text="Add Members">
                          <AddMemberLayout/>
                      </Tab>
                      <TabSeparator />
                      <Tab text="Member Quick Entry">
                          <MassCreateMembersLayout/>
                      </Tab>
                      <TabSeparator />
                      <Tab text="Member Upload Results">
                          <MassCreateMembersResultsLayout/>
                      </Tab>
                    </TabContainer>
                </Page>
            </motion.div>
        </React.Fragment>
    )
}

export default MemberManagerLayout
