import React from 'react'
import MetadataManagerLayout from './MetadataManagerLayout';
function MetadataManager() {
    return (
        <div>
            <MetadataManagerLayout />
        </div>
    )
}
export default MetadataManager
