export function convertAddressToString(addressObj) {
    const addressComponents = [];
    const addIfNotEmpty = (key) => {
      if (addressObj[key] && addressObj[key]!=="N/A" && addressObj[key]!=="n/a") {
        addressComponents.push(addressObj[key]);
      }
    };
    addIfNotEmpty("AddressLine1");
    addIfNotEmpty("AddressLine2");
    addIfNotEmpty("Locality");
    addIfNotEmpty("City");
    addIfNotEmpty("Region");
    addIfNotEmpty("Country");
    var addressString = addressComponents.join(", ");
    if (addressObj.PostalCode &&  (addressObj.PostalCode !=="N/A") &&  (addressObj.PostalCode !=="n/a")) {
        addressString = addressString +" - "+addressObj.PostalCode;
    }
    return addressString;
  }
  
  export function convertToTimeSpanFormat(timeString) { //only works for input HH:MM
    const timeParts = timeString.match(/(\d+):(\d+)/);
    if (!timeParts) {
      throw new Error("Invalid time format");
    }
  
    const hours = parseInt(timeParts[1]);
    const minutes = parseInt(timeParts[2]);
  
    const formattedTimeSpan = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00.0000000`;
  
    return formattedTimeSpan;
  }

  export function convertToDateTimeFormat(dateString) {
    const dateInit = new Date(dateString);
    const day = dateInit.getDate();
    const month = dateInit.getMonth() + 1; // getMonth() returns month index (0-11), so add 1
    const year = dateInit.getFullYear();
    
    // Convert month to numeric format
    const monthIndex = new Date(`${month} 1`).getMonth() + 1;
    const monthPadded = monthIndex.toString().padStart(2, '0');

    const dayPadded = day.toString().padStart(2, '0');

    // Create a Date object
    const date = new Date(`${year}-${monthPadded}-${dayPadded}T00:00:00.000Z`);

    // Format the date to the desired ISO string format
    const isoString = date.toISOString();

    // Replace the last "Z" with "+00:00" and add the required microseconds
    const formattedDate = `${isoString.slice(0, -1)}.0000000+00:00`;

    return formattedDate;
  };

  export function formatDateDDHHHYYYY(inputDateStr) {
    // Parse the input date string into a Date object
    const inputDate = new Date(inputDateStr);
  
    // Define an array of month names
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    // Get the day, month, and year from the Date object
    const day = inputDate.getDate();
    const month = monthNames[inputDate.getMonth()];
    const year = inputDate.getFullYear();
  
    // Create the formatted date string
    const formattedDate = `${day} ${month}, ${year}`;
  
    return formattedDate;
  };

  export function formatTime(inputTimeStr) {
    // Parse the input time string
    const parts = inputTimeStr.split(":");
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
  
    // Determine whether it's AM or PM
    const period = hours < 12 ? "AM" : "PM";
  
    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;
  
    // Create the formatted time string
    const formattedTime = `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  
    return formattedTime;
  };

  export function isEndTimeLessThanStartTime(startTime, endTime){
    // Parse the time strings and create Date objects
    const startDate = new Date(`1970-01-01T${startTime}Z`);
    const endDate = new Date(`1970-01-01T${endTime}Z`);

    // Compare the time values
    return endDate < startDate;
  };
  
