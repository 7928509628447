import React from 'react'
import GroupManagerLayout from './GroupManagerLayout';
function GroupManager() {
    return (
        <div>
            <GroupManagerLayout />
        </div>
    )
}
export default GroupManager
