import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Dialog, Toast, Title, Input, MessageStrip, StandardListItem, Bar, List, Page, Button, Label, TableColumn, Table, TableRow, TableCell } from '@ui5/webcomponents-react';
import { Link, useNavigate } from "react-router-dom";
import "@ui5/webcomponents-icons/dist/nav-back.js";
import apis from '../../apis/api';
const USERS_URL = "api/accounts/users";
const GET_ROLE = "api/roles/";
const ROLES_URL = "api/roles";
const ASSIGN_ROLE = "api/accounts/user/";
const GET_USER = "api/accounts/user/";
let validUsersList = [];
let selectedRole = "";
let roleArray = [];
function RoleManagerLayout() {
  const toastRef = useRef();
  const userListRef = useRef();
  const unassignUserListRef = useRef();
  const showToast = () => {
    toastRef.current.show();
  };
  const dialogRef = useRef();
  const unassignDialogRef = useRef();
  const [message, setMessage] = useState("");
  const [rolesList, setRolesList] = useState([]);
  const [userList, setUserList, getUserList] = useState([]);
  const [roleName, setRoleName] = useState([]);
  const fetchData = async () => {
  const response = await apis.get(ROLES_URL, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
      },
    });
    setRolesList(response.data);
  };
  
  //getUserRoles
  const getUserInfo = async (id) => {
    try{
      const response = await apis.get(GET_USER+id, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        },
      });
      roleArray =  response.data.roles;
      roleArray.push(selectedRole);
      assignRoleToUser(id, roleArray, "assigned");
    }catch(err){
      console.log(err);
    }
  };
  const getUserInfoToRevoke = async (id) => {
    try{
      const response = await apis.get(GET_USER+id, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        },
      });
      roleArray =  response.data.roles;
      var i = roleArray.indexOf(selectedRole);
      if(i>-1){
        roleArray.splice(i,1);
        assignRoleToUser(id, roleArray, "revoked");
      }
      else{
        setMessage('Invalid user. Please refresh and try again');
        unassignHandleClose();
        showToast();
        return;
      }
    }catch(err){
      console.log(err);
    }
  };
  const fetchUsersInRole = async (role) => {
    const response = await apis.get(USERS_URL, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
      },
    });
    var data = response.data;
    validUsersList = [];
    for(var i=0; i<data.length;i++){
      if(data[i].roles.includes(role)){
        validUsersList.push(data[i]);
      }
    }
    setUserList(validUsersList);
    //console.log(validUsersList);
    setRoleName(role);
    selectedRole = role;
    unassignDialogRef.current.show();
    // console.log(userList);
  };
  // fetch user list for popover
    const fetchUserData = async (role) => {
      const response = await apis.get(USERS_URL, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        },
      });
      var data = response.data;
      validUsersList = [];
      for(var i=0; i<data.length;i++){
        if(!data[i].roles.includes(role)){
          validUsersList.push(data[i]);
        }
      }
      setUserList(validUsersList);
      //console.log(validUsersList);
      setRoleName(role);
      selectedRole = role;
      dialogRef.current.show();
      // console.log(userList);
    };
    // open popup
  const onAssignRole = async function(e){
    var roleId = e.currentTarget.id;
    try{
      const response = await apis.get(GET_ROLE+roleId, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        }
      });
      fetchUserData(response.data.name);
      // setMessage('User Deleted');
    }
    catch(err){
      if (!err?.response) {
        setMessage('No Server Response');
      } else if (err.response?.status === 400) {
        setMessage('Email or Username not available');
      } else {
        setMessage('Registration Failed')
      }
    }
  };

  //Assign button clicked in popover
  const assignRoleToUser = function(roleId, Roles, operation){
    try{
      const response = apis.put(ASSIGN_ROLE+roleId+'/roles', Roles,{
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        }
      });
      if(response)
      setMessage('Role '+operation+' successfully');
      if(operation === "assigned"){
        handleClose();
      }else{
        unassignHandleClose();
      }
      showToast();
    }
    catch(err){
      setMessage('Unable to assign role. Please contact your administrator.');
      unassignHandleClose();
      handleClose();
      showToast();
    }
  };

  //assign role calls and checks
  const assignRole = (e) => {
    var selecitems = [];
    userListRef.current.items.forEach(
      (i)=>{
        if(i.selected){
          selecitems.push(i.id);
        }
    });
    if(selecitems.length == 0){
      setMessage('No users were selected');
      handleClose();
      showToast();
    }
    else{
      selecitems.forEach((i)=>{
        getUserInfo(i);
      });
    }
  };
  const unAssignRole = (e) => {
    var selecitems = [];
    unassignUserListRef.current.items.forEach(
      (i)=>{
        if(i.selected){
          selecitems.push(i.id);
        }
    });
    if(selecitems.length == 0){
      setMessage('No users were selected');
      unassignHandleClose();
      showToast();
    }
    else{
      selecitems.forEach((i)=>{
        getUserInfoToRevoke(i);
      });
    }
  };
  const searchUserList = (e) => {
    console.log(e);
    var filteredData=[];
    if(e.target.value!=""){
      for(var i=0;i<validUsersList.length;i++){
        if(validUsersList[i].email.includes(e.target.value)){
          filteredData.push(validUsersList[i]);
        }
      }
    }
    else{
      filteredData = validUsersList;
    }
    setUserList(filteredData);
  };
  const handleClose = () => {
    dialogRef.current.close();
  };
  const unassignHandleClose = () => {
    unassignDialogRef.current.close();
  };
  const onRevokeRole = async function(e){
    var roleId = e.currentTarget.id;
    try{
      const response = await apis.get(GET_ROLE+roleId, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        }
      });
      fetchUsersInRole(response.data.name);
      // setMessage('User Deleted');
    }
    catch(err){
      if (!err?.response) {
        setMessage('No Server Response');
      } else if (err.response?.status === 400) {
        setMessage('Email or Username not available');
      } else {
        setMessage('Registration Failed')
      }
    }
  };

  useEffect(() => {
    try {
      fetchData();
    } catch (err) {
      if (!err.response) {

        setMessage("No Server Response");
      } else if (err.response.status === 400) {

        setMessage("Bad request.");
      } else if (err.response.status === 401) {

        setMessage("Authorization has been denied for this request.");
      } else {

        setMessage("Failed to fetch bloc data");
      }
      // showToast()
    }

  }, []);
  return (
    <>
<Page
style={{height: '500px'}}>
    <Table 
  columns={<>
  <TableColumn popinText="Role"><Label>Role</Label>
  </TableColumn><TableColumn demandPopin minWidth={600}><Label>Assign Role</Label></TableColumn>
  <TableColumn demandPopin minWidth={600}><Label>Unassign Role</Label></TableColumn></>}
  onLoadMore={function noRefCheck(){}}
  onPopinChange={function noRefCheck(){}}
  onSelectionChange={function noRefCheck(){}}
>
    {rolesList && rolesList.map(row => {
        return (
        <TableRow>
            <TableCell style={{textAlign:'start'}}>
            <Label>
            {row.name}
            </Label>
            </TableCell>
            <TableCell style={{textAlign:'start'}}>
            <Button id={row.id} onClick={onAssignRole} design="Positive">
            Assign Role
            </Button>
            </TableCell>
            <TableCell style={{textAlign:'start'}}>
            <Button id={row.id} onClick={onRevokeRole} design="Negative">
            Unassign Role
            </Button>
            </TableCell>
        </TableRow>
        
        );
    })}
    </Table>
    <>
    {ReactDOM.createPortal(
    <Dialog stretch="true" draggable="true" resizable="true" ref={dialogRef} 
    header={<Bar endContent={<Button design="Negative" onClick={handleClose}>x</Button>}><Title>Select Users</Title></Bar>}
    footer={<Bar design="Footer" 
     endContent={<Button design="Emphasized" onClick={assignRole}>Assign</Button>}/>}>
      <MessageStrip>
          Use the checkbox to select the users to assign {roleName} role and click on Assign.
      </MessageStrip>
      <div style={{height:"2%"}}/>
        <Input
        style={{width:"100%"}}
        onChange={searchUserList}
        placeholder="Enter users email and hit enter to search..."
         />
      <List ref={userListRef} mode="MultiSelect"
      >
        {userList && userList.map(row => {
        return (
          <StandardListItem id={row.id} additionalText={row.userName}>
          {row.email}
          </StandardListItem>
        );
        })}
      </List>
      <Toast ref={toastRef}>{message}</Toast>
    </Dialog>, document.body)}
    </>
    <>
    {ReactDOM.createPortal(
    <Dialog stretch="true" draggable="true" resizable="true" ref={unassignDialogRef} 
    header={<Bar endContent={<Button design="Negative" onClick={unassignHandleClose}>x</Button>}><Title>Select Users</Title></Bar>}
    footer={<Bar design="Footer" 
     endContent={<Button design="Emphasized" onClick={unAssignRole}>Unassign</Button>}/>}>
      <MessageStrip>
          Use the checkbox to select the users to unassign {roleName} role.
      </MessageStrip>
      <div style={{height:"2%"}}/>
        <Input
        style={{width:"100%"}}
        onChange={searchUserList}
        placeholder="Enter users email and hit enter to search..."
         />
      <List ref={unassignUserListRef} mode="MultiSelect"
        onItemClick={function noRefCheck(){}}
        onSelectionChange={function noRefCheck(){}}
      >
        {userList && userList.map(row => {
        return (
          <StandardListItem id={row.id} additionalText={row.userName}>
          {row.email}
          </StandardListItem>
        );
        })}
      </List>
      <Toast ref={toastRef}>{message}</Toast>
    </Dialog>, document.body)}
    </>
    <Toast ref={toastRef}>{message}</Toast>
  </Page>
  </>
  );
}

export default RoleManagerLayout;
