import React, { useContext, useEffect, useState, useRef } from 'react'
import { Token, ProgressIndicator, Dialog, Title, MessageStrip, ComboBox, ComboBoxItem, DatePicker, RadioButton, ObjectPageSection, FormGroup, Form, Toast, FormItem, Text, DynamicPageHeader, DynamicPageTitle, ObjectPage, Breadcrumbs, ObjectStatus, BreadcrumbsItem, Avatar, Link, Label, Button, Bar, FlexBox, TabContainer, Input } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
function AddTradRoyalDets (props) {
    const dialogClose = () => {
        props.dialogClose();
    };
    return(
        <>
        <div style={{height:"5vh"}}/>
        <Form
            columnsL={1}
            columnsXL={1}
            style={{
              alignItems: 'baseline'
            }}
          >
            <FormItem label="Traditional Position Type">
                    <ComboBox style={{width:"40%"}} placeholder='Position Type'>
                        <ComboBoxItem text="King"/>
                        <ComboBoxItem text="Queen"/>
                        <ComboBoxItem text="Minister"/>
                    </ComboBox>
                </FormItem>
                <FormItem label="Title">
                    <Input style={{width:"40%"}} placeholder='Title'/>
                </FormItem>
                <FormItem label="Name Of Traditional Stool">
                    <Input style={{width:"40%"}} placeholder='Name Of Traditional Stool'/>
                </FormItem>
                <FormItem label="Traditional Area">
                    <Input style={{width:"40%"}} placeholder='Traditional Area'/>
                </FormItem>
                <FormItem label="Town Of Community">
                    <Input style={{width:"40%"}} placeholder='Town Of Community'/>
                </FormItem>
                <FormItem label="Description">
                    <Input style={{width:"40%"}} placeholder='Description'/>
                </FormItem>
                <FormGroup>
                <FormItem label="">
                        <Button style={{width:"30%"}}>
                            Submit
                        </Button>
                        <Button onClick={dialogClose} style={{width:"10%"}}>
                            Cancel
                        </Button>
                </FormItem>
                </FormGroup>
          </Form>
          </>
    )
}
export default AddTradRoyalDets;