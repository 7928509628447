import React from "react";
import Login from "../pages/auth/Login/Login";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import PrivateRoute from "../contexts/PrivateRoute";
import Home from "../pages/Home/Home";
import AuthManager from "../pages/auth/AuthManager";
import MetadataManager from "../pages/Metadata/MetadataManager";
import MemberManager from "../pages/MemberManager/MemberManager";
import MemberDetail from "../pages/MemberManager/MemberDetail";
import ForgotPassword from "../pages/auth/ForgetPassword/ForgotPassword";
import GroupDetailLayout from "../pages/GroupManager/GroupDetailLayout";
import GroupManager  from "../pages/GroupManager/GroupManager";
import EventManager from "../pages/EventManager/EventManager";
import CreateEvent from "../pages/EventManager/CreateEvent";
import { Routes, Route, useLocation } from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';
import AppSettings from "../pages/Settings/AppSettings";
import ManageEvent from "../pages/EventManager/ManageEvent";
import ManageEventNew from "../pages/EventManager/ManageEventNew";
import EventDetail from "../pages/EventManager/EventDetail";
import Attendance from "../pages/EventManager/Attendance";
import RecordGenerator from "../pages/EventManager/RecordGenerator";
export function AnimatedRoutes(){
    const location = useLocation();
    return(
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route  path='/forgotPassword'  element={<ForgotPassword />}/>
                <Route  path='/' element={<PrivateRoute component={Home}/>}/>
                <Route  path='/login'  element={<Login />}/>
                <Route  path='*'  element={<ErrorPage />}/>
                <Route  path='/authManager'  element={<PrivateRoute component={AuthManager}/>} />
                <Route  path='/metaManager'  element={<PrivateRoute component={MetadataManager}/>} />
                <Route  path='/groupManager'  element={<PrivateRoute component={GroupManager}/>} />
                <Route  path='/groupDetail/:groupId'  element={<PrivateRoute component={GroupDetailLayout}/>} />
                <Route  path='/memberManager' element={<PrivateRoute component={MemberManager}/>} />
                <Route  path='/memberDetail/:memberId' element={<PrivateRoute component={MemberDetail}/>} />
                <Route path='/settings' element={<AppSettings/>}/>
                <Route path="/eventManager" element={<EventManager/>}/>
                <Route path="/createEvent" element={<CreateEvent/>}/>
                <Route path="/manageEvent" element={<ManageEvent/>}/>
                <Route path="/manageEventNew" element={<ManageEventNew/>}/>
                <Route  path='/eventDetail/:eventId' element={<EventDetail/>} />
                <Route path="/attendance" element={<Attendance/>}/>
                <Route path="/recordGenerator" element={<RecordGenerator/>}/>
        </Routes>
    </AnimatePresence>
  );
}
