import React, { useContext, useRef, useEffect } from 'react'
import AuthContext from "../../contexts/AuthProvider";
import {motion} from 'framer-motion';
import apis from '../../apis/api';
import countries from '../../assets/countries.json';
import { Text, Input, Toast, Table, Label, TableRow, MessageStrip, TableCell, Toolbar,TableColumn, BusyIndicator, RadioButton, ComboBoxItem, Button, FlexBox, ComboBox, FormItem, FormGroup, Form } from '@ui5/webcomponents-react';
import { useState } from 'react';
import $ from 'jquery'; 
var selectedKey = '';
function MemberGroupingLayout(props) {
    const authUser = useContext(AuthContext);
    const [entityList, setEntityList] = useState([]);
    const input1 = useRef();
    const parentZone = useRef();
    const input2 = useRef();
    const input3 = useRef();
    const input4 = useRef();
    const [description, enableDescription] = useState(false);
    const [parentZoneBox, setVisibleZone] = useState(false);
    const [parent, enableParent] = useState(false);
    const entityCatData = useRef();
    const entityCat = useRef();
    const Address1 = useRef();
    const Address2 = useRef();
    const Locality = useRef();
    const Region = useRef();
    const Pcode = useRef();
    const City = useRef();
    const Country = useRef();
    const [Columns, setColumns] = useState([]);
    const[rows, setRows] = useState([]);
    const [message, setMessage] = useState("");
    const [address, setEnableAddress] = useState(false);
    const [entityListData, setEntityListData] = useState([]);
    const [zoneData, setZoneData] = useState([]);
    const [create, setCreate] = useState(false);
    const [manage, setManage] = useState(false);
    const [entityListDropDown, setEntityListDropDown] = useState(false);
    const [input1Text, setText1] = useState("");
    const [input2Text, setText2] = useState("");
    const [input3Text, setText3] = useState("")
    const [entityBusyInd, setEntityBusyInd] = useState(false);
    const [entity, setEntity] = useState("");
    const toastRef = useRef();
    const showToast = () => {
        toastRef.current.show();
    };
    const prepareCreateComponent = (entity) => {
        setCreate(true);
        setManage(false);
        if(entity === "Community Zone"){
            setText1("Zone Name");
            setText2("Leader");
            setEnableAddress(true);
            enableDescription(false);
            setVisibleZone(false);
            enableParent(false);
        } else if(entity === "Home Cell"){
            setText1("Home Cell Name");
            setText2("Leader");
            enableDescription(false);
            setVisibleZone(true);
            enableParent(false);
            setEnableAddress(true);
        } else if(entity === "Group"){
            setText1("Group Name");
            setText3("Group Description");
            setText2("Leader");
            enableDescription(true);
            setVisibleZone(false);
            enableParent(true);
            setEnableAddress(false);

        }
    };
    const prepareViewComponent = async (entity) => {
        setCreate(false);
        setManage(true);
        var url = "";
        if(entity === "Community Zone"){
            setColumns(["Zone Name", "Zone Address", "Zone Leader" , ""]);
        } else if(entity === "Home Cell"){
            setColumns(["Home Cell Name", "Zone", "Address", "Leader", ""]);
        } else if(entity === "Group"){
            setColumns(["Group Name", "Description", "Parent Group", "Edit/Delete"]);
        }
        fetchViewData(entity);
    };
    const onDeleteItem = (e)=>{
        var recId = e.currentTarget.id;
        switch(props.entity){
            case "Community Zone":
                onDeleteRecord(URLS.DEL_COMM_ZONE,recId, entity);
                break;
            case "Home Cell":
                onDeleteRecord(URLS.DEL_HOME_CELL,recId, entity);
                break;
            case "Group":
                onDeleteRecord(URLS.DEL_GROUPS,recId, entity);
                break;
            default:
                break;
        }
    };
    const onDeleteRecord = async function(url, id, entity){
        try{
          const response = await apis.delete(url+id, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
          });
          fetchViewData(entity);
          setMessage(entity+' Deleted');
        }
        catch(err){
          if (!err?.response) {
            setMessage('No Server Response');
          } else if (err.response?.status === 400) {
            setMessage('Email or Username not available');
          } else {
            prepareViewComponent(entity);
            setMessage(entity+' Deleted');
          }
        }
        showToast();
    };
    const onEditItem = (e)=>{
        
    };
    const onEntitySelect = (e) => {
        var selectedItem = e.target.value;
        if(selectedItem===''){
            setEntityListDropDown(false);
        } else {
            setEntityListDropDown(true);
            switch(selectedItem){
                case "Block":
                    readEntityContent(selectedItem, URLS.READ_BLOCK);
                    break;
                case  "Country":
                    readEntityContent(selectedItem, URLS.READ_COUNTRY);
                    break;
                case  "RCC":
                    readEntityContent(selectedItem, URLS.READ_RCC);
                    break;
                case  "Area":
                    readEntityContent(selectedItem, URLS.READ_AREA);
                    break;
                case  "District":
                    readEntityContent(selectedItem, URLS.READ_DIST);
                    break;
                case  "Local Assembly":
                    readEntityContent(selectedItem, URLS.READ_LA);
                    break;
                default:
                    break;
            }
            
        }
    };
    const getEntityById = async(entityName, key) => {
        return key;
    };
    

    const fetchViewData = async (e) => {
        var selectedKey = '';
        $.each(e.currentTarget.items,(k,v)=>{if(v.selected){selectedKey=v.id;return;}})
        var url = '';
        switch(props.entity){
            case "Home Cell":
                url = URLS.READ_HOME_CELL_BY_ENTITY;
                break;
            case "Community Zone":
                url = URLS.READ_COMM_ZONE_BY_ENTITY;
                break;
            case "Group":
                url = URLS.READ_GROUP_BY_ENTITY;
                break;
            default:
                break;
        }
        // try{
            const response = await apis.get(url+selectedKey, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                }
            });
            var processedData = [];
            if(props.entity === "Community Zone"){
                response.data.forEach((i)=>{
                    var entityName = getEntityById()
                    processedData.push(
                    <TableRow>
                        <TableCell>
                            <Label>{i.zoneName}</Label>
                        </TableCell>
                        <TableCell>
                        <Text wrapping="true">{i.zoneAddress.addressLine1}, {i.zoneAddress.addressLine2}, {i.zoneAddress.locality}, {i.zoneAddress.city}, {i.zoneAddress.region}, {i.zoneAddress.country} - {i.zoneAddress.postalCode}</Text>
                        </TableCell>
                        <TableCell>
                            <Label>{i.zoneLeader}</Label>
                        </TableCell>
                        <TableCell>
                            <Toolbar>
                                <Button onClick={onEditItem} id={i.zoneId}>Edit</Button>
                                <Button onClick={onDeleteItem} id={i.zoneId} design="Negative">Delete</Button>
                            </Toolbar>
                        </TableCell>
                    </TableRow>
                    )
                });
            } else if(props.entity === "Home Cell"){
                response.data.forEach((i)=>{
                    processedData.push(
                    <TableRow>
                        <TableCell>
                            <Label>{i.homeCellName}</Label>
                        </TableCell>
                        <TableCell>
                            <Label>{i.zoneId}</Label>
                        </TableCell>
                        <TableCell>
                            <Text wrapping="true">{i.homeCellAddress.addressLine1}, {i.homeCellAddress.addressLine2}, {i.homeCellAddress.locality}, {i.homeCellAddress.city}, {i.homeCellAddress.region}, {i.homeCellAddress.country} - {i.homeCellAddress.postalCode}</Text>
                        </TableCell>
                        <TableCell>
                            <Label>{i.homeCellLeader}</Label>
                        </TableCell>
                        <TableCell>
                            <Toolbar>
                                <Button onClick={onEditItem} id={i.homeCellId}>Edit</Button>
                                <Button onClick={onDeleteItem} id={i.homeCellId} design="Negative">Delete</Button>
                            </Toolbar>
                        </TableCell>
                    </TableRow>
                    )
                });
            } else if(props.entity === "Group"){
                response.data.forEach((i)=>{
                    processedData.push(
                    <TableRow>
                        <TableCell>
                            <Label>{i.groupName}</Label>
                        </TableCell>
                        <TableCell>
                            <Label>{i.groupDescription}</Label>
                        </TableCell>
                        <TableCell>
                            <Label>{(i.parentGroup!=="00000000-0000-0000-0000-000000000000")?i.parentGroup:"No Parent Group"}</Label>
                        </TableCell>
                        <TableCell>
                            <Toolbar>
                                <Button onClick={onEditItem} id={i.groupId}>Edit</Button>
                                <Button onClick={onDeleteItem} id={i.groupId} design="Negative">Delete</Button>
                            </Toolbar>
                        </TableCell>
                    </TableRow>
                    )
                });
            }
           
            setRows(processedData);

        // }
        // catch(e){

        // }
    };

    const fetchRelevantData = async (e) =>{
        selectedKey = '';
        $.each(e.currentTarget.items,(k,v)=>{if(v.selected){selectedKey=v.id;return;}})
        var url = '';
        switch(props.entity){
            case "Home Cell":
                url = URLS.READ_COMM_ZONE_BY_ENTITY;
                break;
            case "Group":
                url = URLS.READ_GROUP_BY_ENTITY;
                break;
            default:
                break;
        }
        try{
            const response = await apis.get(url+selectedKey, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                }
            });
            switch(props.entity){
                case "Home Cell":
                    setZoneData(response.data);
                    break;
                case "Group":
                    setZoneData(response.data);
                    break;
                default:
                    break;
            }
        }
        catch(err){

        }
    }
    const handleCreate = async () => {
        var payload = null;
        var url = null;
        if(props.entity === 'Community Zone'){
            payload = {
                ZoneId: "1aa5860b-7dd7-4578-a959-c64f10321404",
                ZoneName: input1.current.value,
                ZoneAddress: {
                    AddressId: "2b373f6f-a884-4525-9edc-21f5c0c0a34c",
                    MemberId: "2b373f6f-a884-4525-9edc-21f5c0c0a34c",
                    AddressLine1: Address1.current.value,
                    AddressLine2: Address2.current.value,
                    Locality: Locality.current.value,
                    City: City.current.value,
                    Region: Region.current.value,
                    PostalCode: Pcode.current.value,
                    Country: Country.current.value
                },
                ZoneLeader: (input2.current.value==="Leader")?"00000000-0000-0000-0000-000000000000":input2.current.value,
                Entity:  entityCat.current.value,
                EntityKey: selectedKey
              };
            url = URLS.CREATE_COMM_ZONE;
        } else if(props.entity === 'Home Cell'){
            payload = {
                HomeCellId: "a900e8f2-95a8-40b4-a9d5-443134e237e5",
                HomeCellName: input1.current.value,
                ZoneId: parentZone.current.value.split(' (')[1].replace(')', ''),
                HomeCellAddress: {
                    AddressId: "2b373f6f-a884-4525-9edc-21f5c0c0a34c",
                    MemberId: "2b373f6f-a884-4525-9edc-21f5c0c0a34c",
                    AddressLine1: Address1.current.value,
                    AddressLine2: Address2.current.value,
                    Locality: Locality.current.value,
                    City: City.current.value,
                    Region: Region.current.value,
                    PostalCode: Pcode.current.value,
                    Country: Country.current.value
                },
                HomeCellLeader:(input2.current.value==="Leader")?"00000000-0000-0000-0000-000000000000":input2.current.value,
                Entity:  entityCat.current.value,
                EntityKey: selectedKey
              };
              url = URLS.CREATE_HOMECELL;
        } else if (props.entity === 'Group'){
            payload = {
                GroupId: "326049a4-11cd-4500-82c4-1810f3e014eb",
                GroupName: input1.current.value,
                GroupDescription: input3.current.value,
                GroupLeader: (input2.current.value==="Leader")?"00000000-0000-0000-0000-000000000000":input2.current.value,
                ParentGroup: (input4.current.value==="")?"00000000-0000-0000-0000-000000000000":input4.current.value.split(' (')[1].replace(')', ''),
                Entity: entityCat.current.value,
                EntityKey: selectedKey
            };
            url = URLS.CREATE_GROUP;
        }
        try{
            const response = await apis.post(url, payload, {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                },
            });
            setMessage(props.entity+" Created Successfully.");
            showToast();
            handleClearInput();
        }
        catch(err){
            setMessage("Server error. Please contact your administrator.");
            showToast();
        }
    };
    const handleClearInput = () => {
        input1.current.value ='';
        input2.current.value ='Leader';
        if(input3.current){
            input3.current.value ='';
            input4.current.value ='';
        }
        entityCat.current.value ='';
        entityCatData.current.value ='';
        if(Address1.current){
            Address1.current.value = '';
            Address2.current.value = '';
            Country.current.value = '';
            Pcode.current.value = '';
            City.current.value = '';
            Region.current.value = '';
            Locality.current.value = '';
        }
        if(parentZone.current){
            parentZone.current.value='';
        }
    };
    const readEntityContent = async (entity, url) => {
        setEntityBusyInd(true);
        try{
            const response = await apis.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                }
            });
            var processedData = [];
            if(entity==="Block"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.blockId, text:index.blockName})
                });
            }
            else if(entity==="Country"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.countryId, text:index.countryName})
                });
            } 
            else if(entity==="RCC"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.rccId, text:index.rccName})
                });
            }
            else if(entity==="Area"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.areaId, text:index.areaName})
                });
            }
            else if(entity==="District"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.districtId, text:index.districtName})
                });
            }
            else if(entity==="Local Assembly"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.assemblyId, text:index.assemblyName})
                });
            }
            setEntityListData(processedData);
            setEntityBusyInd(false);
        }
        catch(err){
            console.log(err);
            setEntityBusyInd(false);
            setEntityListDropDown(false);
        }
    };
    useEffect(() => {
        setEntity(props.entity);
        setEntityList(["Block", "Country", "RCC", "Area", "District", "Local Assembly"]);
        if(props.operation === "Create"){
            prepareCreateComponent(props.entity);
        } else if(props.operation === "Manage / View"){
            prepareViewComponent(props.entity);
        }
    }, [props]);
    return (
        <React.Fragment>
            <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }}>
                {create && 
                    <>
                            <MessageStrip hideCloseButton="true">
                                Please select the entity followed by the entity data to display relevant fields
                            </MessageStrip>
                         <Form columnsXL="1" style={{padding: "1%" ,alignItems: 'center'}}>
                            <FormGroup>
                                <FormItem label="">
                                    <ComboBox ref={entityCat} onChange={onEntitySelect} placeholder="Select Entity" style={{width: '50%'}}>
                                        {entityList && entityList.map(row=>{
                                            return <ComboBoxItem text={row}/>
                                        })}
                                    </ComboBox>
                                </FormItem>
                                <FormItem label=""> {entityListDropDown && 
                                        <ComboBox ref={entityCatData}  placeholder="Select Entity Data" onChange={fetchRelevantData} style={{width: '50%'}}>
                                            {entityListData && entityListData.map(row=>{
                                                return <ComboBoxItem id={row.id} text={row.text}/>
                                            })}
                                        </ComboBox>
                                }</FormItem>
                                </FormGroup>
                            <FormGroup titleText="Basic Details">
                                <FormItem label="">
                                    <Input ref={input1} placeholder={input1Text} style={{width: '50%'}} />
                                </FormItem>
                                {parentZoneBox && <FormItem label="">
                                    <ComboBox ref={parentZone}  placeholder="Select Community Zone" style={{width: '50%'}}>
                                    {zoneData && zoneData.map(row=>{
                                            return <ComboBoxItem text={row.zoneName+' ('+row.zoneId+')'}/>
                                        })}
                                    </ComboBox>
                                </FormItem>}
                                {description && <FormItem label="">
                                    <Input ref={input3} placeholder={input3Text} style={{width: '50%'}} />
                                </FormItem>}
                                <FormItem label="">
                                    <Input disabled="true" ref={input2} placeholder={input2Text} value={input2Text} style={{width: '38%'}} />
                                    <Button>Select Member</Button>
                                </FormItem>
                                {parent && <FormItem label="">
                                    <ComboBox ref={input4} placeholder="Parent Group(Leave it empty if no parent group exists)" style={{width: '50%'}}>
                                        {zoneData && zoneData.map(row=>{
                                            return <ComboBoxItem text={row.groupName+' ('+row.groupId+')'}/>
                                        })}
                                    </ComboBox>
                                </FormItem> }
                            </FormGroup>
                            {address && <FormGroup titleText={entity +" Address"}>
                                <FormItem label="">
                                    <Input ref={Address1} placeholder="Address Line 1" style={{width: '50%'}} />
                                </FormItem>
                                <FormItem label="">
                                    <Input ref={Address2} placeholder="Address Line 2" style={{width: '50%'}} />
                                </FormItem>
                                <FormItem label="">
                                    <Input ref={Locality} placeholder="Locality" style={{width: '50%'}} />
                                </FormItem>
                                <FormItem label="">
                                    <Input ref={Region} placeholder="Region" style={{width: '50%'}} />
                                </FormItem>
                                <FormItem label="">
                                    <Input ref={City} placeholder="City" style={{width: '50%'}} />
                                </FormItem>
                                <FormItem label="">
                                    <Input ref={Pcode} placeholder="Postal Code" style={{width: '50%'}} />
                                </FormItem>
                                <FormItem label="">
                                    <ComboBox ref={Country} placeholder="Country Of Birth" style={{ width: '50%' }}>
                    {countries && countries.map(row => {
                      return <ComboBoxItem text={row.country} />
                    })}
                  </ComboBox>
                                </FormItem>
                        </FormGroup>}
                        </Form>
                        <Form style={{padding: "1%",alignItems: 'center'}}>
                            <FormItem label="">
                                <Button design="Emphasized" style={{width: '25%'}} onClick={handleCreate}>
                                    {"Create "+entity}
                                </Button>
                                <Button style={{width: '25%'}} onClick={handleClearInput}>
                                    Clear
                                </Button>
                            </FormItem>
                        </Form>
                    </>
                }
                {manage &&
                    <>
                     <MessageStrip hideCloseButton="true" style={{padding:"1%"}}>
                                Please select the entity followed by the entity data to display {entity}s 
                            </MessageStrip>
                        <Form  style={{paddingBottom:"1%"}}>
                        <FormItem label="">
                                    <ComboBox ref={entityCat} onChange={onEntitySelect} placeholder="Select Entity" style={{width: '50%'}}>
                                        {entityList && entityList.map(row=>{
                                            return <ComboBoxItem text={row}/>
                                        })}
                                    </ComboBox>
                                </FormItem>
                                    {entityListDropDown && <FormItem label="">
                                        <ComboBox ref={entityCatData}  placeholder="Select Entity Data" onChange={fetchViewData} style={{width: '50%'}}>
                                            {entityListData && entityListData.map(row=>{
                                                return <ComboBoxItem id={row.id} text={row.text}/>
                                            })}
                                        </ComboBox>
                                        <BusyIndicator active={entityBusyInd}>
                                    </BusyIndicator>
                                </FormItem>}
                        </Form>
                        
                        <Table 
                        columns={
                        
                            Columns && Columns.map(
                                row => {
                                    return(
                                        <TableColumn demandPopin="true" popinText={row} minWidth={600}><Label>{row}</Label></TableColumn>
                                    )
                                }
                            )
                        
                        }
                        onLoadMore={function noRefCheck(){}}
                        onPopinChange={function noRefCheck(){}}
                        onSelectionChange={function noRefCheck(){}}
                        >
                            {rows}
                        </Table>
                    </>
                }
                <Toast duration="3000" ref={toastRef}>{message}</Toast>
            </motion.div>
        </React.Fragment>
    )
}

export default MemberGroupingLayout
const URLS = {
    READ_BLOCK : "api/metadata/getAllBlocks",
    READ_COUNTRY : "api/metadata/getAllCountries",
    READ_RCC: "api/metadata/getAllRccs",
    READ_AREA: "api/metadata/getAllAreas",
    READ_DIST: "api/metadata/getAllDistricts",
    READ_LA: "api/metadata/getAllLocalAssemblies",
    CREATE_COMM_ZONE:"api/memberGrouping/createCommunityZone",
    GET_PROF_CAT:"api/MetaProfessionCategories",
    CREATE_DISABILITY_TYPE:"api/DisabilityTypes",
    CREATE_PROF_CAT:"api/MetaProfessionCategories",
    DEL_DISABILITY_TYPE: "api/DisabilityTypes/",
    DEL_PROF_CAT: "api/MetaProfessionCategories/",
    READ_COMM_ZONE_BY_ENTITY:"api/memberGrouping/getZoneByEntityId?id=",
    CREATE_HOMECELL:"api/HomeCells",
    CREATE_GROUP:"api/Groups",
    READ_GROUP_BY_ENTITY:"api/memberGrouping/getGroupByEntityId?id=",
    READ_HOME_CELL_BY_ENTITY:"api/memberGrouping/getHomeCellsByEntityId?id=",
    DEL_COMM_ZONE:"api/memberGrouping/deleteCommunityZone?id=",
    DEL_HOME_CELL:"api/HomeCells/",
    DEL_GROUPS:"api/Groups/"
};