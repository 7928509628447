import React, {  useState, useEffect, useRef } from "react";
import { Table, TableColumn, Label,Bar, StandardListItem, TableCell, TableRow, Button  } from '@ui5/webcomponents-react';
import Avatar from './Avatar';
import { useNavigate } from 'react-router-dom';
import "@ui5/webcomponents-icons/dist/feeder-arrow.js"
function MemberListLayoutMultiple(props) {
    const navigate = useNavigate();
    const [memberData, setMemberData] = useState([]);
    const [orgUnit, setOrgButtonVisible] = useState(false);
    const memberListTable = useRef();
    const [memberManager, setMemberManagerVisible] = useState(true);
    useEffect(()=>{
        setMemberData(props.memberArray);
        if(props.source === "OrgUnit"){
            setOrgButtonVisible(true);
            setMemberManagerVisible(false);
        }
        else if(props.source === "MemberGroupTok"){
            setOrgButtonVisible(false);
            setMemberManagerVisible(false);
        }
        else{
            setOrgButtonVisible(false);
            setMemberManagerVisible(true);
        }
    },[props]);
    const returnMembers = () => {
        var listArray = [];
        var selectedRows = memberListTable.current.selectedRows;
        for(var i=0;i<selectedRows.length;i++){
            listArray.push(<StandardListItem id={selectedRows[i].id}>
            {selectedRows[i].childNodes[1].textContent}
          </StandardListItem>);
        }
        props.returnRef(listArray);
        props.closeDialog();
    };
    const onMemberDetailClick = (e) => {
        navigate('/memberDetail/'+e.target.id);
    };
    // const returnMember = (e) => {
    //     props.returnRef.current.value = e.target.id;
    //     props.closeDialog();
    // };
    return(
    <>
        <Table mode="MultiSelect" ref={memberListTable}
        columns = {
            <>
                <TableColumn popinText="Picture"><Label style={{paddingLeft:"5vw", fontWeight:"bold"}} >Picture</Label></TableColumn>
                <TableColumn popinText="Name"><Label style={{paddingLeft:"6vw", fontWeight:"bold"}} >Name</Label></TableColumn>
                <TableColumn demandPopin="true" popinText="Gender" minWidth={600}><Label style={{marginLeft:"6vw", fontWeight:"bold"}} >Gender</Label></TableColumn>
                <TableColumn demandPopin="false" popinText="Member Type" minWidth={600}><Label style={{marginLeft:"6vw", fontWeight:"bold"}} >Child Member</Label></TableColumn>
                <TableColumn demandPopin="false" popinText="Age" minWidth={600}><Label style={{marginLeft:"2vw", fontWeight:"bold"}}>Age</Label></TableColumn>
                <TableColumn demandPopin="true" popinText="Contact Number" minWidth={600}><Label style={{marginLeft:"4vw", fontWeight:"bold"}}>Contact Number</Label></TableColumn>
                <TableColumn demandPopin="true" popinText="Contact Number"><Label></Label></TableColumn>
            </>
        }>
            {memberData && memberData.map(row=>{
                            return <>
                            <TableRow type="Active" id={row.memberId}>
                                <TableCell>
                                <Avatar id={row.memberId}/>
                                </TableCell>
                                <TableCell>
                                <Label>
                                {row.title} {row.firstName} {row.middleName} {row.lastName}
                                </Label>
                                </TableCell>
                                <TableCell>
                                <Label>
                                {row.gender}
                                </Label>
                                </TableCell>
                                <TableCell>
                                <Label>
                                {(row.childFlag)?"Child":"Adult"}
                                </Label>
                                </TableCell>
                                <TableCell>
                                <Label>
                                {new Date().getFullYear() - new Date(row.dob).getFullYear()} 
                                </Label>
                                </TableCell>
                                <TableCell>
                                <Label>
                                {row.mobileNumber}
                                </Label>
                                </TableCell>
                                <TableCell>
                                    {memberManager&&<Button disabled="true" icon="feeder-arrow" design="Transparent"/>}
                                    {orgUnit && <Button id={row.title + " "+row.firstName + " "+row.middleName + " "+row.lastName+ " ("+row.memberId+")"} onClick={returnMembers}>Select Member</Button>}
                                </TableCell>
                            </TableRow>
                            </>
            })}
        </Table>
        <Bar design="Footer" 
                            endContent={<Button onClick={returnMembers} design="Emphasized" >Add Members</Button>}
                            />
    </>
    );
};

export default MemberListLayoutMultiple;