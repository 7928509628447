const helpTexts = {
    //Create Events Page
    eventType: `<p><strong>What are event types?</strong></p>
    <ol>
        <li>
            <p><strong>Closed Event</strong></p>
            <p>In a closed event, only the members invited to the event can attend the event. For example, if you invite members A and B, only they can attend the event and the application will not permit the attendance recording of any other guest members who attend the event. For example, this could be a committee meeting or a high level meeting that is meant only for a particular group of members.</p>
            <p>&nbsp;</p>
        </li>
        <li>
            <p><strong>Open Event</strong></p>
            <p>An open event, as the name suggests, is open to everyone. Anyone can attend the event invited, non invited, members, non-members etc., and the attendance application will allow recording of guests who are not members as well. For example, if you invite members A and B, and member C joins you as a guest, the attendance application will allow you to record the presence of member C using a special provision that records guest information (name, contact information, address, etc.,). The details are not mandatory, but whatever details the guest would like to provide, the usher/volunteer will be able to record in the attendance application.</p>
            <p>&nbsp;</p>
        </li>
        <li>
            <p><strong>Registration</strong></p>
            <p>A new section appears in the event creation wizard which allows you to create a link for the event and allows users to register for the event using the link. The event creator or delegate can decide on the number of seats available, waiting list, etc., for the event depending on their preference. The registration form can also be customized depending on your needs. You will be able to request about 5 fields of information based on your choice. For example (name, age, church, school, gender etc.,).</p>
        </li>
    </ol>`,
    eventVenue: `<h2 style="margin-left:0px;"><strong>Venue Selection Page</strong></h2>
    <p style="margin-left:0px;">The Venue Selection page in the Event Manager app allows users to choose the venue for their event. Users have three options for selecting a venue:</p>
    <p style="margin-left:0px;"><strong>1. Assembly</strong></p>
    <ul>
        <li>
            <p style="margin-left:0px;"><strong>Description</strong>: Select this option to choose a Church Of Pentecost assembly registered in the application.</p>
        </li>
        <li>
            <p style="margin-left:0px;"><strong>Details</strong>: When you select an assembly, the venue address is automatically populated with the address of the selected assembly. There is no need to manually enter the address.</p>
        </li>
    </ul>
    <p style="margin-left:0px;"><strong>2. Other Address</strong></p>
    <ul>
        <li>
            <p style="margin-left:0px;"><strong>Description</strong>: Select this option to enter a custom address for your event.</p>
        </li>
        <li>
            <p style="margin-left:0px;"><strong>Required Fields</strong>:</p>
            <ul>
                <li>
                    <p style="margin-left:0px;"><strong>Location Name</strong>: Enter the name of the location.</p>
                </li>
                <li>
                    <p style="margin-left:0px;"><strong>Address Line 1</strong>: Enter the primary address line.</p>
                </li>
                <li>
                    <p style="margin-left:0px;"><strong>Postal Code</strong>: Enter the postal code.</p>
                </li>
            </ul>
        </li>
        <li>
            <p style="margin-left:0px;"><strong>Details</strong>: These fields are mandatory and must be filled in to proceed. You can provide additional address details in the optional fields if needed.</p>
        </li>
    </ul>
    <p style="margin-left:0px;"><strong>3. Online</strong></p>
    <ul>
        <li>
            <p style="margin-left:0px;"><strong>Description</strong>: Select this option to conduct an online event.</p>
        </li>
        <li>
            <p style="margin-left:0px;"><strong>Supported Platforms</strong>:</p>
            <ul>
                <li>
                    <p style="margin-left:0px;">Google Meet</p>
                </li>
                <li>
                    <p style="margin-left:0px;">MS Teams</p>
                </li>
                <li>
                    <p style="margin-left:0px;">Zoom</p>
                </li>
            </ul>
        </li>
        <li>
            <p style="margin-left:0px;"><strong>Required Field</strong>:</p>
            <ul>
                <li>
                    <p style="margin-left:0px;"><strong>Invite Link</strong>: Enter the invite link for the online meeting.</p>
                </li>
            </ul>
        </li>
        <li>
            <p style="margin-left:0px;"><strong>Details</strong>: It is mandatory to provide the invite link to create the meeting.</p>
        </li>
    </ul>
    <h3 style="margin-left:0px;"><strong>Note</strong></h3>
    <p style="margin-left:0px;">All the information entered can be edited later in the Manage Events app. If you need to make changes after creating the event, simply go to the Manage Events app and update the details before publishing the event.</p>`,
    eventDateTime: `<h6>Entering Date &amp; Time of the Event</h6>
    <p>This page allows users to set the date and time for their events. Users can specify if the event is a one-time occurrence or a recurring event. Based on the user's choice, appropriate options are displayed to capture the necessary information.</p>
    <p>&nbsp;</p>
    <p><strong>Components</strong></p>
    <ol>
        <li><strong>Recurring Event Checkbox</strong>
            <ul>
                <li><strong>Label:</strong> "Recurring Event"</li>
                <li><strong>Type:</strong> Checkbox</li>
                <li><strong>Description:</strong> When checked, additional options for setting the recurrence pattern, start date, end date, and the time of the event will be displayed. If unchecked, options for setting a single occurrence date and time will be shown.</li>
            </ul>
        </li>
        <li><strong>Recurrence Pattern Options (Visible if "Recurring Event" is checked)</strong>
            <ul>
                <li><strong>Day of the Week Check boxes</strong>
                    <ul>
                        <li><strong>Labels:</strong> "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"</li>
                        <li><strong>Type:</strong> Checkbox</li>
                        <li><strong>Description:</strong> Allows the user to select which days of the week the event should recur. Multiple days can be selected.</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><strong>Event Date Range (Visible if "Recurring Event" is checked)</strong>
            <ul>
                <li><strong>Start Date</strong>
                    <ul>
                        <li><strong>Label:</strong> "Start Date"</li>
                        <li><strong>Type:</strong> Date Picker</li>
                        <li><strong>Description:</strong> The date on which the recurrence starts.</li>
                    </ul>
                </li>
                <li><strong>End Date</strong>
                    <ul>
                        <li><strong>Label:</strong> "End Date"</li>
                        <li><strong>Type:</strong> Date Picker</li>
                        <li><strong>Description:</strong> The date on which the recurrence ends.</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><strong>Event Time (Visible if "Recurring Event" is checked)</strong>
            <ul>
                <li><strong>Start Time</strong>
                    <ul>
                        <li><strong>Label:</strong> "Start Time"</li>
                        <li><strong>Type:</strong> Time Picker</li>
                        <li><strong>Description:</strong> The time at which the event starts on the selected recurring days.</li>
                    </ul>
                </li>
                <li><strong>End Time</strong>
                    <ul>
                        <li><strong>Label:</strong> "End Time"</li>
                        <li><strong>Type:</strong> Time Picker</li>
                        <li><strong>Description:</strong> The time at which the event ends on the selected recurring days.</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><strong>Single Occurrence Event Date and Time (Visible if "Recurring Event" is unchecked)</strong>
            <ul>
                <li><strong>Event Date</strong>
                    <ul>
                        <li><strong>Label:</strong> "Event Date"</li>
                        <li><strong>Type:</strong> Date Picker</li>
                        <li><strong>Description:</strong> The date on which the one-time event occurs.</li>
                    </ul>
                </li>
                <li><strong>Start Time</strong>
                    <ul>
                        <li><strong>Label:</strong> "Start Time"</li>
                        <li><strong>Type:</strong> Time Picker</li>
                        <li><strong>Description:</strong> The time at which the event starts.</li>
                    </ul>
                </li>
                <li><strong>End Time</strong>
                    <ul>
                        <li><strong>Label:</strong> "End Time"</li>
                        <li><strong>Type:</strong> Time Picker</li>
                        <li><strong>Description:</strong> The time at which the event ends.</li>
                    </ul>
                </li>
            </ul>
        </li>
    </ol>
    <h3>User Interaction Flow</h3>
    <ol>
        <li><strong>Selecting Recurring Event</strong>
            <ul>
                <li>User checks the "Recurring Event" checkbox.</li>
                <li>The app displays checkboxes for each day of the week.</li>
                <li>User selects one or more days.</li>
                <li>User selects the start date and end date for the recurrence.</li>
                <li>User selects the start time and end time for the event on the selected recurring days.</li>
            </ul>
        </li>
        <li><strong>Selecting One-time Event</strong>
            <ul>
                <li>User unchecks the "Recurring Event" checkbox.</li>
                <li>The app displays date and time pickers for a single occurrence.</li>
                <li>User selects the event date.</li>
                <li>User selects the start time and end time for the event.</li>
            </ul>
        </li>
    </ol>
    <h3>Example Scenarios</h3>
    <ol>
        <li><strong>Creating a Weekly Meeting</strong>
            <ul>
                <li>User checks "Recurring Event".</li>
                <li>Selects "Monday" and "Wednesday".</li>
                <li>Sets the start date to "2024-06-01" and the end date to "2024-12-31".</li>
                <li>Sets the start time to "10:00 AM" and the end time to "11:00 AM".</li>
            </ul>
        </li>
        <li><strong>Scheduling a Single Workshop</strong>
            <ul>
                <li>User un checks "Recurring Event".</li>
                <li>Sets the event date to "2024-07-15".</li>
                <li>Sets the start time to "09:00 AM" and the end time to "12:00 PM".</li>
            </ul>
        </li>
    </ol>`,
    eventContact: `<h6>Contacts Page</h6>
    <p>This page allows users to maintain various types of contact details associated with an event. Users can add phone numbers, email addresses, and links, providing a comprehensive set of contact options for event management.</p>
    <p><strong>Components</strong></p>
    <ol>
        <li><strong>Contact Type Dropdown</strong>
            <ul>
                <li><strong>Label:</strong> "Contact Type"</li>
                <li><strong>Type:</strong> Dropdown</li>
                <li><strong>Description:</strong> Allows the user to select the type of contact detail they want to add. Options include:<ul>
                        <li>Phone</li>
                        <li>Email</li>
                        <li>Link</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><strong>Description Field</strong>
            <ul>
                <li><strong>Label:</strong> "Description"</li>
                <li><strong>Type:</strong> Text Input</li>
                <li><strong>Description:</strong> A brief description or label for the contact detail. This helps identify the purpose or type of the contact information.</li>
            </ul>
        </li>
        <li><strong>Contact Detail Field</strong>
            <ul>
                <li><strong>Label:</strong> "Contact Detail"</li>
                <li><strong>Type:</strong> Text Input</li>
                <li><strong>Description:</strong> Depending on the selected contact type, this field will be used to enter the phone number, email address, or URL.</li>
            </ul>
        </li>
        <li><strong>Add Contact Button</strong>
            <ul>
                <li><strong>Label:</strong> "Add Contact"</li>
                <li><strong>Type:</strong> Button</li>
                <li><strong>Description:</strong> Adds a new row to the list for users to maintain additional contacts.</li>
            </ul>
        </li>
        <li><strong>Contacts List</strong>
            <ul>
                <li><strong>Label:</strong> "Contacts List"</li>
                <li><strong>Type:</strong> List/Table</li>
                <li><strong>Description:</strong> Displays the list of added contacts with their type, description, and detail. Provides options to delete contacts.</li>
            </ul>
        </li>
    </ol>
    <h3>User Interaction Flow</h3>
    <ul>
        <li><strong>Adding a Phone Number</strong>
            <ul>
                <li>User selects "Phone" from the Contact Type dropdown.</li>
                <li>Enters a description in the Description field (e.g., "Event Manager").</li>
                <li>Enters the phone number in the Contact Detail field (e.g., "7904412267").</li>
                <li>Clicks the "Add Contact" button.</li>
                <li>The phone number is added to the Contacts List with the type "Phone".</li>
            </ul>
        </li>
        <li><strong>Adding an Email Address</strong>
            <ul>
                <li>User clicks on the Add Contact button to add a new row.</li>
                <li>User selects "Email" from the Contact Type dropdown.</li>
                <li>Enters a description in the Description field (e.g., "Support").</li>
                <li>Enters the email address in the Contact Detail field (e.g., "<a rel="noreferrer">support@example.com</a>").</li>
                <li>Clicks the "Add Contact" button.</li>
                <li>The email address is added to the Contacts List with the type "Email".</li>
            </ul>
        </li>
        <li><strong>Adding a Link</strong>
            <ul>
                <li>User clicks on the Add Contact button to add a new row.</li>
                <li>User selects "Link" from the Contact Type dropdown.</li>
                <li>Enters a description in the Description field (e.g., "Facebook").</li>
                <li>Enters the URL in the Contact Detail field (e.g., "<a target="_blank" rel="noopener noreferrer" href="http://facebook.com/eventpage"><a rel="noreferrer" target="_new">http://facebook.com/eventpage</a></a>").</li>
                <li>Clicks the "Add Contact" button.</li>
                <li>The link is added to the Contacts List with the type "Link".</li>
            </ul>
        </li>
    </ul>
    <h3>Example Scenarios</h3>
    <ol>
        <li><strong>Maintaining Multiple Contact Details</strong>
            <ul>
                <li>User can add multiple phone numbers, emails, and links by repeating the steps for each type of contact.</li>
                <li>For example:<ul>
                        <li>[Phone, John Samuel (Event Manager), 1234568989]</li>
                        <li>[Phone, George (Event Co-ordinator), 9876543299]</li>
                        <li>[Email, Support, <a rel="noreferrer">support@example.com</a>]</li>
                        <li>[Link, Facebook, <a target="_blank" rel="noopener noreferrer" href="http://facebook.com/eventpage"><a rel="noreferrer" target="_new">http://facebook.com/eventpage</a></a>]</li>
                    </ul>
                </li>
            </ul>
        </li>
    </ol>`,
    eventAttendee: `<p><strong>Event Attendee List Page</strong></p>
    <p>This section allows users to invite various types of attendees to an event. Users can invite Assemblies, Groups, Community Cells, Home Cells, or Individuals. Depending on the type of invitee selected, the interface will adapt to allow the user to specify the details.</p>
    <p><strong>Example Scenarios</strong></p>
    <ul>
        <li><strong>Inviting a Full Assembly</strong>
            <ul>
                <li>Select "Assemblies" from the Invitee Type drop down.</li>
                <li>Select "Assembly A" from the assemblies drop down.</li>
                <li>Click "Add Invitee".</li>
                <li>"Assembly A" is added to the Attendees List.</li>
            </ul>
        </li>
        <li><strong>Inviting Multiple Individuals</strong>
            <ul>
                <li>Click on the Add Row button to add a new item listed.</li>
                <li>Select "Individuals" from the Invitee Type drop down.</li>
                <li>Enter "<a rel="noreferrer">john@example.com</a>" and press Enter.</li>
                <li>Enter "<a rel="noreferrer">jane@example.com</a>" and press Enter.</li>
                <li>Click "Add Invitee".</li>
                <li>"<a rel="noreferrer">john@example.com</a>" and "<a rel="noreferrer">jane@example.com</a>" are added to the Attendees List.</li>
            </ul>
        </li>
    </ul>
    <p><strong>Components</strong></p>
    <ol>
        <li><strong>Invitee Type Drop down</strong>
            <ul>
                <li><strong>Label:</strong> "Invitee Type"</li>
                <li><strong>Type:</strong> Drop down</li>
                <li><strong>Description:</strong> Allows the user to select the type of invitee they want to add. Options include:<ul>
                        <li>Assemblies</li>
                        <li>Groups</li>
                        <li>Community Cells</li>
                        <li>Home Cells</li>
                        <li>Individuals</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><strong>Invitee Detail Field (Dynamic)</strong>
            <ul>
                <li><strong>Label:</strong> "Invitee Detail"</li>
                <li><strong>Type:</strong> Dropdown or Multi Input Box</li>
                <li><strong>Description:</strong> This field changes based on the selected invitee type:<ul>
                        <li><strong>Assemblies:</strong> Dropdown list of assemblies.</li>
                        <li><strong>Groups:</strong> Dropdown list of groups.</li>
                        <li><strong>Community Cells:</strong> Dropdown list of community cells.</li>
                        <li><strong>Home Cells:</strong> Dropdown list of home cells.</li>
                        <li><strong>Individuals:</strong> Multi input box for entering individual email addresses.</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li><strong>Add Invitee Button</strong>
            <ol>
                <li><strong>Label:</strong> "Add Invitee"</li>
                <li><strong>Type:</strong> Button</li>
                <li><strong>Description:</strong> Adds the selected or entered invitee(s) to the list of attendees for the event.</li>
            </ol>
        </li>
        <li><strong>Attendees List</strong>
            <ol>
                <li><strong>Label:</strong> "Attendees List"</li>
                <li><strong>Type:</strong> List/Table</li>
                <li><strong>Description:</strong> Displays the list of added invitees with their type and detail. Provides options to remove invitees.</li>
            </ol>
        </li>
    </ol>
    <p><strong>User Interaction Flow</strong></p>
    <ol>
        <li><strong>Inviting an Assembly</strong>
            <ol>
                <li>User selects "Assemblies" from the Invitee Type dropdown.</li>
                <li>A new dropdown with the list of assemblies appears in the Invitee Detail field.</li>
                <li>User selects the desired assembly.</li>
                <li>Clicks the "Add Invitee" button.</li>
                <li>The selected assembly is added to the Attendees List.</li>
            </ol>
        </li>
        <li><strong>Inviting a Group</strong>
            <ol>
                <li>User selects "Groups" from the Invitee Type dropdown.</li>
                <li>A new dropdown with the list of groups appears in the Invitee Detail field.</li>
                <li>User selects the desired group.</li>
                <li>Clicks the "Add Invitee" button.</li>
                <li>The selected group is added to the Attendees List.</li>
            </ol>
        </li>
        <li><strong>Inviting a Community Cell</strong>
            <ol>
                <li>User selects "Community Cells" from the Invitee Type dropdown.</li>
                <li>A new dropdown with the list of community cells appears in the Invitee Detail field.</li>
                <li>User selects the desired community cell.</li>
                <li>Clicks the "Add Invitee" button.</li>
                <li>The selected community cell is added to the Attendees List.</li>
            </ol>
        </li>
        <li><strong>Inviting a Home Cell</strong>
            <ol>
                <li>User selects "Home Cells" from the Invitee Type dropdown.</li>
                <li>A new dropdown with the list of home cells appears in the Invitee Detail field.</li>
                <li>User selects the desired home cell.</li>
                <li>Clicks the "Add Invitee" button.</li>
                <li>The selected home cell is added to the Attendees List.</li>
            </ol>
        </li>
        <li><strong>Inviting Individuals</strong>
            <ol>
                <li>User selects "Individuals" from the Invitee Type dropdown.</li>
                <li>A multi input box appears in the Invitee Detail field.</li>
                <li>User enters an email address and presses Enter; the email is converted to a token.</li>
                <li>User can enter multiple emails this way.</li>
                <li>Clicks the "Add Invitee" button.</li>
                <li>The entered email addresses are added to the Attendees List.</li>
            </ol>
        </li>
    </ol>`,
    //Create Members
    createMembers:`
    <p><strong>[SUPPORTED FILE FORMAT FOR UPLOAD IS .CSV]</strong></p>
    <ul>
        <li><strong>Create members using the standard template</strong>
            <ol>
                <li>Download the standard template from <a target="_blank" rel="noopener noreferrer" href="https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/MemberTemplate.csv" download="file">here</a> or by clicking the Download Template button.</li>
                <li>Now, copy the member data into this template carefully using excel or other worksheet applications.</li>
                <li>
                    <p>Make sure that once you enter the data into the Excel you save it in .csv format.</p>
                    <p>How to save a file in .csv format in MS Excel?</p>
                    <ul>
                        <li>Go to <b class="ocpUI" style="box-sizing:border-box;font-family:&quot;Segoe UI&quot;, &quot;Segoe UI Web&quot;, wf_segoe-ui_normal, &quot;Helvetica Neue&quot;, &quot;BBAlpha Sans&quot;, &quot;S60 Sans&quot;, Arial, sans-serif;"><strong>File</strong></b> &gt; <b class="ocpUI" style="box-sizing:border-box;font-family:&quot;Segoe UI&quot;, &quot;Segoe UI Web&quot;, wf_segoe-ui_normal, &quot;Helvetica Neue&quot;, &quot;BBAlpha Sans&quot;, &quot;S60 Sans&quot;, Arial, sans-serif;"><strong>Save As</strong></b>.</li>
                        <li>The <b class="ocpUI" style="box-sizing:border-box;font-family:&quot;Segoe UI&quot;, &quot;Segoe UI Web&quot;, wf_segoe-ui_normal, &quot;Helvetica Neue&quot;, &quot;BBAlpha Sans&quot;, &quot;S60 Sans&quot;, Arial, sans-serif;"><strong>Save As</strong></b> dialog box appears.</li>
                        <li>In the <b class="ocpUI" style="box-sizing:border-box;font-family:&quot;Segoe UI&quot;, &quot;Segoe UI Web&quot;, wf_segoe-ui_normal, &quot;Helvetica Neue&quot;, &quot;BBAlpha Sans&quot;, &quot;S60 Sans&quot;, Arial, sans-serif;"><strong>Save as type</strong></b> box, choose the text file format for the worksheet as .csv.</li>
                        <li>Select the location to save your file and click on save.</li>
                    </ul>
                </li>
                <li>Now click on the upload file button and select the file from your computer.</li>
                <li>Make sure you select the assembly you want the members to be created under. (One upload can only have members from one assembly)</li>
                <li>You can see a preview pane that appears in the bottom section. The application runs a pre-check before uploading the data.</li>
                <li>Make sure that the first column in the preview table says <span style="color:#33691e;"><u>Passed</u></span> for all the records. If you see any records with a status <span style="color:#b71c1c;"><u>Check Failed</u> </span><span style="color:#263238;">then please check if the record is entered correctly and re-upload the .csv file.</span></li>
                <li><span style="color:#263238;">If all the records have passed the pre-check (status column has </span><span style="color:#33691e;"><u>Passed</u></span><span style="color:#263238;">) then click on the <strong>Create Members </strong>button.</span></li>
                <li><span style="color:#263238;">You can see the results of the Member Upload in the <strong>Member Upload Results</strong> section.</span></li>
            </ol>
        </li>
    </ul>
    `
  };
  
  export default helpTexts;
  