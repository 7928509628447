import React, { useContext, useRef, useEffect } from 'react'
import apis from '../../apis/api';
import { Input, StepInput, SuggestionItem, Button, Label, ComboBox, FormItem, Toast, Dialog, Bar, Title, MessageStrip, FormGroup, Form, FlexBox, ComboBoxItem } from '@ui5/webcomponents-react';
import { useState } from 'react';
function AddEditCareerProfile(props) {   
    const [addOrgContent, setAddOrgContent] = useState();
    const cancelOrgAdd = () => {
        setAddOrgContent(null);
    };
    const dialogClose = () => {
        props.dialogClose();
    };
    const onAddOrgClick = () => {
        setAddOrgContent(<>
            <Form 
             columnsL={1}
             columnsXL={1}
             style={{
               alignItems: 'baseline'
             }}
            title="Add Organization">
            <FormGroup titleText="Orgazniaztion Details">
                <FormItem>
                    <Input style={{width:"100%"}} placeholder="Name of organization"/>
                </FormItem>
                <FormItem>
                    <ComboBox style={{width:"100%"}} placeholder='Organization Type'>
                        <ComboBoxItem text="Pepsi"/>
                        <ComboBoxItem text="Cocacola"/>
                    </ComboBox>
                </FormItem>
                <FormItem>
                    <ComboBox style={{width:"100%"}} placeholder='Business Type'>
                        <ComboBoxItem text="Pepsi"/>
                        <ComboBoxItem text="Cocacola"/>
                    </ComboBox>
                </FormItem>
                </FormGroup>
                <FormGroup titleText="Orgazniaztion Address">
                <FormItem>
                    <Input  placeholder="Address Line 1" />
                </FormItem>
                <FormItem>
                    <Input placeholder="Address Line 2" />
                </FormItem>
                <FormItem>
                    <Input  placeholder="Locality" />
                </FormItem>
                <FormItem>
                    <Input placeholder="City" />
                </FormItem>
                <FormItem>
                    <Input placeholder="Region"/>
                </FormItem>
                <FormItem>
                    <Input  placeholder="Country" />
                </FormItem>
                <FormItem>
                    <Input placeholder="Postal Code"/>
                </FormItem>
                <FormItem>
                    <FlexBox direction='Row' style={{width:"100%"}}>
                        <Button style={{width:"80%"}}>
                            Add Organization
                        </Button>
                        <Button onClick={cancelOrgAdd} style={{width:"50%"}}>
                            Cancel
                        </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>);
    };
    return(
        <>
        <div style={{height:"5vh"}}/>
            <Form
            columnsL={1}
            columnsXL={1}
            style={{
              alignItems: 'baseline'
            }}
          >
                <FormItem label="Organization Details">
                    <FlexBox direction='Column'>
                    <FlexBox direction="Row"><ComboBox style={{width:"50%"}} placeholder='Organization'>
                        <ComboBoxItem text="Pepsi"/>
                        <ComboBoxItem text="Cocacola"/>
                    </ComboBox>
                    <Button onClick={onAddOrgClick}>+</Button></FlexBox>
                    <FlexBox direction="Row"><Label>Select an organization from the suggestions or click on the + button to add a new organization</Label></FlexBox>
                    <FlexBox direction='Row'>{addOrgContent}</FlexBox>
                    </FlexBox>
                </FormItem>
                <FormItem label="Career Type">
                    <ComboBox style={{width:"40%"}} placeholder='Career Type'>
                        <ComboBoxItem text="Engineer"/>
                        <ComboBoxItem text="Tehnician"/>
                    </ComboBox>
                </FormItem>
                <FormItem label="Name of Career">
                    <Input style={{width:"40%"}} placeholder='Career Name'/>
                </FormItem>
                <FormItem label="Area Of Expertise">
                    <Input style={{width:"40%"}} placeholder='Career Name'/>
                </FormItem>
                <FormItem label="Current Position">
                    <Input style={{width:"40%"}} placeholder='Career Name'/>
                </FormItem>
                <FormItem label="Years of experience">
                    <StepInput style={{width:"40%"}}/>
                </FormItem>
                <FormGroup>
                <FormItem label="">
                        <Button style={{width:"30%"}}>
                            Add Experience
                        </Button>
                        <Button onClick={dialogClose} style={{width:"10%"}}>
                            Cancel
                        </Button>
                </FormItem>
                </FormGroup>
            </Form>
        </>
    ) 
};
export default AddEditCareerProfile;