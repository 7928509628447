import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React, { useContext }  from 'react';
import { HashRouter } from 'react-router-dom';
import AuthContext from "./contexts/AuthProvider";
import {AnimatedRoutes} from './utility/AnimatedRoutes';

function App() {
  const { setAuth } = useContext(AuthContext);
  const access_token = localStorage.getItem('ACCESS_TOKEN');
  const expires_in = localStorage.getItem('EXPIRES_IN');
  const current_user=localStorage.getItem('USER');
  return (
    <div className="App">
      <HashRouter>
          <AnimatedRoutes />
      </HashRouter>
    </div>
  );
}


export default App;
