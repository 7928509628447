import React, { useRef, useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import AuthContext from "../../../contexts/AuthProvider";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import apis from "../../../apis/api";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from "react-loader-spinner";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../../../assets/coplogoblack.png';
import background from '../../../assets/image.png';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { motion } from "framer-motion";



const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '100vh',
        minWidth: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  palette: {
    primary: {
      main: '#000000', // Black
    },
    secondary: {
      main: '#FFFFFF', // White
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    h6: {
      fontSize: '1rem',
      letterSpacing: '0.1rem',
      color: '#000000',
      textShadow: '2px 2px 4px rgba(255, 255, 255, 0.2)',
    },
  },
});
const url = '/api/Accounts/ForgetPassword?email=';


const ForgotPassword = () => {
  const { setAuth } = useContext(AuthContext);
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [infoMessage, setInfoMessage] = useState("");
  const [infoMessageState, setInfoState] = useState("");

  useEffect(() => {
    document.title = 'CMS - Recover Account';
    setInfoMessage("");
    setInfoState("");
    setLoading(true);
    setTimeout(()=>setLoading(false), 1000);
    // userRef.current.focus();
  }, []);

  useEffect(() => {
    setInfoMessage("");
    setInfoState("");
  }, [username]);

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!emailRegex.test(username)){
      setInfoMessage("Enter a valid email...");
      setInfoState("error");
      return;
    }
    try{
    const response = apis.get(url+username);
    response.then(function(res){
      setInfoMessage("A reset password link has been sent to your registered email.");
      setInfoState("green");
    }).catch(function(err){
      setInfoMessage("A reset password link has been sent to your registered email.");
      setInfoState("green");
    });
    }
    catch(e){
      setInfoMessage("We were unable to reset your password please contact your administrator");
      setInfoState("error");
    }

  };

  return (
    <div>
      {loading ? ( <div style={{display:'flex', justifyContent:'center', height:'100vh', alignItems:'center'}}><div style={{textAlign:'center'}}><Grid
  visible={true}
  height="100"
  width="100"
  color="blue"
  ariaLabel="grid-loading"
  radius="10"
  wrapperStyle={{}}
  wrapperClass="grid-wrapper"
  /><p style={{color:'black', padding:'10%'}}>Loading...</p></div></div>
) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{backgroundImage: `url(${background})`, backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'}}
      className="hold-transition  d-flex"
    >
      
    <ThemeProvider theme={theme}>
      <Container  component="main" maxWidth="xs">
        <Paper elevation={5} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
          <img style={{ width: '10rem' }} src={logo} alt="Logo" />
          <Typography component="h6" variant="h6" sx={{ mb: 3 }}>
          Oops, looks like you've misplaced your password. No worries, we're here to help you 
          </Typography>
          
          {infoMessage && (
                  <Typography variant="body2" color={infoMessageState} sx={{ mb: 2 }}>
                    {infoMessage}
                  </Typography>
          )}
          <form>
            {/* Login Form */}
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
            >
              Send Reset Link
            </Button>
          </form>

          {/* Forgot Password Link */}
          <a
            onClick={()=>navigate('/login')}
            variant="body2"
            style={{cursor:'pointer'}}
          >
            Go back to the login screen?
          </a>
        </Paper>
      </Container>
    </ThemeProvider>

    </motion.div>
    )}
    </div>
  );
};

export default ForgotPassword;
