import React, { useEffect, useState } from "react";
import {
    faUser
  } from "@fortawesome/free-solid-svg-icons";
import { Avatar  } from '@ui5/webcomponents-react';
function MemberListLayout(props) {
    const [url,setUrl] = useState();
    const imageNotLoaded = function(oControl){
        oControl.currentTarget.src="https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/user.png";
    };
    useEffect(()=>{
        setUrl("https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/"+props.id+".jpg?t="+new Date().getTime());
    },[props.id]);
    return(
    <>
        <Avatar
            colorScheme="Accent6"
            icon="employee"
            shape="Square"
            size="S"
            fallbackIcon={faUser}
        >
            <img src={url} id={props.id} alt="Image" onError={imageNotLoaded}/>
        </Avatar>                       
    </>
    );
};

export default MemberListLayout;