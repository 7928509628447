import React, { useRef, useState, useEffect } from "react";
import { Form, FormItem, FormGroup, Input, Button  } from '@ui5/webcomponents-react';
import apis from '../apis/api';

function CustomAssemblyForm(props) {
    const blockName = useRef(), countryName = useRef(), rccName = useRef(), areaName = useRef(), distName = useRef(), assemblyName = useRef();
    useEffect(()=>{
        if(props.currentId!=="00000000-0000-0000-0000-000000000000"){
            loadExistingEntity(props.currentId);
        }
    },[props]);
    const loadExistingEntity = async(id)=>{
        await apis.get("api/AdditionalOrgStores/"+id, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            },
          }).then(res=>{
            blockName.current.value = res.data.Block;
            countryName.current.value = res.data.Country;
            rccName.current.value = res.data.RCC;
            areaName.current.value = res.data.Area;
            distName.current.value = res.data.District;
            assemblyName.current.value = res.data.Assembly;
          })
          .catch(err=>{
            props.returnRef.current.value="Assembly not added. Select from list or try again later";
          });
    };
    const customEntitySave = async ()=>{
        var payload = {
            "Id": "00000000-0000-0000-0000-000000000000",
            "Block": blockName.current.value,
            "Country": countryName.current.value,
            "RCC": rccName.current.value,
            "Area": areaName.current.value,
            "District": distName.current.value,
            "Assembly": assemblyName.current.value
        };
        const response = await apis.post("api/AdditionalOrgStores", payload, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            },
          }).then(res=>{
            //  setMessage("Member added successfully");
            // uploadImage();
            props.returnRef.current.id=res.data.Id;
            props.setId(props.source, res.data.Id);
            props.returnRef.current.value=res.data.Assembly+" - Assembly Outside UK";
            props.close();
          })
          .catch(err=>{
            props.returnRef.current.value="Assembly not added. Select from list or try again later";
            props.close();
          });

        
    };
    return(
    <>
        <Form>
            <FormGroup>
                <FormItem label="">
                    <Input ref={assemblyName} style={{width:"50%"}} placeholder="Local Assembly Name"></Input>
                </FormItem>
                <FormItem label="">
                    <Input ref={distName} style={{width:"50%"}} placeholder="District Name"></Input>
                </FormItem>
                <FormItem label="">
                    <Input ref={areaName} style={{width:"50%"}} placeholder="Area Name"></Input>
                </FormItem>
                <FormItem label="">
                    <Input ref={rccName} style={{width:"50%"}} placeholder="RCC Name"></Input>
                </FormItem>
                <FormItem label="">
                    <Input ref={countryName} style={{width:"50%"}} placeholder="Country Name"></Input>
                </FormItem>
                <FormItem label="">
                    <Input ref={blockName} style={{width:"50%"}} placeholder="Block Name"></Input>
                </FormItem>            
            </FormGroup>
        </Form>
        <Form>
            <FormItem label="">
                <Button design="Emphasized" style={{width:"25%"}} onClick={customEntitySave}>Save</Button>
                <Button style={{width:"25%"}}>Clear</Button>
            </FormItem>
        </Form>
    </>
    );
};

export default CustomAssemblyForm;