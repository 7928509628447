import React, { useContext, useEffect, useState, useRef } from 'react'
import {motion} from 'framer-motion';
import UploadFile from './UploadFile';
import AddEditCareerProfile from '../MemberManager/AddEditCareerProfile';
import AddEditEducationalDetails from '../MemberManager/AddEditEduQual';
import AddTradRoyalDets from '../MemberManager/AddTradRoyalDets';
import countries from '../../assets/countries.json';
import { Token, ProgressIndicator, Dialog, Title, MessageStrip, ComboBox, ComboBoxItem, DatePicker, RadioButton, ObjectPageSection, FormGroup, Form, Toast, FormItem, Text, DynamicPageHeader, DynamicPageTitle, ObjectPage, Breadcrumbs, ObjectStatus, BreadcrumbsItem, Avatar, Link, Label, Button, Bar, FlexBox, TabContainer, Input } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
import ReactDOM from "react-dom";
function EditProfile(props) {
    const [profCatArr, setProfCat] = useState([]);
    const [homeCellArr, setHomeCell] = useState([]);
    const [disType, setDisType] = useState([]);
    const memberData = props.memberData;
    const memberDisType = props.memberDisType;
    const memberAddress =props.memberAddress;
    const memberProfCat = props.memberProfCat;
    const memberAssemblyInfo = props.memberAssemblyInfo;
    const memberAssemblyAddress =props.memberAssemblyAddress;
    const memberSalStatus = props.memberSalStatus;
    const memberDistrict = props.memberDistrict;
    const memberHomeCell = props.memberHomeCell;
    const onProfilePicChange = (e) => {
      setDialogContent(<UploadFile memberId={memberData.memberId} dialogClose={dialogClose}/>);
      setDialogHeader("Upload Profile Picture");
      experienceDialog.current.show();
    };
    const [dialogHeader, setDialogHeader] = useState("");
    const [dialogContent, setDialogContent] = useState();
    const addExperience = () => {
      setDialogContent(<AddEditCareerProfile dialogClose={dialogClose}/>);
      setDialogHeader("Add Career/Experience details");
      experienceDialog.current.show();
    };
    const addEducation = () => {
      setDialogContent(<AddEditEducationalDetails memberId={props.memberData.memberId} dialogClose={dialogClose}/>);
      setDialogHeader("Add Educational Qualifications");
      experienceDialog.current.show();
    };
    const addTradRoyalDets = () => {
      setDialogContent(<AddTradRoyalDets dialogClose={dialogClose}/>);
      setDialogHeader("Add Traditional and Royal Details");
      experienceDialog.current.show();
    };
    const mobileNum = useRef(), single=useRef(), married=useRef(), profession=useRef(), telephone = useRef(), homeTown=useRef(),
    nationality=useRef(), birthCountry=useRef(), residentCountry=useRef(), dob=useRef(), male=useRef(), female=useRef(), emergencyContact = useRef(), 
    add1 = useRef(), add2 = useRef(), locality = useRef(), city = useRef(), region = useRef(), country = useRef(), postalCode = useRef(),
    profCat = useRef(), docop = useRef(), dod = useRef(), disableType = useRef(), disabled = useRef(), notdisabled = useRef(),
    salStatusY = useRef(), salStatusN = useRef(), salDate = useRef(), communicantY = useRef(), communicantN = useRef(),
    homeCell = useRef(), experienceDialog = useRef(), firstName = useRef(), middleName = useRef(), lastName = useRef(), title = useRef(),
    widow = useRef(), divorced = useRef();
    const toastRef = useRef();
    const showToast = () => {
        toastRef.current.show();
    };
    const [message, setMessage] = useState("");
    const dialogClose = () => {
        experienceDialog.current.close();
    };
    const onEditSave = async () => {
      var payload = {
        MemberId: memberData.memberId,
        LocalAssemblyId: memberData.localAssembly.assemblyId,
        ProfCatId: (profCat.current.value==="")?"00000000-0000-0000-0000-000000000000":profCat.current.value.split(' (')[1].replace(')', ''),
        MemberAddress: (memberAddress)?memberAddress.addressId:"00000000-0000-0000-0000-000000000000",
        AssuranceId: (memberData.salvationStatus)?memberData.salvationStatus.assuranceId:"00000000-0000-0000-0000-000000000000",
        BaptismStatusId: "00000000-0000-0000-0000-000000000000",
        homeCellId: (homeCell.current.value==="")?"00000000-0000-0000-0000-000000000000":homeCell.current.value.split(' (')[1].replace(')', ''),
        ProfilePictureId: "00000000-0000-0000-0000-000000000000",
        Title: title.current.value,
        FirstName: firstName.current.value,
        MiddleName: middleName.current.value,
        LastName: lastName.current.value,
        DOB: dob.current.value,
        Gender: (male.current.checked)?"M":(female.current.checked)?"F":"",
        Nationality: nationality.current.value,
        CountryOfBirth: birthCountry.current.value,
        CountryOfResidence: residentCountry.current.value,
        MaritalStatus: (single.current.checked)?"Single":(married.current.checked)?"Married":(widow.current.checked)?"Widowed":(divorced.current.checked)?"Divorced":"",
        MobileNumber: mobileNum.current.value,
        EmailAddress: memberData.emailAddress,
        TelNumber: telephone.current.value,
        Profession: profession.current.value,
        ChurchStatus: "true",
        EmergencyContact: emergencyContact.current.value,
        ChildFlag: memberData.childFlag,
        Disabled: (disabled.current.checked)?true:false,
        DisabilityType: (disableType.current.value==="")?"00000000-0000-0000-0000-000000000000":disableType.current.value.split(' (')[1].replace(')', ''),
        DateOfJoining: docop.current.value,
        BirthPlace: birthCountry.current.value,
        HomeTown: homeTown.current.value,
        DedicationDate: dod.current.value,
        WaterBaptised: false,
        HSBaptised: false
      };
      console.log(payload);
      await apis.put("api/Members/"+memberData.memberId,payload,{
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        }
      }).then(res=>{
        console.log(res);
        props.onEditProfileCancel();
      }).catch(err=>{
        console.log(err);
      });
    };
    const imageNotLoaded = function(oControl){
      oControl.currentTarget.src="https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/user.png";
    };
    const fillProfessionalCategory = async () => {
      await apis.get("api/MetaProfessionCategories", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        },
      }).then(res=>{
        setProfCat(res.data);
      })
      .catch(err=>{
        console.log(err);
      });
    };
    const fillDisType = async () => {
      await apis.get("api/DisabilityTypes", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        },
      }).then(res=>{
        setDisType(res.data);
      })
      .catch(err=>{
        console.log(err);
      });
    };
    const fillHomeCells = async () => {
      await apis.get("api/memberGrouping/getHomeCellsByEntityId?id="+props.memberData.localAssembly.assemblyId, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
        }
      }).then(res=>{
        setHomeCell(res.data);
      })
      .catch(err=>{console.log(err)});
    };
    useEffect(()=>{
      fillProfessionalCategory();
      fillDisType();
      fillHomeCells();
    },[props]);
    return(<>
    <ObjectPage
      footer={<Bar design="FloatingFooter" endContent={<><Button onClick={onEditSave} design="Positive">Save</Button><Button onClick={props.onEditProfileCancel} design="Negative">Cancel</Button></>} />}
      headerContent={<DynamicPageHeader><FlexBox alignItems="Stretch"><FlexBox alignItems="Start" direction="Column"><Link href={"tel:"+memberData.mobileNumber}>{memberData.mobileNumber}</Link><Link href={"mailto:"+memberData.emailAddress}>{memberData.emailAddress}</Link>
      {memberData.disabled &&<Token  text="Disabled" readonly="true"/>}{((memberDisType)? memberDisType.mobilitySupport:false)&& <Token readonly="true" text={"Mobility Support Required"}/>}
      </FlexBox><FlexBox alignItems="Start" direction="Column" style={{ paddingTop:"0.5em", width:"10em"}}></FlexBox></FlexBox></DynamicPageHeader>}
      headerContentPinnable
      headerTitle={<DynamicPageTitle actions={<><Button onClick={onProfilePicChange} design="Emphasized">Change Profile Piture</Button></>}
      header={memberData.title+" "+memberData.firstName+((memberData.middleName!=="")?" "+memberData.middleName:" "+memberData.lastName)+((memberData.middleName!=="")?" "+memberData.lastName:"")}><ObjectStatus state="Success">Member</ObjectStatus> </DynamicPageTitle>}
      image={<Avatar
        colorScheme="Accent6"
        icon="employee"
        shape="Circle"
        // style={{paddingTop:"5px"}}
        size="XL"
      >
        <img src={"https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/"+memberData.memberId+".jpg?t="+new Date().getTime()} alt="Image" onError={imageNotLoaded} />
      </Avatar>}
      imageShapeCircle
      onSelectedSectionChange={function noRefCheck() { }}
      onToggleHeaderContent={function noRefCheck() { }}
      selectedSectionId="goals"
      showHideHeaderButton
      style={{
        height: '700px'
      }}
    >
      <ObjectPageSection
        aria-label="Personal"
        id="contact-info"
        titleText="Contact Information"
      >
        <ProgressIndicator displayValue="Profile Completion Status 70%" value="70" valueState="Success"/>
        {/* <ObjectPageSubSection
          actions={<><Button design="Emphasized" style={{ minWidth: '120px' }}>Custom Action</Button><Button design="Transparent" icon="action-settings" tooltip="settings" /><Button design="Transparent" icon="download" tooltip="download" /></>}
          aria-label="Contact Information"
          id="personal-connect"
          titleText="Contact Information"
        > */}
          <Form
            columnsL={2}
            columnsXL={2}
            style={{
              alignItems: 'baseline'
            }}
          >
            <FormGroup titleText="Phone Numbers">
              <FormItem label="Mobile Number">
                <Input placeholder="Mobile Number" ref={mobileNum} value={(memberData.mobileNumber)?memberData.mobileNumber:""}/>
              </FormItem>
              <FormItem label="Telephone Number">
                <Input placeholder="Telephone" ref={telephone} value={(memberData.telNumber)?memberData.telNumber:""}/>
              </FormItem>
              <FormItem label="Emergency Contact">
                <Input placeholder="Emergency Contact" ref={emergencyContact} value={(memberData.emergencyContact)?memberData.emergencyContact:""}/>
              </FormItem>
              <FormItem label="Email Address">
                <Link href={"mailto:"+memberData.emailAddress}>
                  {memberData.emailAddress}
                </Link>
              </FormItem>
            </FormGroup>
            <FormGroup titleText="Social Accounts">
              <FormItem label="LinkedIn">
                <Link href="https://www.linkedin.com/in/cyril-johnson-cj/" target="_blank">
                  cyril-johnson-cj
                </Link>
              </FormItem>
              <FormItem label="Twitter">
                <Link href="https://twitter.com/CyrilJohnson2" target="_blank">
                  CyrilJohnson2
                </Link>
              </FormItem>
              <FormItem label="Instagram">
                <Link href="https://www.instagram.com/cyriljohnsoncj/" target="_blank">
                  cyriljohnsoncj
                </Link>
              </FormItem>
              <FormItem label="Facebook">
                <Link href="https://www.facebook.com/people/Cyril-Johnson/100082081875220/" target="_blank">
                 Cyril-Johnson/100082081875220/
                </Link>
              </FormItem>
            </FormGroup>
            <FormGroup titleText="Addresses">
              <FormItem label="Home Address">
                <Input ref={add1} placeholder="Address Line 1" value={(!memberAddress)?"":memberAddress.addressLine1}/>
              </FormItem>
              <FormItem label="">
                <Input ref={add2} placeholder="Address Line 2" value={(!memberAddress)?"":memberAddress.addressLine2}/>
              </FormItem>
              <FormItem label="">
                <Input ref={locality} placeholder="Locality" value={(!memberAddress)?"":memberAddress.locality}/>
              </FormItem>
              <FormItem label="">
                <Input ref={city} placeholder="City" value={(!memberAddress)?"":memberAddress.city}/>
              </FormItem>
              <FormItem label="">
                <Input ref={region} placeholder="Region" value= {(!memberAddress)?"":memberAddress.region}/>
              </FormItem>
              <FormItem label="">
                <ComboBox ref={country} placeholder="Country">
                    {countries && countries.map(row => {
                      return <ComboBoxItem text={row.country} />
                    })}
                </ComboBox>
              </FormItem>
              <FormItem label="">
                <Input ref={postalCode} placeholder="Postal Code" value= {(!memberAddress)?"":memberAddress.postalCode}/>
              </FormItem>
            </FormGroup>
          </Form>
          </ObjectPageSection>
        <ObjectPageSection
        titleTextUppercase="false"
        aria-label="Personal Information"
        id="personal-info"
        titleText="Personal Information">
          <Form
            columnsL={2}
            columnsXL={2}
            style={{
              alignItems: 'baseline'
            }}
          >
            <FormGroup titleText="">
              <FormItem label="Title">
                <ComboBox placeholder="Select Title" ref={title} value={memberData.title}>
                  {titles && titles.map(row => {
                    return <ComboBoxItem text={row.title} />
                  })}
                </ComboBox>
              </FormItem>
              <FormItem label="First Name">
                <Input ref={firstName} placeholder="First Name" value={memberData.firstName}/>
              </FormItem>
              <FormItem label="Middle Name">
                <Input ref={middleName} placeholder="Middle Name" value={memberData.middleName}/>
              </FormItem>
              <FormItem label="Last Name">
                <Input ref={lastName} placeholder="Last Name" value={memberData.lastName}/>
              </FormItem>
              <FormItem label="Date Of Bith">
                <DatePicker placeholder="Date Of Birth" value={memberData.dob} ref={dob} />
              </FormItem>
              <FormItem label="Gender">
                <FlexBox alignItems="Center">
                  <RadioButton name="A" checked={(memberData.gender==="M")?"true":"false"} ref={male} text="Male" />
                  <RadioButton name="A" checked={(memberData.gender==="F")?"true":"false"} ref={female} text="Female" />
                </FlexBox>
              </FormItem>
              <FormItem label="Home Town">
                <Input ref={homeTown} placeholder="Home Town" value={memberData.homeTown} />
              </FormItem>
              <FormItem label="Nationality">
                <ComboBox ref={nationality} placeholder="Select Nationality" value={memberData.nationality} >
                    {countries && countries.map(row => {
                      return <ComboBoxItem text={row.nationality} />
                    })}
                </ComboBox>
              </FormItem>
              <FormItem label="Country Of Birth">
                <ComboBox ref={birthCountry} placeholder="Select Country"  value={memberData.countryOfBirth} >
                    {countries && countries.map(row => {
                      return <ComboBoxItem text={row.country} />
                    })}
                </ComboBox>
              </FormItem>
            </FormGroup>
            <FormGroup titleText="">
            <FormItem label="Country Of Residence">
                <ComboBox ref={residentCountry} placeholder="Select Country"  value={memberData.countryOfResidence}>
                    {countries && countries.map(row => {
                      return <ComboBoxItem text={row.country} />
                    })}
                </ComboBox>
              </FormItem>
              <FormItem label="Marrital Status">
               <FlexBox alignItems="Center">
                  <RadioButton name="B" checked={(memberData.maritalStatus==="Single")?"true":"false"} ref={single} text="Single" />
                  <RadioButton name="B" checked={(memberData.maritalStatus==="Married")?"true":"false"} ref={married} text="Married" />
                  <RadioButton ref={widow} checked={(memberData.maritalStatus==="Widowed")?"true":"false"} name="B" text="Widowed" />
                  <RadioButton ref={divorced} checked={(memberData.maritalStatus==="Divorced")?"true":"false"} name="B" text="Divorced" />
                </FlexBox>
              </FormItem>
              <FormItem label="Profession">
                <Input ref={profession} value={memberData.profession} placeholder="Profession"/>
              </FormItem>
              <FormItem label="Professional Categorty">
                <ComboBox ref={profCat} value={(memberProfCat)?memberProfCat.profCatName+" ("+memberProfCat.profCatId+")":""} placeholder="Professional Category">
                {profCatArr && profCatArr.map(row => {
                  return <ComboBoxItem text={row.ProfCatName+' ('+row.ProfCatId+')'} />
                })}
                </ComboBox>
              </FormItem>
              <FormItem label="Date Of Joining">
              <DatePicker placeholder="Date Of Joining COP" value={memberData.dateOfJoining} ref={docop}  />
              </FormItem>
              <FormItem label="Date Of Dedication">
              <DatePicker placeholder="Date Of Dedication" value={memberData.dedicationDate} ref={dod}  />
              </FormItem>
              <FormItem label="Disabled">
              <FlexBox alignItems="Center">
                  <RadioButton name="C" checked={(memberData.disabled)?"true":"false"} ref={disabled} text="Yes" />
                  <RadioButton name="C" checked={(!memberData.maritalStatus)?"true":"false"} ref={notdisabled} text="No" />
                </FlexBox>
              </FormItem>
              <FormItem label="Disability Type">
                <ComboBox ref={disableType} placeholder="Type Of Disability">
                    {disType && disType.map(row => {
                      return <ComboBoxItem text={row.DisabilityName+' ('+row.DisabilityId+')'} />
                    })}
                </ComboBox>
            </FormItem>
            </FormGroup>
          </Form>
        {/* </ObjectPageSubSection> */}
      </ObjectPageSection>
      <ObjectPageSection
      titleTextUppercase="false"
        aria-label="Assembly Infomration"
        id="assembly-info"
        titleText="Assembly Infomration">
            <Form
            columnsL={2}
            columnsXL={2}
            style={{
              alignItems: 'baseline'
            }}
          >
            <FormGroup titleText="Local Assembly">
              <FormItem label="Assembly Name">
                  <Text>
                    {memberAssemblyInfo.assemblyName}
                  </Text>
              </FormItem>
              <FormItem label="Assembly Language">
                  <Text>
                    {memberAssemblyInfo.langSpoken}
                  </Text>
              </FormItem>
              <FormItem label="Assembly District">
                  <Text>
                   {memberDistrict.districtName}
                  </Text>
              </FormItem>
              <FormItem label="Assembly Address">
                <Text>
                  {memberAssemblyAddress.addressLine1}
                </Text>
              </FormItem>
              <FormItem label="">
                <Text>
                 {memberAssemblyAddress.addressLine2}
                </Text>
              </FormItem>
              <FormItem label="">
                <Text>
                {memberAssemblyAddress.locality}
                </Text>
              </FormItem>
              <FormItem label="">
                <Text>
                {memberAssemblyAddress.city}
                </Text>
              </FormItem>
              <FormItem label="">
                <Text>
                {memberAssemblyAddress.region}
                </Text>
              </FormItem>
              <FormItem label="">
                <Text>
                {memberAssemblyAddress.country + "-"+ memberAssemblyAddress.postalCode}
                </Text>
              </FormItem>
            </FormGroup>
            <FormGroup titleText="Salvation Status & Home Cell">
              <FormItem label="Have you accepted Jesus as Lord and personal savior ? ">
              <FlexBox alignItems="Center">
                  <RadioButton name="D" checked={(memberSalStatus)?(memberSalStatus.salvationStatusText === "Yes")?"true":"false":"false"} ref={salStatusY} text="Yes" />
                  <RadioButton name="D" checked={(memberSalStatus)?(memberSalStatus.salvationStatusText === "No")?"true":"false":"false"} ref={salStatusN} text="No" />
                </FlexBox>
              </FormItem>
              <FormItem label="Date Of Salvation">
                <DatePicker placeholder="Date Of Salvation" value={(memberSalStatus)?memberSalStatus.date:""} ref={salDate} />
              </FormItem>
              <FormItem label="Are you a communicant">
              <FlexBox alignItems="Center">
                  <RadioButton name="E" checked={(memberSalStatus)?(memberSalStatus.communicantBool === "Yes")?"true":"false":"false"} ref={communicantY} text="Yes" />
                  <RadioButton name="E" checked={(memberSalStatus)?(memberSalStatus.communicantBool === "No")?"true":"false":"false"} ref={communicantN} text="No" />
                </FlexBox>
              </FormItem>
              <FormItem label="Home cell you belong to">
                <ComboBox ref={homeCell} placeholder="Home Cell" value={(memberHomeCell)?(memberHomeCell.homeCellName):""} >
                  {homeCellArr && homeCellArr.map(row => {
                    return <ComboBoxItem text={row.homeCellName+' ('+row.homeCellId+')'} />
                  })}
                </ComboBox>
              </FormItem>
            </FormGroup>
          </Form>
        </ObjectPageSection>
        <ObjectPageSection
            aria-label="Education Details"
            id="edu-profile"
            titleText="Education Details"
            >
              <Button onClick={addEducation}>Add Education</Button>
        </ObjectPageSection>
        <ObjectPageSection
            aria-label="Career Profile"
            id="career-profile"
            titleText="Career Profile"
            >
              <Button onClick={addExperience}>Add Experience</Button>
        </ObjectPageSection>
        <ObjectPageSection
          titleTextUppercase="false"
            aria-label="Traditional and Royal Details"
            id="trad-royal-dets"
            titleText="Traditional and Royal Details">
              <Button onClick={addTradRoyalDets}>Add Traditional & Royal Details</Button>
        </ObjectPageSection>
    </ObjectPage>
    {ReactDOM.createPortal(
        <Dialog stretch="true" draggable="true" resizable="true" ref={experienceDialog} 
        header={<Bar endContent={<Button design="Negative" onClick={dialogClose}>x</Button>}><Title>{dialogHeader}</Title></Bar>}
        >
          {dialogContent}
       
        <div style={{height:"2%"}}/>
        <Toast ref={toastRef}>{message}</Toast>
        </Dialog>, document.body)}
        </>
    );
}
export default EditProfile;
const titles =
  [
    { title: "Sir" }, { title: "Ma'am" }, { title: "Madam" }, { title: "Mr" }, { title: "Mrs" }, { title: "Ms" }, { title: "Miss" }, { title: "Dr" }, { title: "Professor" }
  ];