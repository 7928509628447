import React, { useState, useEffect } from 'react';
import { AnalyticalTable, Select, Option, Form, FormItem, Button, Page, Bar, Label } from '@ui5/webcomponents-react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import apis from '../../apis/api';
import ShellBarComponent from '../../components/ShellBarComponent';

function RecordGenerator() {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [attendanceData, setAttendanceData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Fetch events
    useEffect(() => {
        const fetchEvents = async () => {
            setIsLoading(true);
            setError('');
            try {
                const response = await apis.get("getMyEvents", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                    }
                });
                if (response.status === 200) {
                    setEvents(response.data);
                }
            } catch (error) {
                setError('Error fetching events: ' + error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchEvents();
    }, []);

    // Fetch attendance data
    useEffect(() => {
        if (selectedEvent) {
            const fetchAttendanceData = async () => {
                setIsLoading(true);
                setError('');
                try {
                    const response = await apis.get(`/api/MemberEventAttendances/${selectedEvent}`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                        }
                    });
                    if (response.status === 200) {
                        setAttendanceData(response.data);
                    }
                } catch (error) {
                    setError('Error fetching attendance data: ' + error.message);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchAttendanceData();
        }
    }, [selectedEvent]);

    const handleEventSelection = (e) => {
        setSelectedEvent(e.detail.selectedOption.value);
    };

    return (
        <div>
            <motion.div initial={{ opacity: 0 }} animate={{ width: "100%", opacity: 1 }} exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}>
                <ShellBarComponent />
                <Page header={<Bar startContent={<Button icon="nav-back" title="Go back" onClick={() => navigate("/eventManager")} />}><Label>Record Generator</Label></Bar>} style={{ height: "90vh" }}>
                    
                    <Form>
                        <FormItem label="Select Event">
                            <Select value={selectedEvent} onChange={handleEventSelection}>
                                <Option key="default" value="">{selectedEvent === '' ? 'Select Event' : 'Select Event'}</Option>
                                {events.map(event => (
                                    <Option key={event.id} value={event.name}>{event.name}</Option>
                                ))}
                            </Select>
                        </FormItem>
                    </Form>

                    {isLoading && <div>Loading...</div>}
                    {error && <div>Error: {error}</div>}

                    {attendanceData && (
                        <AnalyticalTable data={attendanceData} /* additional props as needed for the table */ />
                    )}
                </Page>
            </motion.div>
        </div>
    );
}

export default RecordGenerator;
