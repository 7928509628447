import axios from 'axios';

export default axios.create({
    //test system url
    // baseURL: 'https://testcopukservices.azurewebsites.net'
    
    //prod system url
    baseURL:'https://copuk.azurewebsites.net'
    
});



