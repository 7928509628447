import React from 'react'
// import Footer from '../../components/Footer/Footer';
// import SideBar from '../../components/SideBar/SideBar';
// import TopBar from '../../components/TopBar/TopBar';
import HomeLayout from './HomeLayout';
import HomeDevLayout from './HomeDevLayout';
function Home() {
    return (
        <div>
            <HomeLayout /> 
            {/* <HomeDevLayout/> */}
        </div>
    )
}
export default Home
