import React, { useEffect, useState } from 'react';
import { Button, Bar, Label, Page, TableCell, TableRow, Text, Table, TableColumn, FilterBar, Input, ComboBox, ComboBoxItem, FilterGroupItem, DateRangePicker, Icon } from '@ui5/webcomponents-react';
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import "@ui5/webcomponents-icons/dist/navigation-right-arrow.js";
import ShellBarComponent from '../../components/ShellBarComponent';
import apis from '../../apis/api';
import '../../styles/MasterDetail.css';
function ManageEventNew() {
    const navigate = useNavigate();
    const goBack = () => {
        navigate("/eventManager");
    };
    
    
    
    const [events, setEventList] = useState([]);
    const [groupedData, setGroupedData] = useState([]);
    const handleListItemClick = (event) => {
    };
    const readEvents = async function () {
        const response = await apis.get("getMyEvents", {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        });
        setEventList(response.data);
        var grouped = response.data.reduce((acc, item) => {
            const group = acc[item.creationState] || [];
            group.push(item);
            acc[item.creationState] = group;
            return acc;
        }, {});
        setGroupedData(grouped);
    };
    const onEventDetailClick = (event) => {
        var sEventId = event.detail.row.id;
        var selectedEvent = events.find((event)=>{return event.id === sEventId})
        navigate("/eventDetail/"+sEventId, { state: { eventData: selectedEvent } })
    };

    
    useEffect(() => {
        document.title = 'CMS - Manage Events';
        readEvents();
    }, []);
    return (
        <React.Fragment>
            <motion.div initial={{ opacity: 0 }} animate={{ width: "100%", opacity: 1 }} exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}>
                <ShellBarComponent />
                <Page header={<Bar startContent={<Button icon="nav-back" title="Go back" onClick={goBack} />}><Label>Manage Events</Label></Bar>} style={{ height: "90vh" }}>
                <FilterBar header={"Find Events ("+events.length +")"} filterBarCollapsed="false" onGo={function(){}} onClear={function onClear(){alert("clear")}}  showGoOnFB="true">
                <FilterGroupItem label="Search">
                    <Input placeholder="Search by Event Name" />
                </FilterGroupItem>
                <FilterGroupItem label="Event Status">
                    <ComboBox  placeholder="Event Status">
                        <ComboBoxItem text="Draft" />
                        <ComboBoxItem text="Published" />
                        <ComboBoxItem text="Completed" />
                        <ComboBoxItem text="Cancelled" />
                    </ComboBox>
                </FilterGroupItem>
                <FilterGroupItem label="Event Type">
                    <ComboBox placeholder="Event Type">
                        <ComboBoxItem text="Open" />
                        <ComboBoxItem text="Registration Required" />
                        <ComboBoxItem text="Closed" />
                    </ComboBox>
                </FilterGroupItem>
                <FilterGroupItem label="Created By">
                    <ComboBox placeholder="Created By">
                        <ComboBoxItem text="Me" />
                        <ComboBoxItem text="Others" />
                    </ComboBox>
                </FilterGroupItem>
                <FilterGroupItem label="Event Date">
                <DateRangePicker
                    onChange={function _a(){}}
                    onInput={function _a(){}}
                    onValueStateChange={function _a(){}}
                    primaryCalendarType="Gregorian"
                    valueState="None"
                    />
                </FilterGroupItem>
            </FilterBar>
            <Table onRowClick={onEventDetailClick}
      columns={
        <>
          <TableColumn demandPopin="true" popinDisplay='Inline' popinText='Event Name' minWidth={1000}>Event Name</TableColumn>
          <TableColumn demandPopin="true" popinDisplay='Inline' popinText='Description' minWidth={1000}>Description</TableColumn>
          <TableColumn demandPopin="true" popinDisplay='Inline' popinText='Type' minWidth={1000}>Type</TableColumn>
          <TableColumn demandPopin="true" popinDisplay='Inline' popinText='Status' minWidth={1000}>Status</TableColumn>
          <TableColumn demandPopin="true" popinDisplay='Inline' popinText='Last Job Cycle' minWidth={1000}>Last Job Cycle</TableColumn>
          <TableColumn demandPopin="true" popinDisplay='Inline' popinText='Created By' minWidth={1000}>Created By</TableColumn>
          <TableColumn demandPopin="true" popinDisplay='Inline' popinText='Last Changed By' minWidth={1000}>Last Changed By</TableColumn>
          <TableColumn></TableColumn>

        </>
      }
    >
      {events.map((item) => (
        <TableRow id={item.id} type="Active">
          <TableCell style={{textAlign:'start'}}><Text>{item.name || 'No Name'}</Text></TableCell>
          <TableCell style={{textAlign:'start'}}><Label>{item.description || '-'}</Label></TableCell>
          <TableCell style={{textAlign:'start'}}><Label>{item.type || '-'}</Label></TableCell>
          <TableCell style={{textAlign:'start'}}><Label>{item.status || '-'}</Label></TableCell>
          <TableCell style={{textAlign:'start'}}><Label>{item.lastJobCycle || '-'}</Label></TableCell>
          <TableCell style={{textAlign:'start'}}><Label>{item.createdBy || '-'}</Label></TableCell>
          <TableCell style={{textAlign:'start'}}><Label>{item.lastChangedBy || '-'}</Label></TableCell>
          <TableCell><Icon style={{color:'rgb(131 177 249)'}} name="navigation-right-arrow"></Icon></TableCell>
        </TableRow>
      ))}
    </Table>
       
                </Page>
            </motion.div>
        </React.Fragment>
    );
};
export default ManageEventNew;