import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactDOM from "react-dom";
import apis from '../../apis/api';
import MemberFilterLayout from "../../components/MemberFilterLayout";
import MemberListLayout from '../../components/MemberListLayout';
import { Dialog, Bar, List, Card, Token, StandardListItem, Toast, ComboBoxItem,MessageStrip, Form, FormItem, Input, CardHeader, Panel, Toolbar, Title, ToolbarSpacer, Icon, Avatar, ObjectPageSection, DynamicPageHeader, DynamicPageTitle, ObjectPage, Breadcrumbs, ObjectStatus, BreadcrumbsItem, Link, Button,FlexBox, ComboBox } from '@ui5/webcomponents-react';
import {motion} from 'framer-motion';
import ShellBarComponent from '../../components/ShellBarComponent';
import MemberListLayoutMultiple from '../../components/MemberListLayoutMultiple';
function GroupDetailLayout() {
    const navigate = useNavigate();
    const userList = useRef();
    const memberListDialog = useRef();
    const positionList = useRef();
    const addMemberDialog = useRef();
    const memberListRef = useRef();
    const toastRef = useRef();
    const entityUser = useRef();
    const [message, setMessage] = useState("");
    let { groupId } = useParams();
    const deleteItem = (e) => {
        console.log(e);
    };
    const [memberToks, setMemberToks] = useState([]);
    const [basicInfo, setBasicInfo] = useState({});
    const [positions, setPositions] = useState([]);
    const [members, setMemberList] = useState([]);
    const [memberDataResponse, setResponse] = useState([]);
    const onAddMembers = () =>{
        var members = [];
        var items = userList.current.items;
        for(var i=0;i<items.length;i++){
            members.push({
                FakeId: "2df4adcd-1e7a-414e-8861-f16252f6c0fe",
                PositionId: positionList.current.value.split(" ").pop().replace("(", "").replace(")", ""),
                MemberId: items[i].id,
                GroupId: groupId
              });
        }
        apis.post("api/GroupMemberRelations",members,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(res=>{
            console.log(res.data);
            dialogClose();
            loadGroupInfo();
        })
        .catch(err=>{
            console.log(err);
        });
    };
    const memberDialogClose = () => {
        memberListDialog.current.close()
    }
    const selectMember = () => {
        memberListDialog.current.show();
    };
    const onFilterGo = (filterJson) => {
        loadMemberData(filterJson);
    };
    const loadMemberData = async (filters) => {
        var payload = filters;
        apis.post('api/MemebershipManager/getFilteredMembers',payload,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(response =>{
            setResponse(response.data);
        }).catch(err =>{
            console.log(err);
        })
    };
    const dialogClose = () => {
        addMemberDialog.current.close();
    };
    const onRemoveMember = (e) => {
        apis.delete("api/GroupMemberRelations/"+e.target.id,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(res=>{
            console.log(res);
        })
        .catch(err=>{
            console.log(err);
        });

    };
    const loadGroupInfo = (groupId) => {
        apis.get("api/memberGrouping/getGroupDetail?groupId="+groupId,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(res=>{
            setBasicInfo(res.data.basicInfo);
            setMemberList(res.data.groupMembers);
        }).catch(err=>{
            console.log(err);
        });
    };
    const addMemberDialogOpen = () => {
        apis.get("api/PositionTables",{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(res=>{
            // console.log(res);
            setPositions(res.data);
        })
        .catch(err=>{
            console.log(err);
        });
        addMemberDialog.current.show();
    };
    useEffect(()=>{
        loadGroupInfo(groupId)
    },[]);
    return(
        <React.Fragment>
            <motion.div  initial={{opacity:0}} animate={{width:"100%", opacity:1}} exit={{x:window.innerWidth, transition:{duration: 0.5}}}>
                <ShellBarComponent/>
                    <ObjectPage
                        headerTitle={<DynamicPageTitle 
                        breadcrumbs={<Breadcrumbs onItemClick={()=>navigate('/groupManager')} design="NoCurrentPage"><BreadcrumbsItem>Group Manager</BreadcrumbsItem><BreadcrumbsItem></BreadcrumbsItem></Breadcrumbs>} header={basicInfo.groupName} subHeader={basicInfo.groupDescription}> </DynamicPageTitle>}
                        imageShapeCircle
                        onSelectedSectionChange={function noRefCheck() { }}
                        onToggleHeaderContent={function noRefCheck() { }}
                        showHideHeaderButton
                        style={{
                        height: '700px'
                        }}
                    >
                        <ObjectPageSection titleText="Members">
                        <Panel fixed="true"
                            header={<Toolbar><ToolbarSpacer /><Button onClick={addMemberDialogOpen} design="Emphasized">Add Member</Button><Button onClick={addMemberDialogOpen} >Send Email</Button> </Toolbar>}
                            headerText="Panel"
                            >
                                {members && members.map(row=>{
                                    return <Card
                                    header={<CardHeader 
                                        avatar={<Avatar
                                      >
                                        <img src={"https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/"+row.memberId+".jpg?t="+new Date().getTime()} alt="Image" />
                                      </Avatar>} 
                                    subtitleText={row.position} titleText={row.memberName}/>}
                                    style={{
                                        padding:"1vw",
                                        width: '300px'
                                    }}
                                    >
                                        <Button design="Negative" onClick={onRemoveMember} id={row.relationId}>Remove Member</Button>
                                </Card>
                                })}
                        </Panel>
                        {ReactDOM.createPortal(
                            <Dialog stretch="true" draggable="true" resizable="true" ref={memberListDialog} 
                            header={<Bar endContent={<Button design="Negative" onClick={memberDialogClose}>x</Button>}><Title>Member List</Title></Bar>}
                            footer={<Bar design="Footer" 
                           // endContent={<Button onClick={()=>memberListRef.current.simply()} design="Emphasized" >Add Members</Button>}
                            />}>
                            <MessageStrip>
                                You can select multiple members and add them to the group
                            </MessageStrip>
                            <MemberFilterLayout parentCallBack = {onFilterGo}/>
                            <MemberListLayoutMultiple ref={memberListRef} closeDialog = {memberDialogClose} memberArray = {memberDataResponse} source="MemberGroupTok" returnRef = {setMemberToks}/>
                            <div style={{height:"2%"}}/>
                            <Toast ref={toastRef}>{message}</Toast>
                            </Dialog>, document.body)}
                        {ReactDOM.createPortal(
                            <Dialog stretch="true" draggable="true" resizable="true" ref={addMemberDialog} 
                            header={<Bar endContent={<Button design="Negative" onClick={dialogClose}>x</Button>}><Title>Add Members to Group</Title></Bar>}
                            footer={<Bar design="Footer" 
                            endContent={<Button design="Emphasized" onClick={onAddMembers}>Add Members</Button> }/>}>
                            <MessageStrip>
                                Click on select member button to select members to add to the group. If you would like to deselect members click on select members and deselect members.
                            </MessageStrip>
                            <div style={{height:"2%"}}/>
                            <Form>
                                <FormItem label="">
                                    <ComboBox style={{width:"50%"}} ref={positionList} placeholder="Select Group Position">
                                    {positions && positions.map(row=>{
                                        return <ComboBoxItem text={row.PositionName+' ('+row.PositionId+')'}/>
                                    })}
                                    </ComboBox>
                                </FormItem>
                                <FormItem label="">
                                    <Button onClick={selectMember} style={{width: '50%'}}>Select Members</Button>
                                </FormItem>
                                <FormItem label="">
                                <List style={{width:"50%"}} ref={userList} onItemDelete={deleteItem}>
                                {memberToks}
                                </List>
                                </FormItem>
                            </Form>
                            
                            <Toast ref={toastRef}>{message}</Toast>
                            </Dialog>, document.body)}
                        </ObjectPageSection>
                    </ObjectPage>
            </motion.div>
        </React.Fragment>
    );
};
export default GroupDetailLayout;
