import React, { useRef, useState, useEffect } from "react";
import { BusyIndicator, Bar, Form,TextArea, FormItem, Text, Modals, Button, Label, Link, List, Page, MessageStrip, StandardListItem } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
import Papa from "papaparse";
import $ from 'jquery';
import MemberUploadTable from "../../components/MemberUploadTable";
var finaldata = [];
var selLocalAssemblyId = '';
function MassCreateMembersResultsLayout(props) {
    const [busyState, setBusy] = useState(false);
    const [showTasks, setShowTasks] = useState(true);
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [tasks, setTasks] = useState([]);
    const calculate_age = (dob)=> {
        debugger;
        dob = new Date(dob);
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
    const showDialog = Modals.useShowDialog()
    const [taskDetail, setTaskDetail] = useState([]);
    const onTaskSelect =  (e) => {
        setBusy(true);
        setShowTasks(false);
        var selectedSessionId = e.detail.item.id;
        apis.get("api/massUpload/getTaskDetails?SessionId="+selectedSessionId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            },
        }).then(response=>{
            response.data.sort((a, b) => {
                // If 'a' is "Failed" and 'b' is "Success", 'a' comes before 'b'
                if (a.status === "Failed" && b.status === "Success") {
                  return -1;
                }
                // If 'a' is "Success" and 'b' is "Failed", 'a' comes after 'b'
                if (a.status === "Success" && b.status === "Failed") {
                  return 1;
                }
                // Otherwise, maintain the current order
                return 0;
              });
            setTaskDetail(response.data);
            setShowTaskDetails(true);
            setBusy(false);
        });
    };
    const onViewDetails = async (e) => {
        var selectedItemRef = e.detail.item.id;
        var state = e.detail.item.additionalText;
        setBusy(true);
        if(state === "Success"){
            try{
                var response =  await apis.get("api/MemebershipManager/getMemberById?id="+selectedItemRef, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                    },
                });
                const{close}=
                showDialog({
                    headerText: 'Member Details',
                    state:"Success",
                    children: 
                    <Form>
                        <FormItem label="Name">
                            <Text>{response.data.title+" "+response.data.firstName+" "+response.data.middleName+" "+response.data.lastName}</Text>
                        </FormItem>
                        <FormItem label="Age">
                            <Text>{calculate_age(response.data.dob)}</Text>
                        </FormItem>
                        <FormItem label="Membership Type(Child/Adult)">
                            <Text>{(response.data.childFlag)?"Child Member":"Member"}</Text>
                        </FormItem>
                        <FormItem label="Email">
                            <Link href={"mailto:response.data.emailAddress"}>{response.data.emailAddress}</Link>
                        </FormItem>
                    </Form>,
                    footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
                });
                setBusy(false);
            } catch(error) {
                setBusy(false);
                const{close}=
                showDialog({
                    headerText: 'Unavailable',
                    state:"Information",
                    children: 'Logs do not exist for this task',
                    footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
                });
            }
        } else if (state === "Failed"){
            try{
                var response =  await apis.get("api/massUpload/getFailedTaskDetails?ReferenceId="+selectedItemRef, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                    },
                });
                const{close}=
                showDialog({
                    headerText: "Upload Failed",
                    state:"Error",
                    children: 
                    <Form>
                        <FormItem label="SessionId">
                            <Text>{response.data[0].sessionId}</Text>
                        </FormItem>
                        <FormItem label="Reason">
                            <Text>{response.data[0].reason}</Text>
                        </FormItem>
                       <FormItem label="TimeStamp">
                            <Text>{response.data[0].sessionTimeStamp}</Text>
                       </FormItem>
                        <FormItem label="Data">
                            <TextArea valueState="Error" rows="30" disabled="false" value={JSON.stringify(JSON.parse(response.data[0].data), null, 2)}>                            
                            </TextArea>
                        </FormItem>
                    </Form>,
                    footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
                });
                setBusy(false);
            } catch(error) {
                setBusy(false);
                const{close}=
                showDialog({
                    headerText: 'Unavailable',
                    state:"Information",
                    children: 'Logs do not exist for this task',
                    footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
                });
            }
        }      
    };
    const onBackToTaskList = () => {
        setBusy(true);
        setShowTaskDetails(false);
        setTimeout(() => {
            setBusy(false);
            setShowTasks(true);
        }, 1000);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${dayName} ${day}/${month}/${year}`;
      };
    
    useEffect(() => {
       fetchAllTasks();
    },[]);
    const fetchAllTasks = async () => {
        await apis.get("api/massUpload/getTasks", {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            },
        }).then(response=>{
            response.data.sort((a, b) => new Date(b.sessionTimeStamp) - new Date(a.sessionTimeStamp));
            setTasks(response.data);
        });
    };
    return (
        <>
        
            <MessageStrip style={{paddingBottom:'1%'}}>
                Please reach out to the administrator if you want to access logs from before 15 days. The logs will be retained for 3 months.
            </MessageStrip>
            <BusyIndicator delay="0" style={{width:'100%', alignContent:'center'}} active={busyState}>
           
            {showTasks && <List style={{width:'100%'}}
                headerText="Uploads done in the last 15 days"
                mode="SingleSelect"
                onItemClick={onTaskSelect}
                separators="All"
                >
                    {tasks && tasks.map(row => {
                      return <StandardListItem id={row.sessionId} type="Navigation" additionalText={row.numberOfRecords}>
                                {formatDate(row.sessionTimeStamp)}
                            </StandardListItem>
                    })}
                
                </List>}
            {showTaskDetails && 
            <Page style={{height:'100vh'}}
            header={<Bar design="Header" startContent={<Button onClick={onBackToTaskList}>Back</Button>}><Label></Label></Bar>}
            >
                <List
                headerText="Upload Results (Click to view details)"
                mode="SingleSelect"
                onItemClick={onViewDetails}
                separators="All"
                >
                    {taskDetail && taskDetail.map(row => {
                      return <StandardListItem id={row.referenceId} type="Active" additionalTextState={row.status==="Success"?"Success":"Error"} additionalText={row.status}>
                                {formatDate(row.sessionTimeStamp)}
                            </StandardListItem>
                    })}
                
                </List>
            </Page>
            
            }
             </BusyIndicator>
        </>
    );
}
export default MassCreateMembersResultsLayout;
