import { useEffect, useState } from "react";
import { ObjectStatus  } from '@ui5/webcomponents-react';

function MemberUploadTable(props){
var TableData = null;
var column = null;
var [headerData, setHeaderData] = useState();
var [tableData, setTableData] = useState(); 
var headerText = {
    "Status":"Initial Check",
    "child":"Member Type",
    "title":"Title",
    "lastName":"Last Name",
    "firstName":"First Name",
    "middleName": "Middle Name",
    "dob":"Date Of Birth",
    "gender":"Gender",
    "telephone":"Phone",
    "mobile":"Mobile",
    "email":"Email Address",
    "add1":"Address Line 1",
    "add2":"Address Line 2",
    "city":"City",
    "postalcode":"Postal Code",
    "country": "Country",
    "marritalStatus":"Marrital Status",
    "placeOfBirth":"Place Of Birth",
    "nationality": "Nationality",
    "birthCountry":"Birth Country",
    "disabled":"Physically Disabled",
    "emergencyContact":"Emergency Contacts",
    "profession":"Profession",
    "salvationStatus":"Salvation Status",
    "wbsStatus":"Water Baptism",
    "hbsStatus": "Holy Spirit Baptism",
    "AssemblyName":"Assembly Name",
    "AssemblyContact":"Assembly Contact"
};
// get table column
 

 // get table heading data
 const ThData =()=>{
    setHeaderData(column.map((data)=>{
         return <th key={data}>{headerText[data]}</th>
     }));
 }
useEffect(()=>{
    if(props.data){
    TableData = props.data;
    column = Object.keys(TableData[0]);
    ThData();
    tdData();
}
},[props.data])
// get table row data
const tdData =() =>{
   
    setTableData(TableData.map((data, i)=>{
            return(
                <tr>
                     {
                        column.map((v, j)=>{
                            if(j===0){
                                return <td><ObjectStatus showDefaultIcon="true" state={(data[v]==="Passed")?"Success":"Error"}>{data[v]}</ObjectStatus></td>;
                            } else if(j===1){
                                return <td>{(data[v]===true)?"Child":"Adult"}</td>;
                            } else {
                                return <td>{data[v]}</td>;
                            }
                            
                        })
                     }
                </tr>
            )
       
     }));
}


  return (
      <table className="table">
        <thead>
         <tr>{headerData}</tr>
        </thead>
        <tbody>
        {tableData}
        </tbody>
       </table>
  )
}
export default MemberUploadTable;