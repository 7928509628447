import React, { useState, useRef, useEffect } from "react";
import { TableColumn, Link, Toolbar, TextArea, Dialog, Title, MessageStrip, Table, Text, TableRow, TableCell, ShellBar, Toast, Form, FormItem, FormGroup, Input, Avatar, Bar, ComboBoxItem, ComboBox, FlexBoxDirection, FlexBoxJustifyContent, Button, FlexBox, Label, Page, Panel } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
function EditEventMetadataLayout(props) {
    const entityName = useRef();
    const entityDescription = useRef();
    const eventParticipationType = useRef();
    const updateButton = useRef();
    const block = useRef(), country = useRef(), rcc = useRef(), area = useRef(), dist = useRef(), assembly = useRef();
    const [entity, setEntity] = useState("");
    const handleUpdate = (e) => {
        var id = e.currentTarget.id;
        var payload = null;
        switch (props.entity) {
            case "Event Type":
                payload = {
                    "Id": id,
                    "Type": entityName.current.value,
                    "Description": entityDescription.current.value,
                    "ParticipationType": eventParticipationType.current.value
                };
                postUpdate(payload, "api/metadata/updateEvent?id=");
                break;
            case "External Assemblies":
                payload = {
                    "Id": props.id,
                    "Block": block.current.value,
                    "Country": country.current.value,
                    "RCC": rcc.current.value,
                    "Area": area.current.value,
                    "District": dist.current.value,
                    "Assembly": assembly.current.value
                };
                putUpdate(payload);
                break;
            default:
                break;
        }

    }
    const postUpdate = async (payload, url) => {
        apis.put(url + payload.Id, payload, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
        }).then(response => {
            console.log(response);
            props.close();
        }).catch(err => {
            console.log(err);
        })
    };
    const putUpdate = async (payload) => {
        apis.put(props.url + props.id, payload, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
        }).then(response => {
            console.log(response);
            props.close();
        }).catch(err => {
            console.log(err);
        })
    };
    const fillContent = async (entity, url, id) => {
        const response = await apis.get(url + id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        }).then(res => {
            if (entity === "Event Type") {
                entityName.current.value = res.data.type;
                entityDescription.current.value = res.data.description;
                eventParticipationType.current.value = res.data.participationType;
                updateButton.current.id=res.data.id;
            } else if (entity === "External Assemblies") {
                block.current.value = res.data.Block;
                country.current.value = res.data.Country;
                rcc.current.value = res.data.RCC;
                area.current.value = res.data.Area;
                dist.current.value = res.data.District;
                assembly.current.value = res.data.Assembly;
            }
        });
    };
    useEffect(() => {
        setEntity(props.entity);
        if (props.entity === "Event Type") {
            if (props.id != "")
                fillContent(props.entity, "api/metadata/getEventType?id=", props.id);
        } else if (props.entity === "External Assemblies") {
            fillContent(props.entity, props.url, props.id);
        }
    }, [props]);
    return (
        <>
            <Form style={{ padding: "1%", alignItems: 'center' }}>
                <FormGroup titleText="Basic Details">
                    <FormItem label="">
                        <Input ref={entityName} placeholder={entity + " Name"} style={{ width: '50%' }} />
                    </FormItem>
                    <FormItem label="">
                        <TextArea ref={entityDescription} placeholder={entity + " Description"} style={{ width: '50%' }} />
                    </FormItem>
                    <FormItem label="">
                        <ComboBox style={{ width: '50%' }} placeholder={entity + " Participation Type"} ref={eventParticipationType}>
                            <ComboBoxItem text="Closed Event" />
                            <ComboBoxItem text="Open Event" />
                            <ComboBoxItem text="Registrations Only" />
                        </ComboBox>
                    </FormItem>
                </FormGroup>
            </Form>
            <Form style={{ padding: "1%", alignItems: 'center' }}>
                <FormItem label="">
                    <Button design="Emphasized" ref={updateButton} style={{ width: '25%' }} onClick={handleUpdate}>
                        {"Update " + entity}
                    </Button>
                    <Button style={{ width: '25%' }} onClick={props.close}>
                        Cancel
                    </Button>
                </FormItem>
            </Form>
        </>
    );
};
export default EditEventMetadataLayout;