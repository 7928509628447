import React, { useState, useEffect, useRef } from 'react';
import { Button, Bar, Label, Page, Form, FormItem, Input, Select, Option, AnalyticalTable } from '@ui5/webcomponents-react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import apis from '../../apis/api';

import ShellBarComponent from '../../components/ShellBarComponent';

function Attendance() {
    const [attendees, setAttendees] = useState([]);
    const [newAttendee, setNewAttendee] = useState({ name: '', eventName: '', gender: '', assembly: ''});
    // const [newAttendee, setNewAttendee] = useState({ name: '', eventName: '', usher: '', member: '', gender: '', assembly: ''});
    const [records, setRecords] = useState({ eventName: '', soulsWon: '', newcomers: '', holySpiritBaptism: '', attendance: '' , females: '', males: ''});
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [selectedAssembly, setSelectedAssembly] = useState('');
    const [selectedEntityType, setSelectedEntityType] = useState('');
    const [entityOptions, setEntityOptions] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState('');
    const [membeFilter, setMemberFilter] = useState({
        "assembly": "",
      });
    
    const handleFilter = (key, value) => {
        setMemberFilter({...membeFilter, [key]: value})
    }

    

    const memberAssembly = membeFilter.assembly

    useEffect(() => {
        if(memberAssembly != "") {
            loadMemberData()
        }
    }, [memberAssembly]);

    useEffect(() => {
        document.title = 'CMS - Event Manager';
        fetchAllEvents();
    }, []);

    const navigate = useNavigate();

    const loadMemberData = async () => {
        const payload = membeFilter
        console.log("LoadMemr payload >>> ", payload)
        apis.post('api/MemebershipManager/getFilteredMembers', payload,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(response =>{
        console.log("LoadMemr response >>> ", response.data)

            // setAttendees(response.data);
            reconstructAtendees(response.data)

        }).catch(err =>{
            console.log(err);
        })
    };
   


    const entityCat = useRef();

    const onEntityTypeSelect = async (e) => {
        const entityType = e.target.value;

        console.log("onEntityTypeSelect >>> ", entityType, entityCat.current.value)
        setSelectedEntityType(entityType);

        if (entityType) {
            await fetchEntityOptions(entityType);
        } else {
            setEntityOptions([]);
        }
    };

    const fetchEntityOptions = async (entityType) => {
        const apiURLs = {
            'Area': 'api/metadata/getAllAreas',
            'District': 'api/metadata/getAllDistricts',
            'Local Assembly': 'api/metadata/getAllLocalAssemblies'
        };
        const url = apiURLs[entityType];

        try {
            const response = await apis.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                }
            });
            if (response.status === 200) {
                let options;
                if(entityType == 'Area') {
                    options = response.data.map(item => ({ id: item.areaId,   name: item.areaName }));
                }
                if(entityType == 'District') {
                    options = response.data.map(item => ({ id: item.districtId,   name: item.districtName }));
                }

                
                if(entityType == 'Local Assembly') {
                    options = response.data.map(item => ({ id: item.assemblyId,   name: item.assemblyName }));
                }
                setEntityOptions(options);
            }
        } catch (error) {
            console.error('Error fetching entities:', error);
            setEntityOptions([]);
        }
    };

    const onEntitySelect = (e) => {
       

        const entinty = e.target.value.split("==>")

        console.log('Selected Entity:', entinty[0]) ; 
        const selectedEntityName = entinty[1];
        const selectedEntityID = entinty[0];
        setNewAttendee({ ...newAttendee, assembly: selectedEntityName });
        setSelectedAssembly(selectedEntityName)
        

        if (selectedEntityType == "Local Assembly") {
            handleFilter("assembly", selectedEntityID)
        }else {
            setAttendees([])
        }
    };

    const reconstructAtendees = (attendees) => {
        const newAttendees = attendees.map((newAttendee, index) => {
            const currentTime = new Date().toLocaleTimeString();
            const firstName = `${newAttendee.firstName}`;
            const lastName = `${newAttendee.lastName}`;

            
            return {
                serialNumber: index + 1,
                name: firstName+ ' ' +lastName,
                eventName: selectedEvent,
                // usher: '',
                // member: '',
                gender: newAttendee.gender,
                assembly: selectedAssembly,
                status: selectedEntityType === 'Local Assembly' ? 'To be marked' : 'Attended',
                time: currentTime,
                date: new Date().toISOString().split('T')[0],
            };  
        })
        
        console.log('Adding New Entry:', newAttendees); 
        setAttendees(newAttendees);
        
        // setNewAttendee({ name: '', eventName: '', usher: '', member: '', gender: '', assembly: ''});
    };

    const StatusCell = ({ value, row }) => {
        if (selectedEntityType === 'Local Assembly') {
            return (
                <select value={value} onChange={(e) => updateAttendanceStatus(row.index, e.target.value)}>
                    <option value="To be marked">To be marked</option>
                    <option value="Attended">✔</option>
                    <option value="Absent">X</option>
                </select>
            );
        } else {
            return <span>{value}</span>;
        }
    };
    
    const updateAttendanceStatus = (rowIndex, newStatus) => {
        const updatedAttendees = attendees.map((attendee, index) => {
            if (index === rowIndex) {
                return { ...attendee, status: newStatus };
            }
            return attendee;
        });
        setAttendees(updatedAttendees);
    };
    

    
    const onAssemblySelect = (e) => {
        const selectedAssembly = e.detail.selectedOption.textContent;
        console.log('Selected Assembly:', selectedAssembly); // Debugging line
        setNewAttendee({ ...newAttendee, assembly: selectedAssembly });
    };
    

    const onUsherSelect = (e) => {
        setNewAttendee({ ...newAttendee, usher: e.detail.selectedOption.value });
    };



    const fetchAllEvents = async () => {
        const url = "getMyEvents";
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
        };
    
        console.log(`Making API request to: ${url}`);
        console.log(`Headers: `, headers);
    
        try {
            const response = await apis.get(url, { headers });
            if (response.status === 200) {
                setEvents(response.data);
            }
        } catch (error) {
            console.error('Error fetching events:', error);
            
        }
    };
    

    const addAttendee = () => {
        const currentTime = new Date().toLocaleTimeString();
        const newEntry = {
            serialNumber: attendees.length + 1,
            name: newAttendee.name,
            eventName: newAttendee.eventName,
            // usher: newAttendee.usher,
            // member: newAttendee.member,
            gender: newAttendee.gender,
            assembly: newAttendee.assembly,
            status: 'Attended',
            time: currentTime,
            date: new Date().toISOString().split('T')[0],
        };
        console.log('Adding New Entry:', newEntry); 
        setAttendees([...attendees, newEntry]);
        setNewAttendee({ name: '', eventName: '',  gender: '', assembly: ''});
        
        // setNewAttendee({ name: '', eventName: '', usher: '', member: '', gender: '', assembly: ''});
    };
    

    const handleRecordChange = (e, field) => {
        setRecords({ ...records, [field]: e.target.value });
    };
    const [isSaveData, setIsSaveData] = useState(false);

    const saveData = async () => {
       
        const attendanceCounterData = {
            Type: "90c9b965-bb9c-4b8c-b4d2-0087fc0d5d90", 
            EventId: selectedEvent,
            Count: records.attendance
        };
    
        try {
            await apis.post('https://servicescopuk.azurewebsites.net/api/AttendanceCounters', attendanceCounterData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                }
            });
    
            console.log('Attendance Counter Data saved successfully');
        } catch (error) {
            console.error('Error saving Attendance Counter Data:', error);
        }
    
        // Save Female & Male Attendance Data
       
        const femaleData = {
            AttendanceTypeId: "female-attendance-type-id",
            AttendanceTypeName: "Women",
            Description: "Female members of the church",
            Count: records.females
        };
    
        const maleData = {
            AttendanceTypeId: "male-attendance-type-id",
            AttendanceTypeName: "Men",
            Description: "Male members of the church",
            Count: records.males
        };
    
        try {
            await Promise.all([
                apis.post('https://testcopukservices.azurewebsites.net/api/AttendanceTypes', femaleData, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                    }
                }),
                apis.post('https://testcopukservices.azurewebsites.net/api/AttendanceTypes', maleData, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                    }
                })
            ]);
    
            console.log('Female and Male Attendance Data saved successfully');
        } catch (error) {
            console.error('Error saving Female and Male Attendance Data:', error);
        }
    
        
        if (selectedEntityType === 'Local Assembly') {
            const memberEventAttendanceData = attendees.map(attendee => ({
                MemberId: attendee.memberId, 
                EventId: selectedEvent
            }));
    
            try {
                await Promise.all(memberEventAttendanceData.map(memberData => 
                    apis.post('https://testcopukservices.azurewebsites.net/api/MemberEventAttendances', memberData, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                        }
                    })
                ));
    
                console.log('Member Event Attendance Data saved successfully');
            } catch (error) {
                console.error('Error saving Member Event Attendance Data:', error);
            }
        }
    
        setIsSaveData(true);
    };
    
    // const saveData = async () => {
    //     const eventData = {
    //         eventName: selectedEvent,
    //         attendees: newAttendee,
    //         records: records
    //     };
    
    //     try {
    //         const response = await apis.post('api/MemberEventAttendances', eventData, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN')
    //             },
    //         });
    
    //         console.log('Data saved successfully', response.data);
    //         setIsSaveData(true);
    //     } catch (error) {
    //         console.error('Error saving data:', error);
    //         if (error.response) {
    //             console.error('Server response:', error.response.data);
    //         }
    //     }
    // };
    

    
    

    const updateMyData = (rowIndex, columnId, value) => {
        setAttendees(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      };

      const [isEditMode, setIsEditMode] = useState(false);

      const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
        const [value, setValue] = useState(initialValue);
    
        const onChange = e => {
          setValue(e.target.value);
        };
    
        const onBlur = () => {
          updateMyData(index, id, value);
        };
    
        return isEditMode ? (
          <Input value={value} onChange={onChange} onBlur={onBlur} />
        ) : (
          <span>{initialValue}</span>
        );
      };

    
    
    const columns = [
        { Header: 'S/N', accessor: 'serialNumber', Cell: ({ row }) => row.index + 1 },
        { Header: 'Name', accessor: 'name', Cell: props => <EditableCell {...props} updateMyData={updateMyData} /> },
        { Header: 'Gender', accessor: 'gender' },
        { Header: 'Status', accessor: 'status', Cell: StatusCell, },
        { Header: 'Time', accessor: 'time' },
        { Header: 'Date', accessor: 'date' },
        { Header: 'Event Name', accessor: 'eventName' },
        { Header: 'Assembly', accessor: 'assembly' },
        // { Header: 'Usher', accessor: 'usher' },
        // { Header: 'Member', accessor: 'member' }
    ];

    const getRecordsTableData = () => {
        return [
            { category: 'Event', value: records.eventName },
            { category: 'Attendance', value: records.attendance },
            // { category: 'Offerings', value: records.offerings },
            { category: 'Souls Won', value: records.soulsWon },
            { category: 'Newcomers', value: records.newcomers },
            { category: 'Holy Spirit Baptism', value: records.holySpiritBaptism },
            { category: 'Females', value: records.females },
            { category: 'Males', value: records.males },
            
            
        ];
    };

    const recordsColumns = [
        { Header: 'Category', accessor: 'category' },
        { Header: 'Value', accessor: 'value' }
    ];

    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
      };
    
      const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}` + '\r\n';

        return array.reduce((str, next) => {
            str += `${Object.values(next).map(value => `"${value}"`).join(",")}` + '\r\n';
            return str;
        }, str);
    };


    const downloadCSV = () => {
        try {
            let csvContent = "data:text/csv;charset=utf-8,";
    
            if (attendees.length === 0) {
                console.error('No data available to download');
                return;
            }
    
            csvContent += Object.keys(attendees[0]).join(",") + "\r\n";
            attendees.forEach(obj => {
                let row = Object.values(obj).join(",");
                csvContent += row + "\r\n";
            });
    
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `${newAttendee.eventName || "Event"}-attendance.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            console.log('CSV download initiated');
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };
    

    return (
        <div>
            <motion.div initial={{ opacity: 0 }} animate={{ width: "100%", opacity: 1 }} exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}>
                <ShellBarComponent />
                <Page header={<Bar startContent={<Button icon="nav-back" title="Go back" onClick={() => navigate("/eventManager")} />}><Label>Record Attendance</Label></Bar>} style={{ height: "90vh" }}>
                    
                    <Form style={{ marginBottom: '20px' }}>

                        
                    <FormItem label="Event Name">
                        <Select 
                            value={newAttendee.eventName} 
                            onChange={e => {
                                setNewAttendee({ ...newAttendee, eventName: e.detail.selectedOption.value });
                                setSelectedEvent(e.detail.selectedOption.value);
                                setRecords({ ...records, eventName: e.detail.selectedOption.value }); // Update records state
                            }}
                        >
                            <Option value="">Select Event</Option>
                            {events.map(event => (
                                <Option key={event.id} value={event.name}>{event.name}</Option>
                            ))}
                        </Select>
                    </FormItem>


                        <FormItem label="Assembly">
                        {/* <Select ref={entityCat} value={selectedEntityType} onChange={onEntityTypeSelect}>
                            <Option value="">Select Type</Option>
                            <Option value="Area">Area</Option>
                            <Option value="District">District</Option>
                            <Option value="Local Assembly">Local Assembly</Option>
                        </Select> */}

                        <select ref={entityCat} value={selectedEntityType} onChange={onEntityTypeSelect}>
                            <option value="">Select Type</option>
                            <option value="Area">Area</option>
                            <option value="District">District</option>
                            <option value="Local Assembly">Local Assembly</option>
                        </select>
                    </FormItem>

                    {selectedEntityType && (
                        <FormItem label={`Select ${selectedEntityType}`}>
                            {/* <Select value={selectedEntity} onChange={onEntitySelect}>
                                {entityOptions.map(option => (
                                    <Option key={option.id} value={option.id}>{option.name}</Option>
                                ))}
                            </Select> */}

                            <select defaultValue={selectedEntity} onChange={onEntitySelect}>
                                <option readonly key={null} value={null}>--Select Entity Type --</option>
                                {entityOptions?.map(option => (
                                    <option key={option.id} value={`${option.id}==>${option.name}`}>{option.name}</option>
                                ))}
                            </select>
                        </FormItem>
                        )}
                       {/* {selectedEntityType !== "Local Assembly" && selectedEntityType !== "Area" && selectedEntityType !== "District" && (
                            <FormItem label="Name">
                                <Input value={newAttendee.name} onChange={e => setNewAttendee({ ...newAttendee, name: e.target.value })} />
                            </FormItem>
                        )} */}

                         {/* {selectedEntityType !== "Local Assembly" && (
                        <FormItem label="Gender">
                        <Select value={newAttendee.gender} onChange={e => setNewAttendee({ ...newAttendee, gender: e.detail.selectedOption.value })}>
                            <Option value="Female">Female</Option>
                            <Option value="Male">Male</Option>
                            <Option value="Others">Others</Option>
                        </Select>
                        </FormItem>
                        )} */}

                        {/* {selectedEntityType !== "Local Assembly" && (
                        <FormItem label="Member Type">
                        <Select value={newAttendee.member} onChange={e => setNewAttendee({ ...newAttendee, member: e.detail.selectedOption.value })}>
                            <Option value="Member">Member</Option>
                            <Option value="New Member">New Member</Option>
                            <Option value="Visitor">Visitor</Option>
                        </Select>
                        </FormItem>
                        )} */}

                        {/* <FormItem label="Usher">
                            <Select value={newAttendee.usher} onChange={onUsherSelect}>
                                <Option value="Emmanuel">Emmanuel</Option>
                                <Option value="Angela">Angela</Option>
                                <Option value="Ruth">Ruth</Option>
                                <Option value="Victoria">Victoria</Option>
                                <Option value="James">James</Option>
                                <Option value="Jonathan">Jonathan</Option>
                            </Select>
                        </FormItem> */}
                        
                        
                        <FormItem label="Souls Won">
                            <Input type="number" value={records.soulsWon} onChange={(e) => handleRecordChange(e, 'soulsWon')} />
                        </FormItem>
                        <FormItem label="Newcomers">
                            <Input type="number" value={records.newcomers} onChange={(e) => handleRecordChange(e, 'newcomers')} />
                        </FormItem>
                        <FormItem label="Holy Spirit Baptism">
                            <Input type="number" value={records.holySpiritBaptism} onChange={(e) => handleRecordChange(e, 'holySpiritBaptism')} />
                        </FormItem>
                        <FormItem label="Females">
                            <Input type="number" value={records.females} onChange={(e) => handleRecordChange(e, 'females')} />
                        </FormItem>
                        <FormItem label="Males">
                            <Input type="number" value={records.males} onChange={(e) => handleRecordChange(e, 'males')} />
                        </FormItem>
                        {/* <FormItem label="Offerings">
                            <Input type="number" value={records.offerings} onChange={(e) => handleRecordChange(e, 'offerings')} />
                        </FormItem> */}
                        <FormItem label="Attendance">
                            <Input type="number" value={records.attendance} onChange={(e) => handleRecordChange(e, 'attendance')} />
                        </FormItem>
                    </Form>
                    {/* {selectedEntityType !== "Local Assembly" && (
                        <div>
                        <Button onClick={addAttendee} style={{ marginRight: '10px' }}>Add to Attendance</Button>
                        </div>
                    )} */}
                    
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: '25%' }}> 
                            <h3>Event Records</h3>
                            <AnalyticalTable 
                                data={getRecordsTableData()} 
                                columns={recordsColumns} 
                                visibleRows={getRecordsTableData().length} 
                            />
                        </div>
                    </div>
                    {selectedEntityType === "Local Assembly" && (
                        <div>
                            <h3 style={{ marginTop: '20px' }}>Attendees</h3>
                            <AnalyticalTable data={attendees} columns={columns} visibleRows={10} />
                        </div>
                    )}
                    
                    {!isSaveData ? (
                        <div style={{ display: 'flex', justifyContent: '', marginLeft:'42.5%', alignItems: 'center', marginBottom: '10px' }}>
                            <Button onClick={toggleEditMode}>{isEditMode ? 'Finish Editing' : 'Edit Attendees'}</Button>
                            <Button onClick={downloadCSV} style={{ marginRight: '10px' }}>Download as CSV</Button>
                            <Button onClick={saveData} style={{ marginLeft: '10px' }}>Save Data</Button>
                        </div>
                    ) : (
                        <div>
                            Attendance Saved
                        </div>
                    )}

                </Page>
            </motion.div>
        </div>
    );
}

export default Attendance;

