import React, { useContext, useEffect } from 'react'
import AuthContext from "../../contexts/AuthProvider";
import { useNavigate } from 'react-router-dom';
import ShellBarComponent from '../../components/ShellBarComponent';
import { motion } from 'framer-motion';
import { Page, Button, Label, Bar, SideNavigation, SideNavigationItem, SideNavigationSubItem, FlexBox } from '@ui5/webcomponents-react';
import { useState } from 'react';
function AppSettingsLayout() {
    const authUser = useContext(AuthContext);
    const [content, setContent] = useState();
    const loadContent = (page) => {
        if(page === "Settings"){

        }
    };
    useEffect(() => {
        document.title = 'CMS - Settings';
        loadContent("Settings");
    });
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <motion.div initial={{ opacity: 0 }} animate={{ width: "100%", opacity: 1 }} exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}>
                <ShellBarComponent />
                <Page header={<Bar startContent={<Button icon="nav-back" title="Application Settings" />}><Label>Application Settings</Label></Bar>}
                    style={{ height: '100vh' }}>
                    <FlexBox direction="row" style={{ height: "100vh" }}>
                        <FlexBox direction="column" >
                            <SideNavigation style={{ float: "left" }}
                                onSelectionChange={function noRefCheck() { }}
                                >
                                
                                <SideNavigationItem selected="true"
                                    icon="calendar"
                                    text="Security"
                                >
                                </SideNavigationItem>
                            </SideNavigation>
                        </FlexBox>
                        <FlexBox direction="column">
                            {content}
                        </FlexBox>
                    </FlexBox>

                </Page>
            </motion.div>
        </React.Fragment>
    )
}

export default AppSettingsLayout;
