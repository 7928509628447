import React, { useEffect, useState } from 'react';
import { Button, Bar, Label, Page, List, StandardListItem } from '@ui5/webcomponents-react';
import { LineChart } from '@ui5/webcomponents-react-charts';
import "@ui5/webcomponents-icons/dist/idea-wall.js";
import "@ui5/webcomponents-icons/dist/gantt-bars.js";
import "@ui5/webcomponents-icons/dist/manager-insight.js";
import countries from '../../assets/countries.json';
import "@ui5/webcomponents-icons/dist/provision.js";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import ShellBarComponent from '../../components/ShellBarComponent';
import EventDetail from '../EventManager/EventDetail';
import apis from '../../apis/api';
import '../../styles/MasterDetail.css';
function ManageEvent() {
    const navigate = useNavigate();
    const goBack = () => {
        navigate("/eventManager");
    };
    const [selectedItem, setSelectedItem] = useState(null);
    const [isListVisible, setListVisible] = useState(true);

    
    const [events, setEventList] = useState([]);
    const handleListItemClick = (event) => {
        setSelectedItem(event.detail.item);

        // Hide the list on mobile when an item is clicked
        if (window.innerWidth <= 768) {
            setListVisible(false);
        }
    };
    const readEvents = async function () {
        const response = await apis.get("getMyEvents", {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        });
        setEventList(response.data);
    };

    const toggleList = () => {
        setListVisible(!isListVisible);
    };
    useEffect(() => {
        document.title = 'CMS - Manage Events';
        readEvents();
    }, []);
    return (
        <React.Fragment>
            <motion.div initial={{ opacity: 0 }} animate={{ width: "100%", opacity: 1 }} exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}>
                <ShellBarComponent />
                <Page header={<Bar startContent={<Button icon="nav-back" title="Go back" onClick={goBack} />}><Label>Manage Events</Label></Bar>} style={{ height: "90vh" }}>
                    <div className="master-detail-container">
                        {window.innerWidth <= 768 && (
                            <button className="toggle-button" onClick={toggleList}>
                                Select Event
                            </button>
                        )}
                        <div className={`master-list ${isListVisible ? 'visible' : 'hidden'}`}>
                            <List headerText="Select Event" onItemClick={handleListItemClick}>
                                {events && events.map(row => {
                                    return  <StandardListItem id={row.id} additionalText={row.description}>{row.name}</StandardListItem>
                                })}
                                
                            </List>
                        </div>
                        <div className="detail-content">
                            {selectedItem && <EventDetail selectedItem={selectedItem} />}
                        </div>
                    </div>
                </Page>
            </motion.div>
        </React.Fragment>
    );
};
export default ManageEvent;