import React, { useState, useEffect } from 'react'
import AuthContext from "../../contexts/AuthProvider";
import logo from '../../assets/badge.png';
import { ShellBar, Avatar, Tab, Page, Bar, Button, Label, TabContainer, TabSeparator, FlexBox, Text } from '@ui5/webcomponents-react';
import { SplitterLayout, SplitterElement } from '@ui5/webcomponents-react';
import { useNavigate } from 'react-router-dom';
import RegisterLayout from './Register/RegisterLayout';
import ManageUsersLayout from './ManageUsersLayout';
import RoleManagerLayout from './RoleManagerLayout';
import {motion} from 'framer-motion';
import ShellBarComponent from '../../components/ShellBarComponent';
function AuthManagerLayout() {
    useEffect(() => {
        document.title = 'CMS - User Security Manager';
      });
      const [refresh, doRefresh] = useState(0);
      const navigate = useNavigate();
      const goHome = ()=>{
        navigate('/');
      };
    return (
        <React.Fragment>
          <motion.div  initial={{opacity:0}} animate={{width:"100%", opacity:1}} exit={{x:window.innerWidth, transition:{duration: 0.5}}}>
          <ShellBarComponent/>
                <Page
                header={<Bar startContent={<Button icon="nav-back" title="Go back" onClick={goHome}/>}><Label>User Security Manager</Label></Bar>}
                style={{height: '100vh'}}>
                  <TabContainer onTabSelect={() => doRefresh(true)}>
                      <Tab selected text="Create Users">
                          <RegisterLayout refresh={refresh}/>
                      </Tab>
                      <TabSeparator />
                      <Tab text="Manage Users" >
                        <ManageUsersLayout refresh={refresh}/>
                      </Tab>
                      <TabSeparator />
                      <Tab text="Manage Roles" >
                        <RoleManagerLayout refresh={refresh}/>
                      </Tab>
                    </TabContainer>
                 </Page>
            </motion.div>
        </React.Fragment>
    )
}

export default AuthManagerLayout
