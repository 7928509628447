import React, { useState, useRef, useEffect } from "react";
import { TableColumn, Link, Toolbar, Dialog, Title, MessageStrip, Table, Text, TableRow, TableCell, ShellBar, Toast, Form, FormItem, FormGroup, Input, Avatar, Bar, ComboBoxItem, ComboBox, FlexBoxDirection, FlexBoxJustifyContent, Button, FlexBox, Label, Page, Panel } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
function EditMetaGlobalMiscLayout(props) {
    const [createAssembly, enableCreateAssemblyType] = useState(false);
    const [editTempAssembly, enableTempAssemblyEdit] = useState(false);
    const entityName = useRef();
    const entityDescription = useRef();
    const block = useRef(), country = useRef(), rcc = useRef(), area = useRef(), dist = useRef(), assembly = useRef();
    const [entity, setEntity] = useState("");
    const handleUpdate = () => {
        var payload = null;
        switch (props.entity) {
            case "Assembly Type":
                payload = {
                    "AssemblyTypeId": props.id,
                    "AssemblyTypeName": entityName.current.value,
                    "AssemblyTypeDescription": entityDescription.current.value
                };
                postUpdate(payload);
                break;
            case "External Assemblies":
                payload = {
                    "Id": props.id,
                    "Block": block.current.value,
                    "Country": country.current.value,
                    "RCC": rcc.current.value,
                    "Area": area.current.value,
                    "District": dist.current.value,
                    "Assembly": assembly.current.value
                };
                putUpdate(payload);
            break;
            default:
                break;
        }
        
    }
    const postUpdate = async(payload)=>{
        apis.post(props.url + props.id, payload, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
        }).then(response => {
            console.log(response);
            props.close();
        }).catch(err => {
            console.log(err);
        })
    };
    const putUpdate = async(payload)=>{
        apis.put(props.url + props.id, payload, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
        }).then(response => {
            console.log(response);
            props.close();
        }).catch(err => {
            console.log(err);
        })
    };
    const fillContent = async (entity, url, id) => {
        const response = await apis.get(url + id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        }).then(res=>{
            if (entity === "Assembly Type") {
                entityName.current.value = res.data.assemblyTypeName;
                entityDescription.current.value = res.data.assemblyTypeDescription;
            } else if(entity === "External Assemblies"){
                block.current.value = res.data.Block;
                country.current.value = res.data.Country;
                rcc.current.value = res.data.RCC;
                area.current.value = res.data.Area;
                dist.current.value = res.data.District;
                assembly.current.value = res.data.Assembly;
            }
        });
    };
    useEffect(() => {
        setEntity(props.entity);
        if (props.entity === "Assembly Type") {
            enableCreateAssemblyType(true);
            enableTempAssemblyEdit(false);
            if (props.id != "")
            fillContent(props.entity, "api/metadata/getLocalAssemblyTypeById?id=", props.id);
        } else if(props.entity === "External Assemblies"){
            enableTempAssemblyEdit(true);
            enableCreateAssemblyType(false);
            fillContent(props.entity, props.url, props.id);
        }
    }, [props]);
    return (
        <>
            <Form style={{ padding: "1%", alignItems: 'center' }}>
                <FormGroup titleText="Basic Details">
                    {createAssembly && <>
                        <FormItem label={entity + " Name"}>
                            <Input ref={entityName} placeholder={entity + " Name"} style={{ width: '50%' }} />
                        </FormItem>
                        <FormItem label={entity + " Description"}>
                            <Input ref={entityDescription} placeholder={entity + " Description"} style={{ width: '50%' }} />
                        </FormItem></>}
                    {editTempAssembly && <>
                        <FormItem label="Block">
                            <Input ref={block} placeholder="Enter Block Name" style={{ width: '50%' }} />
                        </FormItem>
                        <FormItem label="Country">
                            <Input ref={country} placeholder="Enter Country Name" style={{ width: '50%' }} />
                        </FormItem>
                        <FormItem label="RCC">
                            <Input ref={rcc} placeholder="Enter RCC Name" style={{ width: '50%' }} />
                        </FormItem>
                        <FormItem label="Area">
                            <Input ref={area} placeholder="Enter Area Name" style={{ width: '50%' }} />
                        </FormItem>
                        <FormItem label="District">
                            <Input ref={dist} placeholder="Enter District Name" style={{ width: '50%' }} />
                        </FormItem>
                        <FormItem label="Assembly">
                            <Input ref={assembly} placeholder="Enter Assembly Name" style={{ width: '50%' }} />
                        </FormItem>
                    </>
                    }
                </FormGroup>
            </Form>
            <Form style={{ padding: "1%", alignItems: 'center' }}>
                <FormItem label="">
                    <Button design="Emphasized" style={{ width: '25%' }} onClick={handleUpdate}>
                        {"Update " + entity}
                    </Button>
                    <Button style={{ width: '25%' }} onClick={props.close}>
                        Cancel
                    </Button>
                </FormItem>
            </Form>
        </>
    );
};
export default EditMetaGlobalMiscLayout;