import React, { useRef, useState, useEffect } from "react";
import countries from '../../assets/countries.json';
import { FlexBox, Wizard, Modals, Bar, Dialog, MessageStrip, CheckBox,Title, Label, WizardStep, RadioButton, ComboBox, ComboBoxItem, Toast, Page, DatePicker, Button, Input, FormItem, FormGroup, Form, Text } from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/nav-back.js";
import "@ui5/webcomponents-icons/dist/employee.js";
import "@ui5/webcomponents-icons/dist/contacts.js";
import "@ui5/webcomponents-icons/dist/map.js";
import "@ui5/webcomponents-icons/dist/building.js";
import "@ui5/webcomponents-icons/dist/heart.js";
import "@ui5/webcomponents-icons/dist/positive.js";
import ReactDOM from "react-dom";
import CustomAssemblyForm from "../../components/CustomAssemblyForm";
import $ from 'jquery'; 
import apis from '../../apis/api';
const DELETE_USER = "api/accounts/user/";
var selProfCatId = '';
var selLocalAssemblyId = '';
var selSSAssembly = '';
var selWbsAssembly = '';
var selHsAssembly = '';
function AddMemberLayout(props) {
  const showDialog = Modals.useShowDialog();
  const toastRef = useRef();
  const childY = useRef(), childN = useRef(), uploader = useRef(), title = useRef(), fName = useRef(), mName = useRef(), lName = useRef(),
    dob = useRef(), male = useRef(), female = useRef(), nat = useRef(), pob = useRef(), htown = useRef(),
    cob = useRef(), cor = useRef(), single = useRef(), married = useRef(), widow = useRef(), divorced = useRef(),
    prof = useRef(), profCat = useRef(), disableY = useRef(), disableN = useRef(), disableType = useRef(), mobile = useRef(),
    tele = useRef(), email = useRef(), emergency = useRef(), addLine1 = useRef(), addLine2 = useRef(), locality = useRef(), region = useRef(),
    city = useRef(), postalCode = useRef(), country = useRef(), localAssembly = useRef(), homeCell = useRef(), dojCop = useRef(), doD = useRef(),
    wBapY = useRef(), wBapN = useRef(), hBapY = useRef(), hBapN = useRef(), ssAssembly = useRef(), ssMinister = useRef(),
    ssY = useRef(), ssN = useRef(), ssDos = useRef(), ssCommunicantY = useRef(), ssCommunicantN = useRef(), 
    bsHs = useRef(), bsWater = useRef(), wbsDob = useRef(), wbsAssembly = useRef(), wbsOfficiatingMinister = useRef(),
    hbsDob = useRef(), salStatusDet = useRef(), hbsAssembly = useRef(), hbsOfficiatingMinister = useRef(), hsBaptRef = useRef(), waterBaptRef = useRef(),
    customAssemblyDialog = useRef(), userAccountY = useRef(), userAccountN = useRef(), loadingDialog=useRef();

  // Form input validation
  const [fname, setFname] = useState("");
  const [customAssemblyContent, setCustomAssemblyContent] = useState();
  const [lname, setLname] = useState("");
  const [gender, setGender] = useState("");
  const [childmemberY, setChildmemberY] = useState("");
  const [childmemberN, setChildmemberN] = useState("");
  const [assembly, setAssembly] = useState("");
  const [error, setError] = useState(false);
  
  //End

  const [image, setImage] = useState();
  const showToast = () => {
    toastRef.current.show();
  };
  const [message, setMessage] = useState("");
  const [profCatArr, setProfCat] = useState([]);
  const [disType, setDisType] = useState([]);
  const [localAssemblyArr, setLocalAssembly] = useState([]);
  const [homeCellArr, setHomeCell] = useState([]);
  const fillHomeCells = async (e) => {
    $.each(e.currentTarget.items,(k,v)=>{if(v.text === e.currentTarget.value){selLocalAssemblyId=v.id;return;}})
    const response = await apis.get("api/memberGrouping/getHomeCellsByEntityId?id="+selLocalAssemblyId, {
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
      }
    });
    setHomeCell(response.data);
  };
  const loadFormMetadata = async () => {
    var response = await apis.get("api/MetaProfessionCategories", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
      },
    });
    setProfCat(response.data);
    response = await apis.get("api/DisabilityTypes", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
      },
    });
    setDisType(response.data);
    response = await apis.get("api/metadata/getAllLocalAssemblies", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
      },
    });
    setLocalAssembly(response.data);
  };


  function validateEmail(email) {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Test the email against the regex
    return emailRegex.test(email);
  }
  const clearForm = () => {
    title.current.value = '';
    fName.current.value = '';
    mName.current.value = '';
    lName.current.value = '';
    dob.current.value = '';
    childY.current.checked = false;
    childN.current.checked = false;
    male.current.checked = false;
    female.current.checked = false;
    single.current.checked = false;
    married.current.checked = false;
    widow.current.checked = false;
    divorced.current.checked = false;
    nat.current.value = '';
    pob.current.value = '';
    htown.current.value = '';
    cob.current.value = '';
    cor.current.value = '';
    prof.current.value = '';
    profCat.current.value = '';
    disableY.current.checked = false;
    disableN.current.checked = false;
    disableType.current.value = '';
    disableType.current.disabled = true;
    profCat.current.value = '';

    mobile.current.value = '';
    tele.current.value = '';
    email.current.value = '';
    emergency.current.value = '';
    userAccountY.current.checked = false;
    userAccountN.current.checked = false;

    addLine1.current.value = '';
    addLine2.current.value = '';
    locality.current.value = '';
    region.current.value = '';
    city.current.value = '';
    postalCode.current.value = '';
    country.current.value = '';

    localAssembly.current.value = '';
    homeCell.current.value = '';
    dojCop.current.value = '';
    doD.current.value = '';

    // Clear Salvation Status section
    ssY.current.checked = false;
    ssN.current.checked = false;

    // Clear Salvation Status Details section
    salStatusDet.current.hidden = true;
    ssAssembly.current.value = '';
    ssMinister.current.value = '';
    ssDos.current.value = '';
    ssCommunicantY.current.checked = false;
    ssCommunicantN.current.checked = false;

    // Clear Type Of Baptism section
    bsWater.current.checked = false;
    bsHs.current.checked = false;

    // Clear Water Baptism Details section
    if (waterBaptRef.current) {
      waterBaptRef.current.hidden = true;
      wbsAssembly.current.value = '';
      wbsOfficiatingMinister.current.value = '';
      wbsDob.current.value = '';
    }

    // Clear Holy Spirit Baptism Details section
    if (hsBaptRef.current) {
      hsBaptRef.current.hidden = true;
      hbsAssembly.current.value = '';
      hbsOfficiatingMinister.current.value = '';
      hbsDob.current.value = '';
    }

  };
  const createMember = async () => {
    
      var baptismStatusArray = [];
      var err = false;
      // validate user account and email input
      if(userAccountY.current.checked === true){
        if(!validateEmail(email.current.value)){
          var err = true;
          email.current.valueState="Error";
        } else {
          email.current.valueState="None";
        }
      }
      // input validations
      if(fname.length==0||lname.length==0){
        fName.current.valueState="Error";
        lName.current.valueState="Error";
        err = true;
        setError(true);
      } 
      if(childmemberY.length=="" && childmemberN.length=="") {
        // setError(true);
        err=true;
        childY.current.valueState="Error";
        childN.current.valueState="Error";
        
      }
      if (selLocalAssemblyId.length<=0){
        localAssembly.current.valueState = "Error";
        err=true;
        setError(true);
      }
           
     if(!err) 
     {// when all fields are valid
        if(bsHs.current.checked){
          baptismStatusArray.push({
            BaptismId: "00000000-0000-0000-0000-000000000000",
            MemberId: "00000000-0000-0000-0000-000000000000",
            LocalAssemblyId: (selHsAssembly!=='')?selHsAssembly:"00000000-0000-0000-0000-000000000000",
            Minister: hbsOfficiatingMinister.current.value,
            Type: "Holy Spirit",
            Date: hbsDob.current.value
          });
        }
        if(bsWater.current.checked){
          baptismStatusArray.push({
            BaptismId: "00000000-0000-0000-0000-000000000000",
            MemberId: "00000000-0000-0000-0000-000000000000",
            LocalAssemblyId: (selWbsAssembly!=='')?selWbsAssembly:"00000000-0000-0000-0000-000000000000",
            Minister: wbsOfficiatingMinister.current.value,
            Type: "Water",
            Date: wbsDob.current.value
          });
        }
        var externalAssembly = false;
        if(ssAssembly.current.value.includes("Assembly")){
          externalAssembly = true;
        }
        var payload = {
          MemberId: "00000000-0000-0000-0000-000000000000",
          LocalAssemblyId: selLocalAssemblyId,
          ProfCatId: (selProfCatId!=="")?selProfCatId:"00000000-0000-0000-0000-000000000000",
          MemberAddress: {
            AddressId: "00000000-0000-0000-0000-000000000000",
            MemberId: " ",
            AddressLine1: (addLine1.current.value!=="")?addLine1.current.value:"-",
            AddressLine2: (addLine2.current.value!=="")?addLine2.current.value:"-",
            Locality: (locality.current.value!=="")?locality.current.value:"-",
            City: (city.current.value!=="")?city.current.value:"-",
            Region: (region.current.value!=="")?region.current.value:"-",
            PostalCode: (postalCode.current.value!=="")?postalCode.current.value:"-",
            Country: (country.current.value!=="")?country.current.value:"-",
          },
          salvationStatus: {
            AssuranceId: "00000000-0000-0000-0000-000000000000",
            MemberId: "00000000-0000-0000-0000-000000000000",
            LocalAssemblyId: (selSSAssembly!=='')?selSSAssembly:"00000000-0000-0000-0000-000000000000",
            ExternalAssembly: externalAssembly,
            OfficiatingMinister: ssMinister.current.value,
            SalvationStatusText: (ssY.current.checked===true)?"Yes":"No",
            Date: ssDos.current.value,
            CommunicantBool: (ssCommunicantY.current.checked===true)?true:false
          },
          baptismArray: baptismStatusArray,
          homeCellId: (homeCell.current.value!=='')?homeCell.current.value.split(' (')[1].replace(')', ''):"00000000-0000-0000-0000-000000000000",
          profilePicture: null,
          Title: title.current.value,
          FirstName: fName.current.value,
          MiddleName: mName.current.value,
          LastName: lName.current.value,
          DOB: dob.current.value,
          Gender: (male.current.checked)?"M":(female.current.checked)?"F":"",
          Nationality: nat.current.value,
          CountryOfBirth: cob.current.value,
          CountryOfResidence: cor.current.value,
          MaritalStatus: (single.current.checked)?"Single":(married.current.checked)?"Married":(widow.current.checked)?"Widowed":(divorced.current.checked)?"Divorced":"",
          MobileNumber: mobile.current.value,
          EmailAddress: email.current.value,
          TelNumber: tele.current.value,
          Profession: prof.current.value,
          ChurchStatus: "true",
          EmergencyContact: emergency.current.value, 
          ChildFlag: (childY.current.checked)?true:false,
          Disabled: (disableY.current.checked)?true:false,
          DisabilityType: (disableType.current.value!=='')?disableType.current.value.split(' (')[1].replace(')', ''):"00000000-0000-0000-0000-000000000000",
          DateOfJoining: dojCop.current.value,
          BirthPlace: pob.current.value,
          HomeTown: htown.current.value,
          DedicationDate: doD.current.value,
          WaterBaptised: bsWater.current.checked,
          HSBaptised: bsHs.current.checked,
          CreateUserAccount: (userAccountY.current.checked === true)?true:false
        };
        const response = await apis.post("api/MemebershipManager/addMember", payload, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
          },
        }).then(res=>{
          setMessage("Member added successfully");
          clearForm()
          const{close}=
        showDialog({
          headerText: 'Member Onboarding Successful',
          state:"Success",
          children: "Member has been added successfully",
          footer: <Bar endContent={<Button onClick={()=>close()}>Close</Button>} />
        });
        })
        .catch(err=>{
          var err = err;
          // setTimeout(function() {
          //   // closeLoadDialog.close();
          // }, 500);
          const{close}=
          showDialog({
            headerText: 'Server Error',
            state:"Error",
            children: err.message,
            footer: <Bar endContent={<Button onClick={()=>close()}>Close</Button>} />
          });
          setMessage("Server Error");
        });
        showToast();

      }
      else{
        const{close}=
        showDialog({
          headerText: 'Missing Information!',
          state:"Warning",
          children: "Please correct all the errors before submitting.",
          footer: <Bar endContent={<Button onClick={()=>close()}>Close</Button>} />
        });
      }
      
  };
  const setAssemblyId = (source, id)=>{
    if(source === "SalStatOtherAssembly"){
      selSSAssembly = id;
    } else if(source === "WaterBaptismAssembly"){
      selWbsAssembly = id;
    } else if(source === "HsBaptismAssembly"){
      selHsAssembly = id;
    }
  };
  
  const enterOtherAssembly = (e)=>{
    var source = e.currentTarget.id;
    var customId = "00000000-0000-0000-0000-000000000000";
    if(source === "SalStatOtherAssembly"){
      if(ssAssembly.current.id){
        customId = ssAssembly.current.id;
      }
      customAssemblyDialog.current.show();
      setCustomAssemblyContent(<CustomAssemblyForm returnRef={ssAssembly} setId={setAssemblyId} source={source} currentId={customId} close={dialogClose}/>);
    } else if(source === "HsBaptismAssembly"){
      if(hbsAssembly.current.id){
        customId = hbsAssembly.current.id;
      }
      customAssemblyDialog.current.show();
      setCustomAssemblyContent(<CustomAssemblyForm returnRef={hbsAssembly} setId={setAssemblyId} source={source} currentId={customId} close={dialogClose}/>);
    } else if(source === "WaterBaptismAssembly"){
      if(wbsAssembly.current.id){
        customId = wbsAssembly.current.id;
      }
      customAssemblyDialog.current.show();
      setCustomAssemblyContent(<CustomAssemblyForm returnRef={wbsAssembly} setId={setAssemblyId} source={source} currentId={customId} close={dialogClose}/>);
    }
  };
  const dialogClose = () => {
    customAssemblyDialog.current.close();
};
  useEffect(() => {
  }, [props.refresh]);
  useEffect(() => {
    try {
      loadFormMetadata();
      hsBaptRef.current.hidden = true;
      waterBaptRef.current.hidden = true;
      salStatusDet.current.hidden = true;
    } catch (err) {
      if (!err.response) {

        setMessage("No Server Response");
      } else if (err.response.status === 400) {

        setMessage("Bad request.");
      } else if (err.response.status === 401) {

        setMessage("Authorization has been denied for this request.");
      } else {

        setMessage("Failed to fetch bloc data");
      }
      showToast()
    }
  }, []);
  return (
    <>
      <Page
        style={{ height: '75vh', width: '100wh' }}>

        <Wizard
          onStepChange={function noRefCheck() { }}

        >
          <WizardStep
            icon="employee"
            selected
            titleText="Basic Details"
          >
            <Form>
              <FormGroup titleText="Basic Details">
                <FormItem label="">
                  <ComboBox placeholder="Title" ref={title} style={{ width: '50%' }}>
                    {titles && titles.map(row => {
                      return <ComboBoxItem text={row.title} />
                    })}
                  </ComboBox>
                </FormItem>
                <FormItem label="">
                  <Input placeholder="First Name" onChange={e=>setFname(e.target.value)}  ref={fName} style={{ width: '50%' }}>
                    <div slot="valueStateMessage">First Name cannot be empty</div>
                    </Input>
                </FormItem>
                
                <FormItem label="">
                  <Input placeholder="Middle Name"  ref={mName} style={{ width: '50%' }}/>
                </FormItem>
                <FormItem label="">
                  <Input placeholder="Last Name" onChange={e=>setLname(e.target.value)} ref={lName} style={{ width: '50%' }}>
                  <div slot="valueStateMessage">Last Name cannot be empty</div>
                    </Input>
                </FormItem>
                <FormItem label="">
                  <DatePicker placeholder="Date Of Birth" ref={dob} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <FlexBox alignItems="Center">
                    <Label required="true">Child Member : </Label>
                    <RadioButton name="AB"  onClick={e=>setChildmemberY(e.target.checked)} ref={childY} text="Yes" />
                    <RadioButton name="AB"  onClick={e=>setChildmemberN(e.target.checked)} ref={childN} text="No" />
                  </FlexBox>
                </FormItem>
                <FormItem label="">
                  <FlexBox alignItems="Center">
                    <Label required="true">Gender : </Label>
                    <RadioButton name="A" ref={male} text="Male" />
                    <RadioButton name="A" ref={female} text="Female" />
                  </FlexBox>
                </FormItem>
                <FormItem label="">
                  <FlexBox alignItems="Center">
                    <Label>Marital Status : </Label>
                    <RadioButton ref={single} name="B" text="Single" />
                    <RadioButton ref={married} name="B" text="Married" />
                    <RadioButton ref={widow} name="B" text="Widowed" />
                    <RadioButton ref={divorced} name="B" text="Divorced" />
                  </FlexBox>
                </FormItem>
                <FormItem label="">
                  <ComboBox placeholder="Nationality" ref={nat} style={{ width: '50%' }}>
                    {countries && countries.map(row => {
                      return <ComboBoxItem text={row.nationality} />
                    })}
                  </ComboBox>
                </FormItem>
                <FormItem label="">
                  <Input placeholder="Place Of Birth" ref={pob} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <Input placeholder="Hometown" ref={htown} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <ComboBox ref={cob} placeholder="Country Of Birth" style={{ width: '50%' }}>
                    {countries && countries.map(row => {
                      return <ComboBoxItem text={row.country} />
                    })}
                  </ComboBox>
                </FormItem>
                <FormItem label="">
                  <ComboBox ref={cor} placeholder="Country of Residence" style={{ width: '50%' }}>
                    {countries && countries.map(row => {
                      return <ComboBoxItem text={row.country} />
                    })}
                  </ComboBox>
                </FormItem>
                <FormItem label="">
                  <Input placeholder="Profession" ref={prof} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <ComboBox ref={profCat} placeholder="Professional Category" style={{ width: '50%' }} onChange={(e)=>{$.each(e.currentTarget.items,(k,v)=>{if(v.selected){selProfCatId=v.id;return;}})}}>
                    {profCatArr && profCatArr.map(row => {
                      return <ComboBoxItem id={row.ProfCatId} text={row.ProfCatName} />
                    })}
                  </ComboBox>
                </FormItem>
                <FormItem label="">
                  <FlexBox alignItems="Center">
                    <Text>Any Disability : </Text>
                    <RadioButton name="BE" ref={disableY} onChange={(e)=>(e.currentTarget.checked)?disableType.current.disabled=false:disableType.current.disabled=true} text="Yes" />
                    <RadioButton name="BE" ref={disableN} onChange={(e)=>(e.currentTarget.checked)?disableType.current.disabled=true:disableType.current.disabled=false} text="No" />
                  </FlexBox>
                </FormItem>
                <FormItem label="">
                  <ComboBox disabled="true" ref={disableType} placeholder="Type Of Disability" style={{ width: '50%' }}>
                    {disType && disType.map(row => {
                      return <ComboBoxItem text={row.DisabilityName+' ('+row.DisabilityId+')'} />
                    })}
                  </ComboBox>
                </FormItem>
              </FormGroup>
            </Form>
          </WizardStep>
          <WizardStep icon="contacts"
            titleText="Contact Details">
            <Form>
              <FormGroup titleText="Contact Details">
                <FormItem label="">
                  <Input ref={mobile} placeholder="Mobile Number" style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <Input ref={tele} placeholder="Telephone Number" style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <Input ref={email} placeholder="Email Address" style={{ width: '50%' }}>
                  <div slot="valueStateMessage">Email is mandatory to create a user account.</div>
                  </Input>

                </FormItem>
                <FormItem label="">
                  <Input ref={emergency} placeholder="Emergency Contact" style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <FlexBox alignItems="Center">
                    <Text>Create User Account for this member? : </Text>
                    <RadioButton name="UA" ref={userAccountY} text="Yes" />
                    <RadioButton name="UA" ref={userAccountN} text="No" />
                  </FlexBox>
                </FormItem>
                <FormItem label="">
                
                <Text style={{ width: '50%' }} wrapping="true"><Title level="H6">Please Note: </Title>A user account will be created with the Email provided above. Email is mandatory to create a user account. After the user account is created an invite Email is sent to the user to confirm their Email and also reset their password. </Text>
                </FormItem>
              </FormGroup>
            </Form>
          </WizardStep>
          <WizardStep icon="map"
            titleText="Address">
            <Form>
              <FormGroup titleText="Address">
                <FormItem label="">
                  <Input placeholder="Address Line 1" ref={addLine1} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <Input placeholder="Address Line 2" ref={addLine2} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <Input placeholder="Locality" ref={locality} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <Input placeholder="Region" ref={region} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <Input placeholder="City" ref={city} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <Input placeholder="Postal Code" ref={postalCode} style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <ComboBox placeholder="Country" ref={country} style={{ width: '50%' }}>
                    {countries && countries.map(row => {
                      return <ComboBoxItem text={row.country} />
                    })}
                  </ComboBox>
                </FormItem>
              </FormGroup>
            </Form>
          </WizardStep>
          <WizardStep icon="building"
            titleText="Assembly Details">
            <Form>
              <FormGroup titleText="Assembly Details">

                <FormItem label="">
                  <ComboBox  ref={localAssembly} placeholder="Local Assembly" onChange={fillHomeCells} style={{ width: '50%' }}>
                    {localAssemblyArr && localAssemblyArr.map(row => {
                      return <ComboBoxItem id={row.assemblyId} text={row.assemblyName} />
                    })}
                    <div slot="valueStateMessage">Assembly cannot be empty</div>
                  </ComboBox>
                </FormItem>
                
                <FormItem label="">
                  <ComboBox ref={homeCell} placeholder="Home Cell" style={{ width: '50%' }}>
                    {homeCellArr && homeCellArr.map(row => {
                      return <ComboBoxItem text={row.homeCellName+' ('+row.homeCellId+')'} />
                    })}
                  </ComboBox>
                </FormItem>
                <FormItem label="">
                  <DatePicker ref={dojCop} placeholder="Date Of Joining COP" style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <DatePicker ref={doD} placeholder="Date Of Dedication" style={{ width: '50%' }} />
                </FormItem>
              </FormGroup>
            </Form>
          </WizardStep>
          <WizardStep icon="positive"
            titleText="Salvation Status (Optional)">
            <Form>
              <FormGroup titleText="Salvation Status">
                <FormItem label="">
                  <FlexBox alignItems="Center">
                    <Text>Accepted Jesus as Lord and personal savior : </Text>
                    <RadioButton ref={ssY} onChange={()=> (ssY.current.checked)?salStatusDet.current.hidden = false: salStatusDet.current.hidden = true} name="E" text="Yes" />
                    <RadioButton ref={ssN} onChange={()=> (ssN.current.checked)?salStatusDet.current.hidden = true: salStatusDet.current.hidden = false} name="E" text="No" />
                  </FlexBox>
                </FormItem>
                </FormGroup>
                </Form>
                <Form ref={salStatusDet}>
                <FormGroup>
                <FormItem label="">
                  <ComboBox ref={ssAssembly} placeholder="Assembly" style={{ width: '30%' }} onChange={(e)=>{$.each(e.currentTarget.items,(k,v)=>{if(v.selected){selSSAssembly=v.id;return;}})}}>
                    {localAssemblyArr && localAssemblyArr.map(row => {
                      return <ComboBoxItem id={row.assemblyId} text={row.assemblyName} />
                    })}
                  </ComboBox>
                  <Button style={{ width: '20%' }} id="SalStatOtherAssembly" onClick={enterOtherAssembly}>Assembly Outside UK</Button>
                </FormItem>
                <FormItem label="">
                  <Input ref={ssMinister} placeholder="Officiating Minister's Name" style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                  <DatePicker ref={ssDos} placeholder="Date Of Salvation" style={{ width: '50%' }} />
                </FormItem>
                <FormItem label="">
                <FlexBox alignItems="Center">
                  <Text>Member is a communicant : </Text>
                  <RadioButton ref={ssCommunicantY} name="EA" text="Yes" />
                  <RadioButton ref={ssCommunicantN} name="EA" text="No" />
                 </FlexBox>
                </FormItem>
              </FormGroup>
            </Form>
          </WizardStep>
          <WizardStep icon="heart"
            titleText="Baptism Status (Optional)">
            <Form>
            <FormItem label="">
                <FlexBox alignItems="Center">
                  <Text>Type Of Baptism : </Text>
                  <CheckBox ref={bsWater} onChange={()=> waterBaptRef.current.hidden = !waterBaptRef.current.hidden} text="Water" />
                  <CheckBox ref={bsHs}  onChange={()=>hsBaptRef.current.hidden = !hsBaptRef.current.hidden} text="Holy Spirit" />
                </FlexBox>
              </FormItem>
              </Form>
              <Form ref={waterBaptRef}>
                <FormGroup titleText="Water Baptism Details">
              <FormItem label="">
                <ComboBox ref={wbsAssembly} placeholder="Assembly" style={{ width: '30%' }}  onChange={(e)=>{$.each(e.currentTarget.items,(k,v)=>{if(v.selected){selWbsAssembly=v.id;return;}})}}>
                  {localAssemblyArr && localAssemblyArr.map(row => {
                    return <ComboBoxItem id={row.assemblyId} text={row.assemblyName} />
                  })}
                </ComboBox>
                <Button style={{width:"20%"}} id="WaterBaptismAssembly" onClick={enterOtherAssembly}>Assembly Outside UK</Button>
              </FormItem>
              <FormItem label="">
                <Input ref={wbsOfficiatingMinister} placeholder="Officiating Minister's Name" style={{ width: '50%' }} />
              </FormItem>
              <FormItem label="">
                <DatePicker ref={wbsDob} placeholder="Date Of Baptism" style={{ width: '50%' }} />
              </FormItem>
              </FormGroup>
              </Form>
              <Form ref={hsBaptRef}>
                <FormGroup titleText="Holy Spirit Baptism Details">
              <FormItem label="">
                <ComboBox ref={hbsAssembly} placeholder="Assembly" style={{ width: '30%' }} onChange={(e)=>{$.each(e.currentTarget.items,(k,v)=>{if(v.selected){selHsAssembly=v.id;return;}})}}>
                  {localAssemblyArr && localAssemblyArr.map(row => {
                      return <ComboBoxItem id={row.assemblyId} text={row.assemblyName} />
                  })}
                </ComboBox>
                <Button style={{width:"20%"}} id="HsBaptismAssembly" onClick={enterOtherAssembly}>Assembly Outside UK</Button>
              </FormItem>
              <FormItem label="">
                <Input ref={hbsOfficiatingMinister} placeholder="Officiating Minister's Name" style={{ width: '50%' }} />
              </FormItem>
              <FormItem label="">
                <DatePicker ref={hbsDob} placeholder="Date Of Baptism" style={{ width: '50%' }} />
              </FormItem>
              </FormGroup>
              </Form>
            
            <Form style={{ alignItems: 'center' }}>
              <FormGroup>
              <FormItem label="">
                <Button design="Emphasized" onClick={createMember} style={{ width: '50%' }}>
                  Add Member
                </Button>
                </FormItem>
                <FormItem label="">
                <Button onClick={clearForm} style={{ width: '50%' }}>
                  Clear
                </Button>
                </FormItem>
              </FormGroup>
            </Form>
          </WizardStep>
        </Wizard>
        <Toast ref={toastRef}>{message}</Toast>
        {ReactDOM.createPortal(
            <Dialog stretch="true" draggable="true" resizable="true" ref={customAssemblyDialog} 
            header={<Bar endContent={<Button design="Negative" onClick={dialogClose}>x</Button>}><Title>Enter Assembly Details</Title></Bar>}
                >
                  {customAssemblyContent}
            <div style={{height:"2%"}}/>
            <Toast ref={toastRef}>{message}</Toast>
            </Dialog>, document.body)}
      </Page>
    </>
  );
}

export default AddMemberLayout;
const titles =
  [
    { title: "Sir" }, { title: "Ma'am" }, { title: "Madam" }, { title: "Mr" }, { title: "Mrs" }, { title: "Ms" }, { title: "Miss" }, { title: "Dr" }, { title: "Professor" }
  ];