import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import ShellBarComponent from "../../components/ShellBarComponent";
import apis from "../../apis/api";
import Cookies from "js-cookie";
import {
  RadialChart,
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  LineSeries,
} from "react-vis";

// Add this import statement at the top of your file
import { HiOutlineUserGroup } from "react-icons/hi";
import { Modals, Bar, Button } from "@ui5/webcomponents-react";
import { motion } from "framer-motion";
import { Card, CardContent, Typography, Grid, Paper, Box } from "@mui/material";
import { BsBuilding, BsShieldLock, FaRegCalendarAlt, MdGroup, BiTable } from "react-icons/all";
import { Page, Panel } from "@ui5/webcomponents-react";
import { LocalActivityOutlined } from "@mui/icons-material";

const HomeDevLayout = () => {
  const authUser = useContext(AuthContext);
  const [count, setCount] = useState();
  const [bibleVerse, setBibleVerse] = useState();
  const [reference, setReferenceText] = useState();
  const showDialog = Modals.useShowDialog();
  const [userDetails, setUserDetails] = useState({
    firstName: " ",
    middleName: " ",
    lastName: " ",
    title: " ",
  });
  let verseLoaded = false;

  const getLoggedOnUserData = () => {
    if(localStorage.getItem("LOGGEDON_USER")){
      setUserDetails(JSON.parse(localStorage.getItem("LOGGEDON_USER")));
    } else {
      apis
        .get("api/MemebershipManager/getMyDetails", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
          },
        })
        .then((res) => {
          if (
            res.data !==
            "Member Id is not assigned or Member role is not assigned. Please contact your administrator."
          ) {
            setUserDetails(res.data);
            localStorage.setItem("LOGGEDON_USER", JSON.stringify(res.data), { expires: 0 });
          } else {
            localStorage.setItem("LOGGEDON_USER", JSON.stringify({
              firstName: " ",
              middleName: " ",
              lastName: " ",
              title: " ",
            }), { expires: 0 });
            const { close } = showDialog({
              headerText: "User not assigned",
              state: "Information",
              children:
                "Your User ID is not assigned to any COP Member Profile or you do not have a member role assigned to your user. Please contact your administrator.",
              footer: (
                <Bar
                  endContent={<Button onClick={() => close()}>Close</Button>}
                />
              ),
            });
          }
        })
        .catch((err) => {});
    }
  };

  const getBibleVerse = () => {
    if (!verseLoaded) {
      verseLoaded = true;
      const url = "https://bible-api.com/";
      const verses = [
        "Psalm 145:9",
        "Isaiah 26:3",
        "1 Chronicles 16:34",
        "Psalm 100:5",
        "James 1:17",
        "2 Samuel 7:28",
        "Psalm 19:7",
        "Psalm 34:8",
        "Nahum 1:7",
        "Psalm 84:11",
        "Isaiah 40:29",
        "Jeremiah 29:11",
        "Psalm 23:4",
        "John 5:24",
        "James 4:7",
      ];
      const randomIndex = Math.floor(Math.random() * verses.length);

      const selectedVerse = verses[randomIndex];

      fetch(`${url}${encodeURI(selectedVerse)}`)
        .then((response) => {
          if (!response.ok) {
          }
          return response.json();
        })
        .then((data) => {
          setBibleVerse(data.text);
          setReferenceText(data.reference);
          verseLoaded = true;
        })
        .catch((error) => {});
    }
  };

  const getCount = async () => {
    var payload = {
      "searchName": null,
      "homeCell": null,
      "age": 3,
      "isChild": true,
      "gender": "sample string 4",
      "nationality": "sample string 5",
      "marritalStatus": "sample string 6",
      "isDisabled": true,
      "block": null,
      "country": null,
      "rcc": null,
      "area": null,
      "dist": null,
      "assembly": null,
      "profilePicture": true
    };
    try {
      apis.post('api/MemebershipManager/readMembers?returnType=C',payload,{
          headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
      }).then(response =>{
          setCount(response.data);
      }).catch(err =>{
          console.log(err);
      });
    } catch (error) {
      // Handle error
    }
  };

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );

    return formattedDate;
  };

  const generateRandomData = () => {
    return Array.from({ length: 5 }, () => Math.floor(Math.random() * 50) + 10);
  };

  const SquareTile = ({ title, data, chartType }) => {
    let chartComponent;

    switch (chartType) {
      case "radial":
        const radialData = data.map((value, index) => ({
          angle: value,
          label: `Segment ${index + 1}`,
        }));
        chartComponent = (
          <RadialChart
            data={radialData}
            width={200}
            height={200}
            showLabels
            labelsStyle={{ fontSize: 8, fontWeight: "bold" }}
            animation
            innerRadius={25}
            radius={80}
          />
        );
        break;

      case "scatter":
        const scatterData = data.map((value, index) => ({
          x: index + 1,
          y: value,
        }));
        chartComponent = (
          <XYPlot width={200} height={200}>
            <XAxis />
            <YAxis />
            <VerticalBarSeries data={scatterData} />
          </XYPlot>
        );
        break;

      case "bar":
        const barData = data.map((value, index) => ({
          x: index + 1,
          y: value,
        }));
        chartComponent = (
          <XYPlot width={200} height={200}>
            <XAxis />
            <YAxis />
            <VerticalBarSeries data={barData} />
          </XYPlot>
        );
        break;

      case "line":
        const lineData = data.map((value, index) => ({
          x: index + 1,
          y: value,
        }));
        chartComponent = (
          <XYPlot width={200} height={200}>
            <XAxis />
            <YAxis />
            <LineSeries data={lineData} />
          </XYPlot>
        );
        break;

      default:
        chartComponent = <div>No valid chart type specified</div>;
    }

    return (
      <div
        style={{
          border: "2px solid #ddd",
          borderRadius:"10%",
          width: "auto",
          height: "auto",
        }}
      >
        <h6>{title}</h6>
        {chartComponent}
      </div>
    );
  };
  const tiles = [
    { title: "Members By Age", data: generateRandomData(), chartType: "radial" },
    { title: "Baptism's this year", data: generateRandomData(), chartType: "scatter" },
    { title: "New members every month", data: generateRandomData(), chartType: "bar" },
    { title: "Events coming up", data: generateRandomData(), chartType: "line" },
  ];
  const navigate = useNavigate();

  const renderCard = (header, subheader, icon, countPer, onClick) => {
    return (
      <Paper
  component={motion.div}
  initial={{ scale: 0.9, opacity: 0 }}
  animate={{ scale: 1, opacity: 1 }}
  transition={{ duration: 0.5 }}
  sx={{
    cursor: 'pointer',
    p: 3,
    borderRadius: 8,
    position: 'relative',
    boxShadow: 2,
    transition: 'box-shadow 0.3s',
    '&:hover': { boxShadow: 5 },
    display: 'flex',
    alignItems: 'center',
    height: '150px', // Adjust the height as needed
  }}
  onClick={onClick}
>
      <div style={{ marginRight: '16px' }}>{/* Space for the count */}</div>
      <div>
        <Typography variant="h6" sx={{ mb: 1 }}>
          {header}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 1 }}>
          {subheader}
        </Typography>
      </div>
      <Typography variant="subtitle1" sx={{ marginLeft: 'auto', fontWeight:'bold', color: 'rgba(0, 0, 0, 0.7)' }}>
      <div style={{color:'green', fontSize:'50px'}}>{countPer}</div> {icon} 
      </Typography>
    </Paper>
    );
  };



  useEffect(() => {
    document.title = "CMS - Launchpad";
    getCount();
    if (!verseLoaded) {
      getBibleVerse();
    }
    getLoggedOnUserData();
  }, []);

  return (
    <React.Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ width: "100%", opacity: 1 }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <ShellBarComponent />

        <Grid container spacing={3} sx={{ padding: 2 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: "center",
                color: "black",
                fontFamily: "Helvetica",
                fontSize: "28px",
                marginTop: "20px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              Greetings, {`${userDetails.title}. ${userDetails.firstName}!`}
              <Typography
                variant="body2"
                sx={{ fontSize: "15px", marginTop: "10px" }}
              >
                Today is {formatDate(new Date())}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {renderCard(
                  "Member Manager",
                  "Manage Member Data",
                  <HiOutlineUserGroup size={30} />,
                  <p>{count}</p>,
                  () => navigate("/memberManager")
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {renderCard(
                  "Administrative Manager",
                  "Manage Essential Metadata",
                  <BiTable size={30} />,
                  '',
                  () => navigate("/metaManager")
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {renderCard(
                  "Group/Ministry Manager",
                  "Create & Manage Groups",
                  <MdGroup size={30} />,
                  '',
                  () => navigate("/groupManager")
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {renderCard(
                  "Auth Manager",
                  "Manage User Authorization",
                  <BsShieldLock size={30} />,
                  '',
                  () => navigate("/authManager")
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {renderCard(
                  "Event Manager",
                  "Create & Manage Events",
                  <FaRegCalendarAlt size={30} />,
                  '',
                  () => navigate("/eventManager")
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
            <Panel headerText="You might also want to see this..." collapsed="true">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                  gap: "10px",
                  padding: "20px",
                }}
              >
                {tiles.map((tile, index) => (
                  <SquareTile
                    key={index}
                    title={tile.title}
                    data={tile.data}
                    chartType={tile.chartType}
                  />
                ))}
              </div>
            </Panel>
          </Grid> */}

          <Grid item xs={12}>
            <Paper
              sx={{
                marginTop: "10px",
                background: "linear-gradient(45deg, #87CEEB, #6A5ACD)",
                padding: "20px",
                borderRadius: "15px",
                boxShadow: "10px 4px 8px rgba(0, 0, 0, 0.2)",
                color: "#ffffff",
                textAlign: "center",
                fontFamily: "Arial, sans-serif",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "black",
                  fontFamily: "Helvetica",
                  fontSize: "28px",
                  marginTop: "20px",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {bibleVerse}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "20px",
                }}
              >
                - {reference}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </motion.div>
    </React.Fragment>
  );
};

export default HomeDevLayout;
