
import React, { useContext, useState, useEffect} from 'react';
import {Route, Navigate } from 'react-router-dom'

import AuthContext from "./AuthProvider";
import Cookies from 'js-cookie';


export default function PrivateRoute({ component: RouteComponent}) {
    const authUser = useContext(AuthContext);
    const isAuth = Cookies.get("COP_USER");
    

    if (isAuth) {
        return <RouteComponent />
    }
    return <Navigate to="/login" /> 
}

