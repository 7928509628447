import React, { useContext, useEffect, useState, useRef } from 'react'
import { Token, ProgressIndicator, Dialog, Title, MessageStrip, ComboBox, ComboBoxItem, DatePicker, RadioButton, ObjectPageSection, FormGroup, Form, Toast, FormItem, Text, DynamicPageHeader, DynamicPageTitle, ObjectPage, Breadcrumbs, ObjectStatus, BreadcrumbsItem, Avatar, Link, Label, Button, Bar, FlexBox, TabContainer, Input } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
function AddEditEducationalDetails(props) {
    const [qualLevels, setQualLevels] = useState([]);
    const degreeName = useRef(), instName = useRef(), complDate = useRef();
    const dialogClose = () => {
        props.dialogClose();
    };
    var levelId = "";
    const addQualification = () => {
        var payload = [];
        payload.push({
            QualificationId: "8729e705-68de-4abc-949d-858fed8517c3",
            LevelOfQualificationId: levelId,
            Name: degreeName.current.value,
            Institution: instName.current.value,
            CompletionDate: complDate.current.value
        });
        console.log(props.memberId);
        apis.post("api/Qualifications?memberId="+props.memberId,payload,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
        }).then(res=>{
            console.log(res);
            props.dialogClose();
        })
        .catch(err=>{
            console.log(err);
        });
    };
    const onLevelChange = (e) => {
        levelId = e.detail.item.id;
    };
    const fillQualLevels = () => {
        apis.get("api/LevelOfQualifications",{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
        }).then(res=>{
            setQualLevels(res.data);
        }).catch(err=>{ 
            console.log(err);
        });
    };
    useEffect(()=>{
        fillQualLevels();
    },[]);
    return(
        <>
        <div style={{height:"5vh"}}/>
        <Form
            columnsL={1}
            columnsXL={1}
            style={{
              alignItems: 'baseline'
            }}
          >
            <FormItem label="Level Of Qualification">
                    <ComboBox onSelectionChange={onLevelChange}  style={{width:"40%"}} placeholder='Qualification Levels'>
                    {qualLevels && qualLevels.map(row => {
                      return <ComboBoxItem id={row.LevelOfQualificationId} text={row.QualificationLevel} />
                    })}
                    </ComboBox>
                </FormItem>
                <FormItem label="Degree Stream/Class">
                    <Input ref={degreeName} style={{width:"40%"}} placeholder='Degree Stream or Class'/>
                </FormItem>
                <FormItem label="Institution Name">
                    <Input ref={instName} style={{width:"40%"}} placeholder='Institution Name'/>
                </FormItem>
                <FormItem label="Completion Date">
                    <DatePicker ref={complDate} style={{width:"40%"}} placeholder='Completion Date'/>
                </FormItem>
                <FormGroup>
                <FormItem label="">
                        <Button style={{width:"20%"}} onClick={addQualification}>
                            Add Qualification
                        </Button>
                        <Button onClick={dialogClose} style={{width:"20%"}}>
                            Cancel
                        </Button>
                </FormItem>
                </FormGroup>
          </Form>
          </>
    )
}
export default AddEditEducationalDetails;