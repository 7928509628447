import { useContext, createContext, useState, useEffect } from "react";

const AuthContext = createContext({
    user:'',
    access_token:'',
    expires_in:'',
    role:''
});

export function useAuth () {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    // setAuth(localStorage);
    // console.log("Current User");
    // console.log(auth.user);

    // const USER = localStorage.getItem('USER');
    // const ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN');
    // const EXPIRES_IN = localStorage.getItem('EXPIRES_IN');
    // console.log(USER);
    // useEffect(() => {
    //     setAuth({USER});
    // }, []);
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;