import React, { useRef, useState, useEffect } from "react";
import { ComboBox, FilterGroupItem, Switch, FilterBar, Input, ComboBoxItem } from '@ui5/webcomponents-react';
import apis from '../apis/api';
import $, { param } from 'jquery';
function MemberFilterLayout(props) {
    const [blocks, setBlocks] = useState([]);
    const [countries, setCountires] = useState([]);
    const [rccs, setRccs] = useState([]);
    const [areas, setAreas] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [assemblies, setAssemblies] = useState([]);
    const searchTop = useRef(), search = useRef(), block = useRef(), country = useRef(), rcc = useRef(), area = useRef(), districtRef = useRef(), assembly = useRef(), loadImage = useRef(),
    gender = useRef(), child = useRef(); 
    const init = async ()=>{
        var response = {};
        if(localStorage.getItem("FILTER_DATA")){
            response["data"] = JSON.parse(localStorage.getItem("FILTER_DATA"));
        } else {
            response = await apis.get("api/filter/getFilterData",{
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                },
              });
        }
        
          if(response.data){
          setBlocks(response.data.blocks);
          if(response.data.blocks && response.data.blocks.length === 1 && block.current && block.current.items.length !== 0){
            block.current.value = block.current.items[0].text;
            block.current.items[0].selected = true;
            block.current.disabled = true;
          }
          setCountires(response.data.countries);
          if(response.data.countries && response.data.countries.length === 1 && country.current  && country.current.items.length !== 0){
            country.current.value = country.current.items[0].text;
            country.current.items[0].selected = true;
            country.current.disabled = true;
          }
          setRccs(response.data.rccs);
          if(response.data.rccs && response.data.rccs.length === 1 && rcc.current && rcc.current.items.length!== 0){
            rcc.current.value = rcc.current.items[0].text;
            rcc.current.items[0].selected = true;
            rcc.current.disabled = true;
          }
          setAreas(response.data.areas );
          if(response.data.areas && response.data.areas.length === 1 && area.current && area.current.items.length!== 0){
            area.current.value = area.current.items[0].text;
            area.current.items[0].selected = true;
            area.current.disabled = true;
          }
          setDistricts(response.data.districts);
          if(response.data.districts && response.data.districts.length === 1 && districtRef.current && districtRef.current.items.length!== 0){
            districtRef.current.value = districtRef.current.items[0].text;
            districtRef.current.items[0].selected = true;
            districtRef.current.disabled = true;
          }
          setAssemblies(response.data.assemblies);
          if(response.data.assemblies && response.data.assemblies.length === 1 && assembly.current && assembly.current.items.length!== 0){
            assembly.current.value = assembly.current.items[0].text;
            assembly.current.items[0].selected = true;
            assembly.current.disabled = true;
          }
        }

    };
    const onGo = () => {
        var searchParams = {
            searchName : search.current.value,
            isChild : (child.current.value === "Child")?true:(child.current.value === "Adult")?false:null,
            gender: gender.current.value,
            block: block.current ? $.grep(block.current.items, (item) => {
                if(item.selected){
                    return item.id;
                }
            }): null,
            country:  country.current?$.grep(country.current.items, (item) => {
                if(item.selected){
                    return item.id;
                }
            }): null,
            rcc:  rcc.current?$.grep(rcc.current.items, (item) => {
                if(item.selected){
                    return item.id;
                }
            }): null,
            area:  area.current ? $.grep(area.current.items, (item) => {
                if(item.selected){
                    return item.id;
                }
            }): null,
            dist:  districtRef.current ? $.grep(districtRef.current.items, (item) => {
                if(item.selected){
                    return item.id;
                }
            }) : null,
            assembly: assembly.current? $.grep(assembly.current.items, (item) => {
                if(item.selected){
                    return item.id;
                }
            }): null,
            profilePicture: loadImage.current.checked
        };
        var processedParams = {};
        var emptyGuid = "00000000-0000-0000-0000-000000000000";
        processedParams.searchName = (searchParams.searchName)?searchParams.searchName:null;
        processedParams.isChild = searchParams.isChild;
        processedParams.gender = (searchParams.gender)?searchParams.gender:null;
        processedParams.profilePicture = searchParams.profilePicture;
        if(searchParams.block && searchParams.block.length === 1){
            processedParams.block = searchParams.block[0].id; 
        } else{
            processedParams.block = emptyGuid;
        }
        if(searchParams.country && searchParams.country.length === 1){
            processedParams.country = searchParams.country[0].id; 
        } else{
            processedParams.country = emptyGuid;
        }
        if(searchParams.rcc && searchParams.rcc.length === 1){
            processedParams.rcc = searchParams.rcc[0].id; 
        } else{
            processedParams.rcc = emptyGuid;
        }
        if(searchParams.area && searchParams.area.length === 1){
            processedParams.area = searchParams.area[0].id; 
        } else{
            processedParams.area = emptyGuid;
        }
        if(searchParams.dist && searchParams.dist.length === 1){
            processedParams.dist = searchParams.dist[0].id; 
        } else{
            processedParams.dist = emptyGuid;
        }
        if(searchParams.assembly && searchParams.assembly.length === 1){
            processedParams.assembly = searchParams.assembly[0].id; 
        } else{
            processedParams.assembly = emptyGuid;
        }
        props.parentCallBack(processedParams);
    };
    useEffect(() => {
        init();
    }, [props]);
    return (<>
    <FilterBar header={"Filter Members ("+props.count+")"} filterBarCollapsed="true" onGo={onGo} onClear={function onClear(){alert("clear")}}  showGoOnFB="true">
                <FilterGroupItem label="Search">
                    <Input ref = {search} placeholder="Search by name" />
                </FilterGroupItem>
                {blocks && <FilterGroupItem label="Block">
                    <ComboBox  ref={block} placeholder="Block">
                        {blocks && blocks.map(row=>{
                            return <ComboBoxItem id={row.blockId} text={row.blockName}/>
                        })}
                    </ComboBox>
                </FilterGroupItem>}
                {countries && <FilterGroupItem label="Country">
                    <ComboBox ref={country} placeholder="Country">
                        {countries && countries.map(row=>{
                            return <ComboBoxItem id={row.countryId} text={row.countryName}/>
                        })}
                    </ComboBox>
                </FilterGroupItem>}
                {rccs && <FilterGroupItem label="RCC">
                    <ComboBox ref={rcc} placeholder="RCC">
                        {rccs && rccs.map(row=>{
                            return <ComboBoxItem id={row.rccId} text={row.rccName}/>
                        })}
                    </ComboBox>
                </FilterGroupItem>}
                {areas && <FilterGroupItem label="Area">
                    <ComboBox ref={area} placeholder="Area">
                        {areas && areas.map(row=>{
                            return <ComboBoxItem id={row.areaId} text={row.areaName}/>
                        })}
                    </ComboBox>
                </FilterGroupItem>}
                {districts && <FilterGroupItem label="District">
                    <ComboBox  ref={districtRef} placeholder="District">
                        {districts && districts.map(row=>{
                            return <ComboBoxItem id={row.districtId} text={row.districtName}/>
                        })}
                    </ComboBox>
                    
                </FilterGroupItem>}
                {assemblies && <FilterGroupItem label="Local Assembly">
                    <ComboBox ref={assembly} placeholder="Local Assembly">
                        {assemblies && assemblies.map(row=>{
                            return <ComboBoxItem id={row.assemblyId} text={row.assemblyName}/>
                        })}
                    </ComboBox>
                </FilterGroupItem>}
                <FilterGroupItem label="Gender">
                    <ComboBox  ref={gender} placeholder="Gender">
                        <ComboBoxItem text="Male" />
                        <ComboBoxItem text="Female" />
                    </ComboBox>
                </FilterGroupItem>
                <FilterGroupItem label="Child/Adult">
                    <ComboBox ref={child} placeholder="Child/Adult">
                        <ComboBoxItem text="Child" />
                        <ComboBoxItem text="Adult" />
                    </ComboBox>
                </FilterGroupItem>
                <FilterGroupItem hidden="true" label="Load With Image">
                    <Switch ref={loadImage} textOff="No" textOn="Yes"/>
                </FilterGroupItem>
            </FilterBar> 
    </>);
}
export default MemberFilterLayout;