import React, { useRef, useState, useEffect } from "react";
import { FilterGroupItem, FilterBar,MessageStrip, ComboBoxItem, Input, ComboBox  } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
import MemberFilterLayout from '../../components/MemberFilterLayout';
import MemberListLayout from '../../components/MemberListLayout';
function ManageUsersLayout(props) {
    const [memberDataResponse, setResponse] = useState([]);
    useEffect(() => {
        loadMemberData();
    });
    const loadMemberData = async (filters) => {
        if(filters){
            var payload = filters;
            await apis.post('api/MemebershipManager/readMembers?returnType=L',payload,{
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                }
            }).then(response =>{
                setResponse(response.data);
            }).catch(err =>{
                console.log(err);
            });
        }
    };
    const onFilterGo = (filterJson) => {
        loadMemberData(filterJson);
    };
    return (
        <>
            <MessageStrip onClose={function noRefCheck(){}}>
                Choose the relevant filters and click on Go. There might be a delay if you do not use any filters.    
            </MessageStrip>
           <MemberFilterLayout parentCallBack = {onFilterGo} count = {memberDataResponse.length}/>
           <MemberListLayout memberArray = {memberDataResponse} source="MemberManager"/>
        </>
    );
}
export default ManageUsersLayout;