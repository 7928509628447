import React, {  useState, useEffect } from "react";
import { Table, TableColumn, Label, TableCell, TableRow, Button  } from '@ui5/webcomponents-react';
import Avatar from '../components/Avatar';
import { useNavigate } from 'react-router-dom';
import "@ui5/webcomponents-icons/dist/feeder-arrow.js"
function MemberListLayout(props) {
    const navigate = useNavigate();
    const [memberData, setMemberData] = useState([]);
    const [orgUnit, setOrgButtonVisible] = useState(false);
    const [memberManager, setMemberManagerVisible] = useState(true);
    useEffect(()=>{
        setMemberData(props.memberArray);
        if(props.source === "OrgUnit"){
            setOrgButtonVisible(true);
            setMemberManagerVisible(false);
        }
        else{
            setOrgButtonVisible(false);
            setMemberManagerVisible(true);
        }
    },[props]);
    const onMemberDetailClick = (e) => {
        navigate('/memberDetail/'+e.target.id);
    };
    const returnMember = (e) => {
        props.returnRef.current.value = e.target.id.split("--")[1];
        props.returnRef.current.id = e.target.id.split("--")[0];
        props.closeDialog();
    };
    return(
    <>
        <Table onRowClick={onMemberDetailClick}
        columns = {
            <>
                <TableColumn popinText="Picture"><Label >Picture</Label></TableColumn>
                <TableColumn popinText="Name"><Label >Name</Label></TableColumn>
                <TableColumn demandPopin="true" popinText="Gender" minWidth={600}><Label >Gender</Label></TableColumn>
                <TableColumn demandPopin="false" popinText="Member Type" minWidth={600}><Label >Child Member</Label></TableColumn>
                <TableColumn demandPopin="false" popinText="Age" minWidth={600}><Label >Age</Label></TableColumn>
                <TableColumn demandPopin="true" popinText="Contact Number" minWidth={600}><Label>Contact Number</Label></TableColumn>
                <TableColumn demandPopin="true" popinText="Contact Number"><Label></Label></TableColumn>
            </>
        }>
            {memberData && memberData.map(row=>{
                            return <>
                            <TableRow type="Active" id={row.memberId}>
                                <TableCell style={{textAlign:'start'}}>
                                <Avatar id={row.memberId}/>
                                </TableCell>
                                <TableCell style={{textAlign:'start'}}>
                                <Label>
                                {row.title} {row.firstName} {row.middleName} {row.lastName}
                                </Label>
                                </TableCell>
                                <TableCell style={{textAlign:'start'}}>
                                <Label>
                                {(row.gender.includes("f")||row.gender.includes("F"))?"Female":"Male"}
                                </Label>
                                </TableCell>
                                <TableCell style={{textAlign:'start'}}>
                                <Label>
                                {(row.childFlag)?"Child":"Adult"}
                                </Label>
                                </TableCell>
                                <TableCell style={{textAlign:'start'}}>
                                <Label>
                                {isNaN(new Date().getFullYear() - new Date(row.dob).getFullYear())?'':new Date().getFullYear() - new Date(row.dob).getFullYear()} 
                                </Label>
                                </TableCell>
                                <TableCell style={{textAlign:'start'}}>
                                <Label>
                                {row.mobileNumber}
                                </Label>
                                </TableCell>
                                <TableCell style={{textAlign:'start'}}>
                                    {memberManager&&<Button disabled="true" icon="feeder-arrow" design="Transparent"/>}
                                    {orgUnit && <Button id={row.memberId+"--"+row.title+" "+row.firstName+" "+row.middleName+" "+row.lastName} onClick={returnMember}>Select Member</Button>}
                                </TableCell>
                            </TableRow>
                            </>
            })}
        </Table>
    </>
    );
};

export default MemberListLayout;