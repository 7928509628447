import React, { useRef, useEffect } from 'react';
import {  TableColumn, Toolbar, Dialog, Title, MessageStrip, Table, TableRow, TableCell,  Toast, Form, FormItem, FormGroup, Input, Bar, Button, Label } from '@ui5/webcomponents-react';
import { useState } from 'react';
import ReactDOM from "react-dom";
import EditMetaGlobalMiscLayout from './EditMetaGlobalMiscLayout';
import apis from '../../apis/api';
function MetaGlobalMiscLayout(props){
    const craeteDiv = useRef();
    const manageDiv = useRef();
    const entityName = useRef();
    const messageDiv = useRef();
    const [messageText, setMessageText] = useState("");
    const editMetaGlobalMiscDialog = useRef();
    const entityDescription = useRef();
    const [entity, setEntity] = useState("");
    const [createAssembly, enableCreateAssemblyType] = useState(false);
    const [tableColumns, setTableColumns] = useState([]);
    const [tableRows, setTableRows] = useState();
    const [entityId, setSelectedEntityId] = useState("");
    const [random, setRandom] = useState();
    const toastRef = useRef();
    const showToast = () => {
        toastRef.current.show();
    };
    const [message, setMessage] = useState("");
    const [url, setUrl] = useState("");
    const onEditItem = (e)=>{
        var recId = e.currentTarget.id;
        setSelectedEntityId(recId);
        setRandom(Math.random());
        switch(props.entity){
            case "Assembly Type":
                setUrl("api/metadata/updateLocalAssemblyType?id=");
                break;
            case "External Assemblies":
                setUrl("api/AdditionalOrgStores/");
                break;
            default:
                break;
        }
        editMetaGlobalMiscDialog.current.show();
    };
    const metaDialogClose = () => {
        editMetaGlobalMiscDialog.current.close();
        craeteDiv.current.hidden = true;
        manageDiv.current.hidden = true;
        if(props.operation === "Create"){
            craeteDiv.current.hidden = false;
            manageDiv.current.hidden = true;
            prepareCreateContent(props.entity, props.operation);
        }
        else{
            craeteDiv.current.hidden = true;
            manageDiv.current.hidden = false;
            prepareViewContent(props.entity, props.operation);
        }

    };
    const onDeleteRecord = async function(url, id, entity){
        try{
          const response = await apis.delete(url+id, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
          });
          prepareViewContent(entity, props.operation);
          setMessage(entity+' Deleted');
          showToast();
        }
        catch(err){
          if (!err?.response) {
            setMessage('No Server Response');
            showToast();
          } else if (err.response?.status === 400) {
            setMessage('Bad Request');
            showToast();
          } else {
            prepareViewContent(entity, props.operation);
            setMessage(entity+' Deleted');
            showToast();
          }
        }
        showToast();
    };
    const onDeleteItem = (e)=>{
        var recId = e.currentTarget.id;
        switch(props.entity){
            case "Assembly Type":
                onDeleteRecord("api/metadata/deleteLocalAssemblyType?id=",recId, props.entity);
                break;
            case "Group Position":
                onDeleteRecord("api/PositionTables/",recId, props.entity);
                break;
            case "Educational Qualification Level":
                onDeleteRecord("api/LevelOfQualifications/",recId, props.entity);
                break;
            case "External Assemblies":
                onDeleteRecord("api/AdditionalOrgStores/",recId, props.entity);
                break;
            default:
                break;
        }
    };
    const postData = async (payload, url, params, operation) => {
        apis.post(url+params,payload,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(response =>{
            if(operation === "Create"){
                setMessage("Creation Successfull");
                showToast();
                handleClearInput();
            }
        }).catch(err =>{
            setMessage("Server Error. Please contact your administrator.");
            showToast();
        })
    };
    const readData = async (entity, url, params) => {
        const response = await apis.get(url+params,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
        });
        var processedData = [];
        if(params==="" && entity==="Assembly Type"){
            response.data.forEach((i)=>{
                processedData.push(
                <TableRow>
                    <TableCell>
                        <Label>{i.assemblyTypeName}</Label>
                    </TableCell>
                    <TableCell>
                        <Label>{i.assemblyTypeDescription}</Label>
                    </TableCell>
                    <TableCell>
                        <Toolbar>
                            <Button onClick={onEditItem} id={i.assemblyTypeId}>Edit</Button>
                            <Button onClick={onDeleteItem} id={i.assemblyTypeId} design="Negative">Delete</Button>
                        </Toolbar>
                    </TableCell>
                </TableRow>
                )
            });
            setTableRows(processedData);
        } else if(params === "" && entity === "Group Position"){
            response.data.forEach((i)=>{
                processedData.push(
                <TableRow>
                    <TableCell>
                        <Label>{i.PositionName}</Label>
                    </TableCell>
                    <TableCell>
                        <Label>{i.Description}</Label>
                    </TableCell>
                    <TableCell>
                        <Toolbar>
                            <Button onClick={onEditItem} id={i.PositionId}>Edit</Button>
                            <Button onClick={onDeleteItem} id={i.PositionId} design="Negative">Delete</Button>
                        </Toolbar>
                    </TableCell>
                </TableRow>
                )
            });
            setTableRows(processedData);
        } else if(params==="" && entity==="Educational Qualification Level"){
            response.data.forEach((i)=>{
                processedData.push(
                <TableRow>
                    <TableCell>
                        <Label>{i.QualificationLevel}</Label>
                    </TableCell>
                    <TableCell>
                        <Toolbar>
                            <Button onClick={onEditItem} id={i.LevelOfQualificationId}>Edit</Button>
                            <Button onClick={onDeleteItem} id={i.LevelOfQualificationId} design="Negative">Delete</Button>
                        </Toolbar>
                    </TableCell>
                </TableRow>
                )
            });
            setTableRows(processedData);
        } else if(params==="" && entity==="External Assemblies"){
            response.data.forEach((i)=>{
                processedData.push(
                <TableRow>
                    <TableCell>
                        <Label>{i.Block}</Label>
                    </TableCell>
                    <TableCell>
                        <Label>{i.Country}</Label>
                    </TableCell>
                    <TableCell>
                        <Label>{i.RCC}</Label>
                    </TableCell>
                    <TableCell>
                        <Label>{i.Area}</Label>
                    </TableCell>
                    <TableCell>
                        <Label>{i.District}</Label>
                    </TableCell>
                    <TableCell>
                        <Label>{i.Assembly}</Label>
                    </TableCell>
                    <TableCell>
                        <Toolbar>
                            <Button onClick={onEditItem} id={i.Id}>Edit</Button>
                            <Button onClick={onDeleteItem} id={i.Id} design="Negative">Delete</Button>
                        </Toolbar>
                    </TableCell>
                </TableRow>
                )
            });
            setTableRows(processedData);
        }
    };
    const prepareViewContent = (entity, operation) => {
        switch(props.entity){
            case "Assembly Type":
                setTableColumns(["Assembly Type", "Description", "Operations"]);
                readData(props.entity, "api/metadata/getAllLocalAssemblyTypes", "");
                break;  
            case "Group Position":
                setTableColumns(["Position Name", "Description", "Operations"]);
                readData(props.entity, "api/PositionTables", "");
                break; 
            case "Educational Qualification Level":
                setTableColumns(["Qualification Level",  "Operations"]);
                readData(props.entity, "api/LevelOfQualifications", "");
                break; 
            case "External Assemblies":
                setTableColumns(["Block", "Country", "RCC", "Area", "District", "Assembly", "Operations"]);
                readData(props.entity,"api/AdditionalOrgStores", "");
                break;
            default:
                break;
        }
    };
    const prepareCreateContent= () => {
        switch(props.entity){
            case "Assembly Type":
                enableCreateAssemblyType(true);
                setEntity(props.entity);
                break;
            case "Group Position":
                enableCreateAssemblyType(true);
                setEntity(props.entity)
                break;
            case "Educational Qualification Level":
                enableCreateAssemblyType(true);
                setEntity(props.entity);
                if(entityDescription.current)
                entityDescription.current.hidden = true;
                break;
            case "External Assemblies":
                craeteDiv.current.hidden = true;
                messageDiv.current.hidden = false;
                setEntity(props.entity);
                setMessageText("Create Operation is not permitted for this entity. You can view, edit or manage existing entities using the View/Manage operation.");
                break;
            default:
                break;
        }
    };
    const handleClearInput = () => {
        entityName.current.value = "";
        entityDescription.current.value = "";
    };
    const handleCreate = () => {  
        var payload = null;
        switch(props.entity){
            case "Assembly Type":
                payload = {
                    "AssemblyTypeId": "d7383607-cc8e-4a8e-b965-776dbc0985aa",
                    "AssemblyTypeName": entityName.current.value,
                    "AssemblyTypeDescription": entityDescription.current.value
                };
                postData(payload, "api/metadata/createLocalAssemblyType", "", "Create");
                break;
            case "Group Position":
                payload ={
                    PositionId: "9d0d9308-9ea7-48f7-ab8d-88984120fefd",
                    PositionName: entityName.current.value,
                    Description: entityDescription.current.value
                };
                postData(payload, "api/PositionTables", "", "Create");
                break;
            case "Educational Qualification Level":
                payload={
                    LevelOfQualificationId: "2039aa1f-6afe-49e9-aec2-b1d33993f398",
                    QualificationLevel: entityName.current.value
                };
                postData(payload, "api/LevelOfQualifications", "", "Create");
            default:
                break;
        }
    };
    useEffect(()=>{

        craeteDiv.current.hidden = true;
        manageDiv.current.hidden = true;
        messageDiv.current.hidden = true;
        if(props.operation === "Create"){
            craeteDiv.current.hidden = false;
            manageDiv.current.hidden = true;
            prepareCreateContent(props.entity, props.operation);
        }
        else{
            craeteDiv.current.hidden = true;
            manageDiv.current.hidden = false;
            prepareViewContent(props.entity, props.operation);
        }
    },[props]);
    return(
        <>
        <Toast duration="3000" ref={toastRef}>{message}</Toast>
        <div ref={messageDiv}>
            <MessageStrip>
                {messageText}
            </MessageStrip>
        </div>
        <div ref={craeteDiv}>
        <Toast duration="3000" ref={toastRef}>{message}</Toast>
        <Form style={{padding: "1%",alignItems: 'center'}}>
            <FormGroup titleText="Basic Details">
                {createAssembly&&<><FormItem label="">
                    <Input ref={entityName} placeholder={entity + " Name"} style={{width: '50%'}} />
                </FormItem>
                <FormItem label="">
                    <Input ref={entityDescription} placeholder={entity + " Description"} style={{width: '50%'}} />
                </FormItem></>}
            </FormGroup>
        </Form>
        <Form style={{padding: "1%",alignItems: 'center'}}>
                <FormItem label="">
                    <Button design="Emphasized" style={{width: '25%'}} onClick={handleCreate}>
                        {"Create "+entity}
                    </Button>
                    <Button style={{width: '25%'}} onClick={handleClearInput}>
                        Clear
                    </Button>
                </FormItem>
            </Form>
        </div>
        <div ref={manageDiv}>
        <Toast duration="3000" ref={toastRef}>{message}</Toast>
            <Table
            columns={
                
                tableColumns && tableColumns.map(
                    row => {
                        return(
                            <TableColumn demandPopin="true" popinText={row} minWidth={600}><Label>{row}</Label></TableColumn>
                        )
                    }
                )
               
            }
            >
                {tableRows}
            </Table>
        </div>
        {ReactDOM.createPortal(
            <Dialog stretch="true" draggable="true" resizable="true" ref={editMetaGlobalMiscDialog} 
            header={<Bar endContent={<Button design="Negative" onClick={metaDialogClose}>x</Button>}><Title>Edit {props.entity}</Title></Bar>}
            >
            <EditMetaGlobalMiscLayout entity = {props.entity} id={entityId} url = {url} random={random} close={metaDialogClose}/>
            <div style={{height:"2%"}}/>
            <Toast ref={toastRef}>{message}</Toast>
            </Dialog>, document.body)}
        </>
    )
};
export default MetaGlobalMiscLayout;