import React, { useRef, useState, useEffect } from "react";
import { Form, FormItem, Toast, Input, FormGroup, Page, Bar, Button } from '@ui5/webcomponents-react';
import { Link, useNavigate } from "react-router-dom";
import "@ui5/webcomponents-icons/dist/nav-back.js";
import apis from '../../../apis/api';
// const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGISTER_URL = '/api/accounts/create';

function RegisterLayout() {
  const errRef = useRef();
  const successRef = useRef();
  const navigate = useNavigate();
  const userRef = useRef();
  const emailRef = useRef();
  const toastRef = useRef();
  const showToast = () => {
    toastRef.current.show();
  };
  const [message, setMessage] = useState("");
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    // userRef.current.focus();
  }, []);
  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);
  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd);
  }, [password, matchPwd]);
  useEffect(() => {
    setErrMsg("");
  }, [password, matchPwd]);
  useEffect(() => {
    setSuccess("");
  }, [user, password]);
  const clearInputs = async(e) =>{
    passwordConfirmRef.current.value='';
    passwordRef.current.value='';
    emailRef.current.value='';
    userRef.current.value='';
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const v2 = EMAIL_REGEX.test(email);
    const v3 = PWD_REGEX.test(password);
    if ( !v2 || !v3) {
      setMessage("Invalid Entry");
      showToast();
        return;
    }
    try {   
        const rolename = "";
        const data = {
            "Email": email,
            "Username": email,
            "RoleName": rolename,
            "Password": password,
            "ConfirmPassword": matchPwd
          }
        const response = await apis.post(REGISTER_URL, data, 
            {headers: { 'Content-Type': 'application/json' }}
        );
        setSuccess(true);
        setUser('');
        setEmail('');
        setPassword('');
        setMatchPwd('');
        setMessage('User Creation Successful!');
        clearInputs();
    } catch (err) {
        if (!err?.response) {
          setMessage('No Server Response');
        } else if (err.response?.status === 400) {
          setMessage('Email or Username not available');
        } else {
          setMessage('Registration Failed')
        }
    }
    showToast();
  };

  return (
    <>
<Page
footer={<Bar design="FloatingFooter" endContent={<><Button onClick={handleSubmit} design="Emphasized">Create</Button><Button onClick={clearInputs} design="Transparent">Clear</Button></>}/>}
style={{height: '500px'}}>
    <Form>
    <FormGroup>
        <FormItem label="Full Name">
          <Input type="Text" ref={userRef} text={user} onInput={(e) => setUser(e.target.value)} />
        </FormItem>
        
        <FormItem label="Email">
          <Input type="Email" ref={emailRef} text={email} onInput={(e) => setEmail(e.target.value)} />
        </FormItem>
        
        <FormItem label="Password">
          <Input type="Password" ref={passwordRef} text={password} onInput={(e) => setPassword(e.target.value)} />
        </FormItem>
        
        <FormItem label="Confirm Password">
          <Input type="Password" ref={passwordConfirmRef} text={matchPwd} onInput={(e) => setMatchPwd(e.target.value)} />
        </FormItem>
      </FormGroup>
    </Form>
    <Toast ref={toastRef}>{message}</Toast>
  </Page>
  </>
  );
}

export default RegisterLayout;
