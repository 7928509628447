import React from 'react'
import ErrorLayout from './ErrorLayout'

function ErrorPage() {
    return (
        <div>
            {/* <TopBar /> */}
            {/* <SideBar /> */}
            <ErrorLayout />
            {/* <Footer /> */}
        </div>
    )
}

export default ErrorPage
