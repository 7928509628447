import React, { useRef, useEffect } from 'react';
import { TableColumn, Toolbar, Dialog, ComboBox, ComboBoxItem, TextArea, Title, MessageStrip, Table, TableRow, TableCell, Toast, Form, FormItem, FormGroup, Input, Bar, Button, Label } from '@ui5/webcomponents-react';
import { useState } from 'react';
import ReactDOM from "react-dom";
import EditEventMetadataLayout from './EditEventMetadataLayout';
import apis from '../../apis/api';
function EventMetadataLayout(props) {
    const craeteDiv = useRef();
    const manageDiv = useRef();
    const entityName = useRef();
    const messageDiv = useRef();
    const [messageText, setMessageText] = useState("");
    const editMetaEventTypeDialog = useRef();
    const entityDescription = useRef();
    const eventParticipationType = useRef();
    const [entity, setEntity] = useState("");
    const [createAssembly, enableCreateAssemblyType] = useState(false);
    const [tableColumns, setTableColumns] = useState([]);
    const [tableRows, setTableRows] = useState();
    const [entityId, setSelectedEntityId] = useState("");
    const [random, setRandom] = useState();
    const toastRef = useRef();
    const showToast = () => {
        toastRef.current.show();
    };
    const [message, setMessage] = useState("");
    const [url, setUrl] = useState("");
    const onEditItem = (e) => {
        var recId = e.currentTarget.id;
        setSelectedEntityId(recId);
        setRandom(Math.random());
        switch (props.entity) {
            case "Attendance Type":
                setUrl("api/AdditionalOrgStores/");
                break;
            default:
                break;
        }
        editMetaEventTypeDialog.current.show();
    };
    const metaDialogClose = () => {
        editMetaEventTypeDialog.current.close();
        craeteDiv.current.hidden = true;
        manageDiv.current.hidden = true;
        if (props.operation === "Create") {
            craeteDiv.current.hidden = false;
            manageDiv.current.hidden = true;
            prepareCreateContent(props.entity, props.operation);
        }
        else {
            craeteDiv.current.hidden = true;
            manageDiv.current.hidden = false;
            prepareViewContent(props.entity, props.operation);
        }

    };
    const onDeleteRecord = async function (url, id, entity) {
        try {
            const response = await apis.delete(url + id, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                }
            });
            prepareViewContent(entity, props.operation);
            setMessage(entity + ' Deleted');
            showToast();
        }
        catch (err) {
            if (!err?.response) {
                setMessage('No Server Response');
                showToast();
            } else if (err.response?.status === 400) {
                setMessage('Bad Request');
                showToast();
            } else {
                prepareViewContent(entity, props.operation);
                setMessage(entity + ' Deleted');
                showToast();
            }
        }
        showToast();
    };
    const onDeleteItem = (e) => {
        var recId = e.currentTarget.id;
        switch (props.entity) {
            case "Group Position":
                onDeleteRecord("api/PositionTables/", recId, props.entity);
                break;
            case "Educational Qualification Level":
                onDeleteRecord("api/LevelOfQualifications/", recId, props.entity);
                break;
            case "External Assemblies":
                onDeleteRecord("api/AdditionalOrgStores/", recId, props.entity);
                break;
            default:
                break;
        }
    };
    const postData = async (payload, url, params, operation) => {
        apis.post(url + params, payload, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
        }).then(response => {
            if (operation === "Create") {
                setMessage("Creation Successfull");
                showToast();
                handleClearInput();
            }
        }).catch(err => {
            setMessage("Server Error. Please contact your administrator.");
            showToast();
        })
    };
    const readData = async (entity, url, params) => {
        const response = await apis.get(url + params, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
        });
        var processedData = [];
        if (params === "" && entity === "Attendance Type") {
            response.data.forEach((i) => {
                processedData.push(
                    <TableRow>
                        <TableCell>
                            <Label>{i.PositionName}</Label>
                        </TableCell>
                        <TableCell>
                            <Label>{i.Description}</Label>
                        </TableCell>
                        <TableCell>
                            <Toolbar>
                                <Button onClick={onEditItem} id={i.PositionId}>Edit</Button>
                                <Button onClick={onDeleteItem} id={i.PositionId} design="Negative">Delete</Button>
                            </Toolbar>
                        </TableCell>
                    </TableRow>
                )
            });
            setTableRows(processedData);
        }
    };
    const prepareViewContent = (entity, operation) => {
        switch (props.entity) {
            case "Attendance Type":
                setTableColumns(["Position Name", "Description", "Operations"]);
                readData(props.entity, "api/PositionTables", "");
                break;
            default:
                break;
        }
    };
    const prepareCreateContent = () => {
        switch (props.entity) {
            case "Attendance Type":
                enableCreateAssemblyType(true);
                setEntity(props.entity)
                break;
            default:
                break;
        }
    };
    const handleClearInput = () => {
        entityName.current.value = "";
        entityDescription.current.value = "";
        eventParticipationType.current.value="";
    };
    const handleCreate = () => {
        var payload = null;
        switch (props.entity) {
            case "Attendance Type":
                payload = {
                    PositionId: "9d0d9308-9ea7-48f7-ab8d-88984120fefd",
                    PositionName: entityName.current.value,
                    Description: entityDescription.current.value
                };
                postData(payload, "api/PositionTables", "", "Create");
                break;
            default:
                break;
        }
    };
    useEffect(() => {

        craeteDiv.current.hidden = true;
        manageDiv.current.hidden = true;
        messageDiv.current.hidden = true;
        if (props.operation === "Create") {
            craeteDiv.current.hidden = false;
            manageDiv.current.hidden = true;
            prepareCreateContent(props.entity, props.operation);
        }
        else {
            craeteDiv.current.hidden = true;
            manageDiv.current.hidden = false;
            prepareViewContent(props.entity, props.operation);
        }
    }, [props]);
    return (
        <>
            <Toast duration="3000" ref={toastRef}>{message}</Toast>
            <div ref={messageDiv}>
                <MessageStrip>
                    {messageText}
                </MessageStrip>
            </div>
            <div ref={craeteDiv}>
                <Toast duration="3000" ref={toastRef}>{message}</Toast>
                <Form style={{ padding: "1%", alignItems: 'center' }}>
                    <FormGroup titleText="Basic Details">
                        {createAssembly && <><FormItem label="">
                            <Input ref={entityName} placeholder={entity + " Name"} style={{ width: '50%' }} />
                        </FormItem>
                            <FormItem label="">
                                <TextArea ref={entityDescription} placeholder={entity + " Description"} style={{ width: '50%' }} />
                            </FormItem>
                            <FormItem label="">
                                <ComboBox style={{ width: '50%' }} placeholder={entity + " Participation Type"} ref={eventParticipationType}>
                                    <ComboBoxItem text="Closed Event"/>
                                    <ComboBoxItem text="Open Event"/>
                                    <ComboBoxItem text="Registrations Only"/>
                                </ComboBox>
                            </FormItem>
                        </>}
                    </FormGroup>
                </Form>
                <Form style={{ padding: "1%", alignItems: 'center' }}>
                    <FormItem label="">
                        <Button design="Emphasized" style={{ width: '25%' }} onClick={handleCreate}>
                            {"Create " + entity}
                        </Button>
                        <Button style={{ width: '25%' }} onClick={handleClearInput}>
                            Clear
                        </Button>
                    </FormItem>
                </Form>
            </div>
            <div ref={manageDiv}>
                <Toast duration="3000" ref={toastRef}>{message}</Toast>
                <Table
                    columns={

                        tableColumns && tableColumns.map(
                            row => {
                                return (
                                    <TableColumn demandPopin="true" popinText={row} minWidth={600}><Label>{row}</Label></TableColumn>
                                )
                            }
                        )

                    }
                >
                    {tableRows}
                </Table>
            </div>
            {ReactDOM.createPortal(
                <Dialog stretch="true" draggable="true" resizable="true" ref={editMetaEventTypeDialog}
                    header={<Bar endContent={<Button design="Negative" onClick={metaDialogClose}>x</Button>}><Title>Edit {props.entity}</Title></Bar>}
                >
                    <EditEventMetadataLayout entity={props.entity} id={entityId} url={url} random={random} close={metaDialogClose} />
                    <div style={{ height: "2%" }} />
                    <Toast ref={toastRef}>{message}</Toast>
                </Dialog>, document.body)}
        </>
    )
};
export default EventMetadataLayout;