import React, { useRef, useState, useEffect } from "react";
import { Form,MessageStrip, CheckBox, Modals, Bar, BusyIndicator, Panel, Button, ComboBoxItem, FormItem, ComboBox, FileUploader  } from '@ui5/webcomponents-react';
import apis from '../../apis/api';
import Papa from "papaparse";
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import MemberUploadTable from "../../components/MemberUploadTable";
import helpTexts from "../../utility/Help";
import {
    faCircleInfo
  } from "@fortawesome/free-solid-svg-icons";
var finaldata = [];
var selLocalAssemblyId = '';
function MassCreateMembersLayout(props) {
    const fileUpload = useRef();
    const preview = useRef();
    const [localAssemblyArr, setLocalAssembly] = useState([]);
    const localAssembly = useRef(), loadingDialog = useRef();
    const showDialog = Modals.useShowDialog();
    const [inputData, setInputData] = useState();
    const [busyState, setBusy] = useState(false);
    useEffect(() => {
       fetchLA();
    },[]);
    const fetchLA = async () => {
    await apis.get("api/metadata/getAllLocalAssemblies", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
      },
    }).then(response=>{
        setLocalAssembly(response.data);
    });
    };
    const onDownloadTemplate = () => {
        window.open("https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/MemberTemplate.csv");
    };
    const onFileUpload =  (e) => {
        var file = e.target.files[0];
        var error=false;
        finaldata = [];
        Papa.parse(file, {
            header:false,
            complete:(res)=>{
                res.data.shift();
                //console.log(res.data);
                res.data.forEach(data=>{
                    delete data[0];
                    delete data[1];
                    delete data[2];
                    delete data[3];
                    delete data[4];
                    if(data[5]!=="Myself"){
                        data[5] = "true";
                    } else{
                        data[5] = "false";
                    }
                    delete data[6];
                    delete data[7];
                    delete data[9];
                    delete data[10];
                    delete data[29];
                    delete data[30];
                    data[31]=data[31]+"//"+data[32]+"//"+data[33]+"//"+data[34];
                    delete data[32];
                    delete data[33];
                    delete data[34];
                    delete data[35];
                    delete data[36];
                    delete data[37];
                    delete data[38];
                    delete data[39];
                    delete data[40];
                    delete data[43];
                    delete data[44];
                    delete data[45];
                    delete data[51];
                    delete data[52];
                    delete data[53];
                    if((data[12]==="") || (data[11]==="") || data[16]===""){
                        error = true;
                    }
                    if(data[11]||data[12]||data[13]){
                    finaldata.push({
                    Status:(error)?"Check Failed":"Passed",
                       child:data[5],
                       title:data[8],
                       lastName:data[11],
                       firstName:data[12],
                       middleName:data[13],
                       dob:data[15],
                       gender:data[16],
                       telephone:data[17],
                       mobile:data[18],
                       email:data[19],
                       add1:data[20],
                       add2:data[21],
                       city:data[22],
                       postalcode:data[23],
                       country:"United Kingdom of Great Britain and Northern Ireland",
                       marritalStatus: data[24],
                       placeOfBirth: data[25],
                       nationality: data[26],
                       birthCountry: data[27],
                       disabled: (data[28]==="Yes")?true:false,
                       emergencyContact:data[31],
                       profession: data[42],
                       salvationStatus:data[46],
                       wbsStatus:data[47],
                       hbsStatus:data[48],
                       AssemblyName:data[49],
                       AssemblyContact:data[50]
                      
                    });
                }
                    error=false;

                });
                
               // console.log(finaldata);

                setInputData(finaldata);
                preview.current.collapsed=false;
            }
        });
    };
    const scrollContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        width: '100%',
    };
    const onCreateMembers = () => {
        // setBusy(true);
       
        if(selLocalAssemblyId===''){
            localAssembly.current.valueState = "Error";
            setBusy(false);
            return;
        }
        var payload = finaldata;
        apis.post("api/massUpload/CreateMembers?assemblyId="+selLocalAssemblyId,payload,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        })
        .then(res=>{
            setBusy(false);
            const{close}=
                showDialog({
                headerText: 'Upload Job Scheduled',
                children:"Upload Job has been scheduled. You can see the results of the upload in the 'Member Upload Results' section",
                footer: <Bar endContent={<Button onClick={()=>close()}>Close</Button>} />
                });
        })
        .catch(err=>{console.log(err);
             setBusy(false);
             const{close}=
                showDialog({
                headerText: 'Server Error!',
                children: "Server error. Please contact your administrator",
                footer: <Bar endContent={<Button onClick={()=>close()}>Close</Button>} />
                });
            });
    };
    const onShowUploadInstructions = () => {
        const{close}=
        showDialog({
            headerText: 'Member Upload Instructions',
            state:"Information",
            children:<div> {ReactHtmlParser(helpTexts.createMembers)} </div>,
            footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
        });
    };
    return (
        <>
            <BusyIndicator active={busyState} />
            <Form style={{padding: "1%",alignItems: 'center'}}>
                <FormItem label="">
                    <Button onClick={onShowUploadInstructions} style={{width:"50%"}} icon="information">Upload Instructions</Button>
                </FormItem>
                <FormItem label="Assembly">
                    <ComboBox onChange={(e)=>{$.each(e.currentTarget.items,(k,v)=>{if(v.selected){selLocalAssemblyId=v.id;e.currentTarget.valueState = "None";return;}})}} ref={localAssembly} placeholder="Local Assembly" style={{ width: '50%' }}>
                    {localAssemblyArr && localAssemblyArr.map(row => {
                      return <ComboBoxItem id={row.assemblyId} text={row.assemblyName} />
                    })}
                    <div slot="valueStateMessage">Assembly cannot be empty</div>
                    </ComboBox>
                </FormItem>
                <FormItem label="CSV Template"><Button onClick={onDownloadTemplate} style={{width:"50%"}}>Download Template</Button></FormItem>
                <FormItem label="Upload File"> 
                    <FileUploader accept=".csv" ref={fileUpload} onChange={onFileUpload} style={{width:"50%"}}>
                    <Button style={{width:"100%"}}>
                        Upload file
                    </Button>
                    </FileUploader>
                </FormItem>
                <FormItem label="Do you want to create user accounts for the members to access their information? (Disclaimer: User accounts are created only for members with emails...)">
                <CheckBox
                    onChange={function _a(){}}
                    text="Create User Accounts"
                />
                </FormItem>
                <FormItem label=""></FormItem>
                <FormItem label="">
                    <Button onClick={onCreateMembers} style={{width:"50%"}}>Create Members</Button>
                </FormItem>
            </Form>
            <Panel collapsed="true" ref={preview} headerText="Preview">
                <MessageStrip design="Warning">Mandatoy Fields: Member Type(child/adult), First Name, Last Name, Gender, Date of Birth</MessageStrip>
                <div style={scrollContainerStyle}>
                    <MemberUploadTable data={inputData}/>
                </div>
            </Panel>
        </>
    );
}
export default MassCreateMembersLayout;