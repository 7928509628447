import React, { useContext, useRef, useEffect } from 'react'
import AuthContext from "../../contexts/AuthProvider";
import {motion} from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import apis from '../../apis/api';
import { Text, Input, Toast, Table, Label, TableRow, TableCell, Toolbar,TableColumn, BusyIndicator, RadioButton, ComboBoxItem, Button, FlexBox, ComboBox, FormItem, FormGroup, Form } from '@ui5/webcomponents-react';
import { useState } from 'react';
import $ from 'jquery'; 
var entityId = "";
function UserSpecificMetadataLayout(props) {
    const authUser = useContext(AuthContext);
    const navigate = useNavigate();
    const input1 = useRef();
    const input2 = useRef();
    const entityCatData = useRef();
    const entityCat = useRef();
    const radioButtonOpt2 = useRef();
    const radioButtonOpt1 = useRef();
    const [Columns, setColumns] = useState([]);
    const[rows, setRows] = useState([]);
    const radioButtonLabel = useRef();
    const [message, setMessage] = useState("");
    const [entityList, setEntityList] = useState([]);
    const [entityListData, setEntityListData] = useState([]);
    const [create, setCreate] = useState(false);
    const [manage, setManage] = useState(false);
    const [entityListDropDown, setEntityListDropDown] = useState(false);
    const [input1Text, setText1] = useState("");
    const [input2Text, setText2] = useState("")
    const [entityBusyInd, setEntityBusyInd] = useState(false);
    const [entity, setEntity] = useState("");
    const [radioButtons, setRadButVis] = useState(false);
    const toastRef = useRef();
    const showToast = () => {
        toastRef.current.show();
    };
    const prepareCreateComponent = (entity) => {
        setCreate(true);
        setManage(false);
        if(entity === "Professional Category"){
            setText1("Professional Category Name");
            setText2("Professional Category Description");
            setRadButVis(false);
            if(entityCat.current)
            entityCat.current.hidden = true;
            if(entityCatData.current)
            entityCatData.current.hidden = true;
            if(radioButtonLabel.current){
                radioButtonLabel.current.hidden = true;
                radioButtonOpt1.current.hidden = true;
                radioButtonOpt2.current.hidden = true;
            }

        } else if(entity === "Disability Types"){
            setText1("Disability Name");
            setText2("Medical Condition");
            setRadButVis(true);
            if(entityCat.current)
            entityCat.current.hidden = true;
            if(entityCatData.current)
            entityCatData.current.hidden = true;
            if(radioButtonLabel.current){
                radioButtonLabel.current.hidden = false;
                radioButtonOpt1.current.hidden = false;
                radioButtonOpt2.current.hidden = false;   
            }
        } else if (entity === "Traditional Position Type (Royal Ministry)"){
            setText1("Position Name");
            setText2("Description");
            setRadButVis(false);
            if(entityCat.current)
            entityCat.current.hidden = true;
            if(entityCatData.current)
            entityCatData.current.hidden = true;
        } else if (entity === "Career Type"){
            setText1("Career Type");
            setText2("");
            setRadButVis(false);
            if(entityCat.current)
            entityCat.current.hidden = true;
            if(entityCatData.current)
            entityCatData.current.hidden = true;
            if(input2.current)
            input2.current.hidden = true;
        } else if (entity === "Business Type"){
            setText1("Business Type");
            setText2("");
            setRadButVis(false);
            if(entityCat.current)
            entityCat.current.hidden = true;
            if(entityCatData.current)
            entityCatData.current.hidden = true;
            if(input2.current)
            input2.current.hidden = true;
        }
    };
    const prepareViewComponent = async (entity) => {
        setCreate(false);
        setManage(true);
        var url = "";
        if(entity === 'Professional Category'){
            setColumns(["Professional Category", "Description", "Entity", "", ""]);
            url = URLS.GET_PROF_CAT;
        } else if(entity === 'Disability Types'){
            setColumns(["Disability Types", "Medical Condition", "Mobility Support", "Entity", "", ""]);
            url = URLS.GET_DISABILITY_TYPE;
        } else if (entity === "Traditional Position Type (Royal Ministry)"){
            setColumns(["Position Name", "Description", "", ""]);
            url = URLS.TRAD_POS_TYPE;
        } else if (entity === "Business Type"){
            setColumns(["Business Type", "", ""]);
            url = URLS.BUS_TYP;
        } else if (entity === "Career Type"){
            setColumns(["Career Type", "", ""]);
            url = URLS.CARR_TYP;
        }
        try{
            const response = await apis.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                }
            });
            var processedData = [];
            if(entity==="Professional Category"){
                response.data.sort((a, b) => a.ProfCatName.localeCompare(b.ProfCatName));
                response.data.forEach((i)=>{
                    processedData.push(
                    <TableRow>
                        <TableCell style={{textAlign:'start'}}>
                            <Label>{i.ProfCatName}</Label>
                        </TableCell>
                        <TableCell style={{textAlign:'start'}}>
                            <Label>{i.ProfCatDesc}</Label>
                        </TableCell>
                        {/* <TableCell>
                            <Label>{i.EntityKey}</Label>
                        </TableCell> */}
                        <TableCell style={{textAlign:'start'}}>
                            <Toolbar>
                                <Button onClick={onEditItem} id={i.ProfCatId}>Edit</Button>
                                <Button onClick={onDeleteItem} id={i.ProfCatId} design="Negative">Delete</Button>
                            </Toolbar>
                        </TableCell>
                    </TableRow>
                    )
                });
                setRows(processedData);
            }
            else if(entity==="Disability Types"){
                response.data.sort((a, b) => a.DisabilityName.localeCompare(b.DisabilityName));
                response.data.forEach((i)=>{
                    processedData.push(
                    <TableRow>
                        <TableCell style={{textAlign:'start'}}>
                            <Label>{i.DisabilityName}</Label>
                        </TableCell>
                        <TableCell style={{textAlign:'start'}}>
                            <Label>{i.MedCondition}</Label>
                        </TableCell>
                        <TableCell style={{textAlign:'start'}}>
                            <Label>{(i.MobilitySupport)?"Yes":"No"}</Label>
                        </TableCell>
                        {/* <TableCell>
                            <Label>{i.EntityKey}</Label>
                        </TableCell> */}
                        <TableCell style={{textAlign:'start'}}>
                            <Toolbar>
                                <Button onClick={onEditItem} id={i.DisabilityId}>Edit</Button>
                                <Button onClick={onDeleteItem} id={i.DisabilityId} design="Negative">Delete</Button>
                            </Toolbar>
                        </TableCell>
                    </TableRow>
                    )
                });
                setRows(processedData);
            } else if (entity === "Traditional Position Type (Royal Ministry)"){
                response.data.sort((a, b) => a.Name.localeCompare(b.Name));
                response.data.forEach((i) => {
                    processedData.push(
                        <TableRow>
                        <TableCell style={{textAlign:'start'}}>
                            <Label>{i.Name}</Label>
                        </TableCell>
                        <TableCell style={{textAlign:'start'}}>
                            <Label>{i.Description}</Label>
                        </TableCell>
                        <TableCell style={{textAlign:'start'}}>
                            <Toolbar>
                                <Button onClick={onEditItem} id={i.TypeOfTraditionalPositionId}>Edit</Button>
                                <Button onClick={onDeleteItem} id={i.TypeOfTraditionalPositionId} design="Negative">Delete</Button>
                            </Toolbar>
                        </TableCell>
                    </TableRow>
                    )
                });
                setRows(processedData);
            } else if (entity === "Career Type"){
                response.data.sort((a, b) => a.Name.localeCompare(b.Name));
                response.data.forEach((i)=>{
                    processedData.push(
                    <TableRow>
                        <TableCell style={{textAlign:'start'}}>
                            <Label>{i.Name}</Label>
                        </TableCell>
                        <TableCell style={{textAlign:'start'}}>
                            <Toolbar>
                                <Button onClick={onEditItem} id={i.CareerTypeId}>Edit</Button>
                                <Button onClick={onDeleteItem} id={i.CareerTypeId} design="Negative">Delete</Button>
                            </Toolbar>
                        </TableCell>
                    </TableRow>);
                });
                setRows(processedData);
            } else if (entity === "Business Type"){
                response.data.sort((a, b) => a.BusinessTypeName.localeCompare(b.BusinessTypeName));
                response.data.forEach((i)=>{
                    processedData.push(
                    <TableRow>
                        <TableCell style={{textAlign:'start'}}>
                            <Label>{i.BusinessTypeName}</Label>
                        </TableCell>
                        <TableCell style={{textAlign:'start'}}>
                            <Toolbar>
                                <Button onClick={onEditItem} id={i.BusinessTypeId}>Edit</Button>
                                <Button onClick={onDeleteItem} id={i.BusinessTypeId} design="Negative">Delete</Button>
                            </Toolbar>
                        </TableCell>
                    </TableRow>);
                });
                setRows(processedData);
            } else if (entity === "Organization Type"){

            }
        }
        catch(err){

        }
    };
    const onDeleteItem = (e)=>{
        var recId = e.currentTarget.id;
        switch(props.entity){
            case "Professional Category":
                onDeleteRecord(URLS.DEL_PROF_CAT,recId, props.entity);
                break;
            case "Disability Types":
                onDeleteRecord(URLS.DEL_DISABILITY_TYPE,recId, props.entity);
                break;
            case "Traditional Position Type (Royal Ministry)":
                onDeleteRecord(URLS.TRAD_POS_TYPE+"/",recId, props.entity);
                break;
            case "Career Type":
                onDeleteRecord(URLS.CARR_TYP+"/", recId, props.entity);
                break;
            case "Business Type":
                onDeleteRecord(URLS.BUS_TYP+"/", recId, props.entity);
                break;
            default:
                break;
        }
    };
    const onDeleteRecord = async function(url, id, entity){
        try{
          const response = await apis.delete(url+id, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
          });
          prepareViewComponent(entity);
          setMessage(entity+' Deleted');
        }
        catch(err){
          if (!err?.response) {
            setMessage('No Server Response');
          } else if (err.response?.status === 400) {
            setMessage('Email or Username not available');
          } else {
            prepareViewComponent(entity);
            setMessage(entity+' Deleted');
          }
        }
        showToast();
    };
    const onEditItem = (e)=>{
        
    };
    const onEntitySelect = (e) => {
        var selectedItem = e.target.value;
        if(selectedItem===''){
            setEntityListDropDown(false);
        } else {
            setEntityListDropDown(true);
            switch(selectedItem){
                case "Block":
                    readEntityContent(selectedItem, URLS.READ_BLOCK);
                    break;
                case  "Country":
                    readEntityContent(selectedItem, URLS.READ_COUNTRY);
                    break;
                case  "RCC":
                    readEntityContent(selectedItem, URLS.READ_RCC);
                    break;
                case  "Area":
                    readEntityContent(selectedItem, URLS.READ_AREA);
                    break;
                case  "District":
                    readEntityContent(selectedItem, URLS.READ_DIST);
                    break;
                case  "Local Assembly":
                    readEntityContent(selectedItem, URLS.READ_LA);
                    break;
                default:
                    break;
            }
            
        }
    };
    const handleCreate = async () => {
        var payload = null;
        var url = null;
        if(props.entity === 'Professional Category'){
            payload = {
                ProfCatId: "8bd37d7b-355b-4cb9-8b28-2d47eaaacd03",
                ProfCatName: input1.current.value,
                ProfCatDesc: input2.current.value
              }
            url = URLS.CREATE_PROF_CAT;
        } else if(props.entity === 'Disability Types'){
            payload = {
                DisabilityId: "3054b038-7b13-46a0-893c-87fa67c7ff47",
                DisabilityName: input1.current.value,
                MedCondition: input2.current.value,
                MobilitySupport: (radioButtonOpt1.current.checked === true)?true:false
              }
              url = URLS.CREATE_DISABILITY_TYPE;
        } else if(props.entity === "Traditional Position Type (Royal Ministry)"){
            payload = {
                TypeOfTraditionalPositionId: "d931a6cc-d9a5-487f-9e90-40092acb8e4c",
                Name: input1.current.value,
                Description: input2.current.value
            }
            url = URLS.TRAD_POS_TYPE;
        } else if(props.entity === "Business Type"){
            payload = {
                BusinessTypeId: "28d92abf-b5fe-4ce1-941b-f2a86e1046f6",
                BusinessTypeName: input1.current.value
              }
              url = URLS.BUS_TYP;
        } else if(props.entity === "Career Type"){
            payload = {
                CareerTypeId: "e903919f-e29d-421a-bdd1-16d22bcb18b2",
                Name: input1.current.value
              };
              url = URLS.CARR_TYP;
        }   
        try{
            const response = await apis.post(url, payload, {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                },
            });
            setMessage(props.entity+" Created Successfully.");
            showToast();
            handleClearInput();
        }
        catch(err){
            setMessage("Server error. Please contact your administrator.");
            showToast();
        }
    };
    const handleClearInput = () => {
        input1.current.value ='';
        input2.current.value ='';
        if(!entityCat.current.hidden){
            entityCat.current.value ='';
            entityCatData.current.value ='';
        }
        if(radioButtonOpt1.current){
            radioButtonOpt2.current.checked = false;
            radioButtonOpt1.current.checked = false;
        }
    };
    const readEntityContent = async (entity, url) => {
        setEntityBusyInd(true);
        try{
            const response = await apis.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                }
            });
            var processedData = [];
            if(entity==="Block"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.blockId, text:index.blockName})
                });
            }
            else if(entity==="Country"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.countryId, text:index.countryName})
                });
            } 
            else if(entity==="RCC"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.rccId, text:index.rccName})
                });
            }
            else if(entity==="Area"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.areaId, text:index.areaName})
                });
            }
            else if(entity==="District"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.districtId, text:index.districtName})
                });
            }
            else if(entity==="Local Assembly"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.assemblyId, text:index.assemblyName})
                });
            }
            setEntityListData(processedData);
            setEntityBusyInd(false);
        }
        catch(err){
            console.log(err);
            setEntityBusyInd(false);
            setEntityListDropDown(false);
        }
    };
    useEffect(() => {
        setEntity(props.entity);
        setEntityList(["Block", "Country", "RCC", "Area", "District", "Local Assembly"]);
        if(props.operation === "Create"){
            prepareCreateComponent(props.entity);
        } else if(props.operation === "Manage / View"){
            prepareViewComponent(props.entity);
        }
    }, [props]);
    return (
        <React.Fragment>
            <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }}>
                {create && 
                    <>
                         <Form style={{padding: "1%",alignItems: 'center'}}>
                            <FormGroup>
                                <FormItem label="">
                                    <Input ref={input1} placeholder={input1Text} style={{width: '50%'}} />
                                </FormItem>
                                <FormItem label="">
                                    <Input ref={input2} placeholder={input2Text} style={{width: '50%'}} />
                                </FormItem>
                                <FormItem label="">
                                    <ComboBox ref={entityCat} onChange={onEntitySelect} placeholder="Select Entity" style={{width: '50%'}}>
                                        {entityList && entityList.map(row=>{
                                            return <ComboBoxItem text={row}/>
                                        })}
                                    </ComboBox>
                                </FormItem>
                                    {entityListDropDown && <FormItem label="">
                                        <ComboBox ref={entityCatData}  placeholder="Select Entity Data" style={{width: '50%'}} onChange={(e)=>{$.each(e.currentTarget.items,(k,v)=>{if(v.selected){entityId=v.id;return;}})}}>
                                            {entityListData && entityListData.map(row=>{
                                                return <ComboBoxItem id={row.id} text={row.text}/>
                                            })}
                                        </ComboBox>
                                        <BusyIndicator active={entityBusyInd}>
                                    </BusyIndicator>
                                    </FormItem>}
                                
                                {radioButtons && <FormItem label="">
                                    <FlexBox alignItems="Center">
                                        <Text ref={radioButtonLabel}>Need Mobility Support : </Text>
                                        <RadioButton ref={radioButtonOpt1} name="A" text="Yes" />
                                        <RadioButton ref={radioButtonOpt2} name="A" text="No" />
                                    </FlexBox>
                                </FormItem>}
                            </FormGroup>
                        </Form>
                        <Form style={{padding: "1%",alignItems: 'center'}}>
                            <FormItem label="">
                                <Button design="Emphasized" style={{width: '25%'}} onClick={handleCreate}>
                                    {"Create "+entity}
                                </Button>
                                <Button style={{width: '25%'}} onClick={handleClearInput}>
                                    Clear
                                </Button>
                            </FormItem>
                        </Form>
                    </>
                }
                {manage &&
                    <>
                        <Table 
                        columns={
                        
                            Columns && Columns.map(
                                row => {
                                    return(
                                        <TableColumn demandPopin="true" popinText={row} minWidth={600}><Label>{row}</Label></TableColumn>
                                    )
                                }
                            )
                        
                        }
                        onLoadMore={function noRefCheck(){}}
                        onPopinChange={function noRefCheck(){}}
                        onSelectionChange={function noRefCheck(){}}
                        >
                            {rows}
                        </Table>
                    </>
                }
                <Toast duration="3000" ref={toastRef}>{message}</Toast>
                
            </motion.div>
        </React.Fragment>
    )
}

export default UserSpecificMetadataLayout
const URLS = {
    READ_BLOCK : "api/metadata/getAllBlocks",
    READ_COUNTRY : "api/metadata/getAllCountries",
    READ_RCC: "api/metadata/getAllRccs",
    READ_AREA: "api/metadata/getAllAreas",
    READ_DIST: "api/metadata/getAllDistricts",
    READ_LA: "api/metadata/getAllLocalAssemblies",
    GET_DISABILITY_TYPE:"api/DisabilityTypes",
    GET_PROF_CAT:"api/MetaProfessionCategories",
    CREATE_DISABILITY_TYPE:"api/DisabilityTypes",
    CREATE_PROF_CAT:"api/MetaProfessionCategories",
    DEL_DISABILITY_TYPE: "api/DisabilityTypes/",
    DEL_PROF_CAT: "api/MetaProfessionCategories/",
    TRAD_POS_TYPE:"api/TypeOfTraditionalPositions",
    CARR_TYP:"api/CareerTypes",
    BUS_TYP:"api/BusinessTypes",
};