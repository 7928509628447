import React from 'react'
import AppSettingsLayout from './AppSettingsLayout';
function AppSettings() {
    return (
        <div>
            <AppSettingsLayout />
        </div>
    )
}
export default AppSettings
