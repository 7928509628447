import React, { useContext, useRef, useEffect } from 'react'
import apis from '../../apis/api';
import languages from '../../assets/languages.json';
import countries from '../../assets/countries.json';
import MemberFilterLayout from "../../components/MemberFilterLayout";
import ReactDOM from "react-dom";
import MemberListLayout from '../../components/MemberListLayout';
import { Input, Panel, ComboBoxItem, Button, ComboBox, FormItem, Toast, Dialog, Bar, Title, MessageStrip, FormGroup, Form, Modals } from '@ui5/webcomponents-react';
import { useState } from 'react';
function EditMetadataLayout(props) {      
    const entityName = useRef();
    const entityDescription = useRef();
    const assignMemberDialog = useRef();
    const entityUser = useRef();
    const showDialog = Modals.useShowDialog();
    const parentUnit = useRef();
    const addressPane = useRef();
    const userRefHidden = useRef();
    const Address1 = useRef();
    const langSpoken = useRef();
    const assemblyType = useRef();
    const Address2 = useRef();
    const AddressId = useRef();
    const Locality = useRef();
    const Region = useRef();
    const Pcode = useRef();
    const City = useRef();
    const Country = useRef();  
    const [assemblySpecificFields, setAssemblySpecificFields] = useState(false);
    const [assemblyTypesArray, setAssemblyTypesArray] = useState([]);
    const[showParent, setParentVisible] = useState(false);
    const [entityRole, setEntityRole] = useState("");
    const [parentEntity, setParentEntity] = useState("");
    const [memberDataResponse, setResponse] = useState([]);
    const [parentEntityData, setParentEntityData] = useState("");
    const [message, setMessage] = useState("");
    const toastRef = useRef();
    const dialogClose = () => {
        assignMemberDialog.current.close();
    };
    const showToast = () => {
        toastRef.current.show();
    };
    const selectMember = (e) => {
        assignMemberDialog.current.show();
    };
    const onFilterGo = (filterJson) => {
        loadMemberData(filterJson);
    };
    const loadMemberData = async (filters) => {
        var payload = filters;
        apis.post('api/MemebershipManager/getFilteredMembers',payload,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(response =>{
            setResponse(response.data);
        }).catch(err =>{
            console.log(err);
        })
    };
    const updateOrgData = () => {
        var payload = null;
        const selectedElement = parentUnit.current.items.find(item => item.selected === true);
        switch(props.entity){
            case "Block":
                payload = {
                    BlockId: props.id,
                    BlockName: entityName.current.value,
                    BlockDescription: entityDescription.current.value,
                    UserId: (entityUser.current.value!=="" && entityUser.current.value!=='Not Assigned')?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                  }
                break;
            case "Country":
                payload = {
                    CountryId: props.id,
                    CountryName: entityName.current.value,
                    CountryDescription: entityDescription.current.value,
                    UserId: (entityUser.current.value!=="" && entityUser.current.value!=='Not Assigned')?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                    BlockId: selectedElement.id
                  }
                break;
            case "RCC":
                payload = {
                    RccId: props.id,
                    RccName: entityName.current.value,
                    RccDescription: entityDescription.current.value,
                    UserId: (entityUser.current.value!=="" && entityUser.current.value!=='Not Assigned')?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                    CountryId: selectedElement.id
                  }
                break;
            case "Area":
                payload = {
                    area: {
                      AreaId: props.id,
                      AreaName: entityName.current.value,
                      Description: entityDescription.current.value,
                      AreaAddress: AddressId.current.value,
                      AddrFId: null,
                      UserId: (entityUser.current.value!=="" && entityUser.current.value!=='Not Assigned')?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                      RccId: selectedElement.id
                    },
                    address: {
                      AddressId: AddressId.current.value,
                      MemberId: "00000000-0000-0000-0000-000000000000",
                      AddressLine1: Address1.current.value,
                      AddressLine2: Address2.current.value,
                      Locality: Locality.current.value,
                      City: City.current.value,
                      Region: Region.current.value,
                      PostalCode: Pcode.current.value,
                      Country: Country.current.value
                    }
                  }
                break;
            case "District":
                payload = {
                    district: {
                      DistrictId: props.id,
                      DistrictName: entityName.current.value,
                      DistrictAddress: AddressId.current.value,
                      Description: entityDescription.current.value,
                      AddrFId: null,
                      UserId: (entityUser.current.value!=="" && entityUser.current.value!=='Not Assigned')?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                      AreaId: selectedElement.id
                    },
                    address: {
                      AddressId: AddressId.current.value,
                      MemberId: "00000000-0000-0000-0000-000000000000",
                      AddressLine1: Address1.current.value,
                      AddressLine2: Address2.current.value,
                      Locality: Locality.current.value,
                      City: City.current.value,
                      Region: Region.current.value,
                      PostalCode: Pcode.current.value,
                      Country: Country.current.value
                    }
                  }
                break;
            case "Local Assembly":
                payload = {
                    assembly: {
                      AssemblyId: props.id,
                      Description: entityDescription.current.value,
                      AssemblyName: entityName.current.value,
                      AssemblyAddress: AddressId.current.value,
                      AddrFId: null,
                      UserId: (entityUser.current.value!=="" && entityUser.current.value!=='Not Assigned')?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                      DistrictId: selectedElement.id,
                      LangSpoken: langSpoken.current.value,
                      AssemblyTypeId: (assemblyType.current.value!=="")?assemblyType.current.value.split(' (')[1].replace(')', ''):"00000000-0000-0000-0000-000000000000",
                    },
                    address: {
                      AddressId: AddressId.current.value,
                      MemberId: "00000000-0000-0000-0000-000000000000",
                      AddressLine1: Address1.current.value,
                      AddressLine2: Address2.current.value,
                      Locality: Locality.current.value,
                      City: City.current.value,
                      Region: Region.current.value,
                      PostalCode: Pcode.current.value,
                      Country: Country.current.value
                    }
                  }
                break;
            default:
                break;
        }
        apis.post(props.url+props.id,payload,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(response =>{
            const{close}=
                showDialog({
                headerText: 'Update Successfull',
                state:"Success",
                children: "The information has been updated successfully",
                footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
                });
            props.close();
        }).catch(err =>{
            console.log(err);
        })

    };
    const handleClearInput = () => {};
    const [entity, setEntity] = useState("");
    const prepareEditComponent = (entity) => {
        setAssemblySpecificFields(false);
        switch(entity){
            case "Block":
                setEntityRole("Coordinator");
                addressPane.current.hidden = true;
                setParentVisible(false);
                fillFormContent(props.id, URLS.READ_SINGLE_BLOCK, props.entity);
                break;
            case "Country":
                setEntityRole("Head");
                addressPane.current.hidden = true;
                setParentEntity("Block");
                setParentVisible(true);
                readParentData(props.parentUrl, "Block");
                fillFormContent(props.id, URLS.READ_SINGLE_COUNTRY, props.entity);
                break;
            case "RCC":
                setEntityRole("Coordinator");
                setParentEntity("Country");
                setParentVisible(true);
                readParentData(props.parentUrl, "Country");
                addressPane.current.hidden = true;
                fillFormContent(props.id, URLS.READ_SINGLE_RCC, props.entity);
                break;
            case "Area":
                setEntityRole("Head");
                setParentEntity("RCC");
                setParentVisible(true);
                readParentData(props.parentUrl, "RCC");
                addressPane.current.hidden = false;
                fillFormContent(props.id, URLS.READ_SINGLE_AREA, props.entity);
                break;
            case "District":
                setEntityRole("Pastor");
                setParentEntity("Area");
                setParentVisible(true);
                readParentData(props.parentUrl, "Area");
                addressPane.current.hidden = false;
                fillFormContent(props.id, URLS.READ_SINGLE_DIST, props.entity);
                break;
            case "Local Assembly":
                setEntityRole("Presiding Elder");
                setParentEntity("District");
                setParentVisible(true);
                readParentData(props.parentUrl, "District");
                addressPane.current.hidden = false;
                setAssemblySpecificFields(true);
                fetchAssemblyTypes();
                fillFormContent(props.id, URLS.READ_SINGLE_LA, props.entity);
                break;
            default:
                break;
        }
    };
    const fetchAssemblyTypes = async () => {
        const response = await apis.get("api/metadata/getAllLocalAssemblyTypes", {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        }).then(response=>{
            setAssemblyTypesArray(response.data);
        }).catch(err=>{
            console.log(err);
        });
    };
    const readParentData = async (parentUrl, entity) => {
        if(parentUrl!==""){
        const response = await apis.get(parentUrl, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        });
        var processedData = [];
        if(entity==="Block"){
            response.data.forEach((index)=>{
                processedData.push({id:index.blockId, text:index.blockName})
            });
        }
        else if(entity==="Country"){
            response.data.forEach((index)=>{
                processedData.push({id:index.countryId, text:index.countryName})
            });
        } 
        else if(entity==="RCC"){
            response.data.forEach((index)=>{
                processedData.push({id:index.rccId, text:index.rccName})
            });
        }
        else if(entity==="Area"){
            response.data.forEach((index)=>{
                processedData.push({id:index.areaId, text:index.areaName})
            });
        }
        else if(entity==="District"){
            response.data.forEach((index)=>{
                processedData.push({id:index.districtId, text:index.districtName})
            });
        }
        else if(entity==="LA"){
            response.data.forEach((index)=>{
                processedData.push({id:index.assemblyId, text:index.assemblyName})
            });
        }
        setParentEntityData(processedData);
    }
    };
    const fillFormContent = async (entityId, url, entity) => {
        if(entityId!=null){
        const response = await apis.get(url+entityId, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        });
        switch(entity){
            case "Block":
                entityName.current.value = response.data.blockName;
                entityDescription.current.value = response.data.blockDescription.split("//")[0];
                entityUser.current.value = response.data.blockDescription.split("//")[1];
                entityUser.current.id = response.data.UserId;
                userRefHidden.current.value = response.data.UserId;
                break;
            case "Country":
                entityName.current.value = response.data.countryName;
                entityDescription.current.value = response.data.countryDescription.split("//")[0];
                entityUser.current.value = response.data.countryDescription.split("//")[1];
                entityUser.current.id = response.data.UserId;
                userRefHidden.current.value = response.data.UserId;
                parentUnit.current.items.forEach((val)=>{
                    if(val.id === response.data.blockId){
                        parentUnit.current.value = val.text;
                        val.selected = true;
                        return;
                    }
                });
                break;
            case "RCC":
                entityName.current.value = response.data.rccName;
                entityDescription.current.value = response.data.rccDescription.split("//")[0];
                entityUser.current.value = response.data.rccDescription.split("//")[1];
                entityUser.current.id = response.data.UserId;
                userRefHidden.current.value = response.data.UserId;
                parentUnit.current.items.forEach((val)=>{
                    if(val.id === response.data.countryId){
                        parentUnit.current.value = val.text;
                        val.selected = true;
                        return;
                    }
                });
                break;
            case "Area":
                entityName.current.value = response.data.areaName;
                entityDescription.current.value = response.data.description.split("//")[0];
                entityUser.current.value = response.data.description.split("//")[1];
                entityUser.current.id = response.data.UserId;
                entityUser.current.id = response.data.UserId;
                userRefHidden.current.value = response.data.UserId;
                parentUnit.current.items.forEach((val)=>{
                    if(val.id === response.data.rccId){
                        parentUnit.current.value = val.text;
                        val.selected = true;
                        return;
                    }
                });
                AddressId.current.value = response.data.areaAddress.addressId;
                Address1.current.value = response.data.areaAddress.addressLine1;
                Address2.current.value = response.data.areaAddress.addressLine2;
                Locality.current.value = response.data.areaAddress.locality;
                Region.current.value = response.data.areaAddress.region;
                City.current.value = response.data.areaAddress.city;
                Pcode.current.value = response.data.areaAddress.postalCode;
                Country.current.value = response.data.areaAddress.country;
                break;
            case "District":
                entityName.current.value = response.data.districtName;
                entityDescription.current.value = response.data.description.split("//")[0];
                entityUser.current.value = response.data.description.split("//")[1];
                entityUser.current.id = response.data.UserId;
                userRefHidden.current.value = response.data.UserId;
                parentUnit.current.items.forEach((val)=>{
                    if(val.id === response.data.areaId){
                        parentUnit.current.value = val.text;
                        val.selected = true;
                        return;
                    }
                });
                AddressId.current.value = response.data.districtAddress.addressId;
                Address1.current.value = response.data.districtAddress.addressLine1;
                Address2.current.value = response.data.districtAddress.addressLine2;
                Locality.current.value = response.data.districtAddress.locality;
                Region.current.value = response.data.districtAddress.region;
                City.current.value = response.data.districtAddress.city;
                Pcode.current.value = response.data.districtAddress.postalCode;
                Country.current.value = response.data.districtAddress.country;
                break;
            case "Local Assembly":
                entityName.current.value = response.data.assemblyName;
                entityDescription.current.value = response.data.description.split("//")[0];
                entityUser.current.value = response.data.description.split("//")[1];
                entityUser.current.id = response.data.userId;
                userRefHidden.current.value = response.data.UserId;
                parentUnit.current.items.forEach((val)=>{
                    if(val.id === response.data.districtId){
                        parentUnit.current.value = val.text;
                        val.selected = true;
                        return;
                    }
                });
                assemblyType.current.items.forEach((val)=>{
                    if(val.text.includes(response.data.assemblyTypeId)){
                        assemblyType.current.value = val.text;
                        return;
                    }
                });
                langSpoken.current.value = response.data.langSpoken;
                AddressId.current.value = response.data.assemblyAddress.addressId;
                Address1.current.value = response.data.assemblyAddress.addressLine1;
                Address2.current.value = response.data.assemblyAddress.addressLine2;
                Locality.current.value = response.data.assemblyAddress.locality;
                Region.current.value = response.data.assemblyAddress.region;
                City.current.value = response.data.assemblyAddress.city;
                Pcode.current.value = response.data.assemblyAddress.postalCode;
                Country.current.value = response.data.assemblyAddress.country;
                break;
            default:
                break;
        }
    }
    };
    useEffect(()=>{
        setParentVisible(false);
        setEntity(props.entity);
        addressPane.current.hidden = true;
        prepareEditComponent(props.entity);
        var aa = props.random;
        
    },[props]);
            return(
                <Panel>
            <Form style={{padding: "1%",alignItems: 'center'}}>
                <FormGroup titleText="Basic Details">
                    <FormItem label={entity + " Name"}>
                        <Input ref={entityName} placeholder={entity + " Name"} style={{width: '50%'}} />
                    </FormItem>
                    <FormItem label={entity + " Description"}>
                        <Input ref={entityDescription} placeholder={entity + " Description"} style={{width: '50%'}} />
                    </FormItem>
                    <FormItem label={entity +" "+entityRole}>
                        <Input ref={entityUser} disabled = "true" style={{width: '35%'}} value={"Select "+entity +" "+entityRole}/>
                        <Button onClick={selectMember} style={{width: '15%'}}>Select Member</Button>
                    </FormItem>
                    {showParent && <FormItem label={"Parent"+ " " +parentEntity}>
                        <ComboBox ref={parentUnit} placeholder={"Parent"+ " " +parentEntity} style={{width: '50%'}}>
                            {parentEntityData && parentEntityData.map(row=>{
                                return <ComboBoxItem id={row.id} text={row.text}/>
                            })}
                        </ComboBox>
                    </FormItem>}
                    {assemblySpecificFields&&<>
                    <FormItem label = "Language Spoken">
                        <ComboBox ref={langSpoken} placeholder="Language Spoken" style={{width: '50%'}}>
                            {languages && languages.map(row => {
                                    return <ComboBoxItem id={row.name} text={row.name}/>
                            })}
                        </ComboBox> 
                    </FormItem>
                    <FormItem label = "Assembly Type">
                        <ComboBox ref={assemblyType} placeholder="Assembly Type" style={{width: '50%'}}>
                            {assemblyTypesArray && assemblyTypesArray.map(row => {
                                    return <ComboBoxItem id={row.assemblyTypeId} text={row.assemblyTypeName+' ('+row.assemblyTypeId+')'}/>
                            })}
                        </ComboBox> 
                    </FormItem></>
                    }
              </FormGroup>
              </Form>
              <Form   ref={addressPane} style={{padding: "1%",alignItems: 'center'}}>
                <FormGroup titleText={entity +" Address"}>
                        <FormItem label="">
                            <Input ref={AddressId} hidden="true"/>
                            <Input ref={Address1} placeholder="Address Line 1" style={{width: '50%'}} />
                        </FormItem>
                        <FormItem label="">
                            <Input ref={Address2} placeholder="Address Line 2" style={{width: '50%'}} />
                        </FormItem>
                        <FormItem label="">
                            <Input ref={Locality} placeholder="Locality" style={{width: '50%'}} />
                        </FormItem>
                        <FormItem label="">
                            <Input ref={Region} placeholder="Region" style={{width: '50%'}} />
                        </FormItem>
                        <FormItem label="">
                            <Input ref={City} placeholder="City" style={{width: '50%'}} />
                        </FormItem>
                        <FormItem label="">
                            <Input ref={Pcode} placeholder="Postal Code" style={{width: '50%'}} />
                        </FormItem>
                        <FormItem label="">
                            {/* <Input ref={Country} placeholder="Country" style={{width: '50%'}} /> */}
                            <ComboBox ref={Country} placeholder="Country" style={{width: '50%'}}>
                                {countries && countries.map(row => {
                                    return <ComboBoxItem text={row.country} />
                                })}
                            </ComboBox>
                        </FormItem>
                        <FormItem>
                            <Input ref={userRefHidden} hidden="true">fdfd</Input>
                        </FormItem>
                </FormGroup>
              </Form>
              <Form style={{padding: "1%",alignItems: 'center'}}>
                <FormItem label="">
                    <Button design="Emphasized" style={{width: '25%'}} onClick={updateOrgData}>
                        {"Update "+entity}
                    </Button>
                    <Button style={{width: '25%'}} dis abled="true" onClick={handleClearInput}>
                        Clear
                    </Button>
                </FormItem>
            </Form>
            {ReactDOM.createPortal(
            <Dialog stretch="true" draggable="true" resizable="true" ref={assignMemberDialog} 
            header={<Bar endContent={<Button design="Negative" onClick={dialogClose}>x</Button>}><Title>Assign Member Id</Title></Bar>}
                >
            <MessageStrip>
                Use the filters to select a member to assign as {entityRole}
            </MessageStrip>
            <MemberFilterLayout parentCallBack = {onFilterGo}/>
            <MemberListLayout closeDialog = {dialogClose} memberArray = {memberDataResponse} source="OrgUnit" returnRef = {entityUser} returnHiddenRef = {userRefHidden}/>
            <div style={{height:"2%"}}/>
            <Toast ref={toastRef}>{message}</Toast>
            </Dialog>, document.body)}
            </Panel>
            )
}
export default EditMetadataLayout;

const URLS = {
    READ_SINGLE_BLOCK : "api/metadata/getBlockById?id=",
    READ_SINGLE_COUNTRY : "api/metadata/getCountriesById?id=",
    READ_SINGLE_RCC : "api/metadata/getRccById?id=",
    READ_SINGLE_AREA : "api/metadata/getAreaById?id=",
    READ_SINGLE_DIST : "api/metadata/getDistrictById?id=",
    READ_SINGLE_LA : "api/metadata/getLocalAssemblyById?id="
};