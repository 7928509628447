import React, {useRef, useState, useEffect} from 'react';
import ShellBarComponent from '../../components/ShellBarComponent';
import "@ui5/webcomponents-icons/dist/group.js";
import { Page, Bar, Card, BusyIndicator, Title, List, StandardListItem, Icon, CardHeader, Form, FormItem, ComboBox, ComboBoxItem, Button, Label, MessageStrip } from '@ui5/webcomponents-react';
import { useNavigate } from "react-router-dom";
import apis from '../../apis/api';
function GroupManagerLayout() {
    const entityCatData = useRef();
    const entityCat = useRef();
    const navigate = useNavigate();
    const [groupList,setGroupList] = useState();
    const [entityNameText,setEntityNameText] = useState("");
    const [entityBusyInd, setEntityBusyInd] = useState(false);
    const [entityListData, setEntityListData] = useState([]);
    const [entityListDropDown, setEntityListDropDown] = useState(false);
    const [entityList, setEntityList] = useState([]);
    const goHome = ()=>{
        navigate('/');
    };
    const onItemClick = (e) => {
        console.log(e.detail.item.id);
        navigate('/groupDetail/'+e.detail.item.id);
    };
    const onEntitySelect = (e) => {
        var selectedItem = e.target.value;
        if(selectedItem===''){
            setEntityListDropDown(false);
        } else {
            setEntityListDropDown(true);
            switch(selectedItem){
                case "Block":
                    readEntityContent(selectedItem, URLS.READ_BLOCK);
                    break;
                case  "Country":
                    readEntityContent(selectedItem, URLS.READ_COUNTRY);
                    break;
                case  "RCC":
                    readEntityContent(selectedItem, URLS.READ_RCC);
                    break;
                case  "Area":
                    readEntityContent(selectedItem, URLS.READ_AREA);
                    break;
                case  "District":
                    readEntityContent(selectedItem, URLS.READ_DIST);
                    break;
                case  "Local Assembly":
                    readEntityContent(selectedItem, URLS.READ_LA);
                    break;
                default:
                    break;
            }
            
        }
    };
    const readEntityContent = async (entity, url) => {
        setEntityBusyInd(true);
        try{
            const response = await apis.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                }
            });
            var processedData = [];
            if(entity==="Block"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.blockId, text:index.blockName})
                });
            }
            else if(entity==="Country"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.countryId, text:index.countryName})
                });
            } 
            else if(entity==="RCC"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.rccId, text:index.rccName})
                });
            }
            else if(entity==="Area"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.areaId, text:index.areaName})
                });
            }
            else if(entity==="District"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.districtId, text:index.districtName})
                });
            }
            else if(entity==="Local Assembly"){
                response.data.forEach((index)=>{
                    processedData.push({id:index.assemblyId, text:index.assemblyName})
                });
            }
            setEntityListData(processedData);
            setEntityBusyInd(false);
        }
        catch(err){
            console.log(err);
            setEntityBusyInd(false);
            setEntityListDropDown(false);
        }
    };
    const fetchRelevantGroups = (e) => {
        var entity = entityCat.current.value;
        var entityKey = entityCatData.current.value.split(' (')[1].replace(')', '');
        var data = entityCatData.current.value.split(" ");
        data.pop();
        setEntityNameText(data.join(" "));
        apis.get("api/memberGrouping/getGroupByEntityId?id="+entityKey,{
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        }).then(res=>{
            setGroupList(res.data);
        })
        .catch(err=>{console.log(err)});
    };
    useEffect(()=>{
        document.title = 'CMS - Event Manager';
        setEntityList(["Block", "Country", "RCC", "Area", "District", "Local Assembly"]);
    },[]);
    return (
        <div>
            <ShellBarComponent/>
            <Page style={{height:"100vh"}} header={<Bar startContent={<Button icon="nav-back" title="Go back" onClick={goHome}/>}><Label>Group Manager</Label></Bar>}>
                <div style={{height:"2vh"}}></div>
                <Form style={{padding: "1%",alignItems: 'center'}}>
                <FormItem label="Administrative Structure">
                    <ComboBox ref={entityCat} onChange={onEntitySelect} placeholder="Select Entity" style={{width: '50%'}}>
                        {entityList && entityList.map(row=>{
                            return <ComboBoxItem text={row}/>
                        })}
                    </ComboBox>
                </FormItem>
                    {entityListDropDown && <><FormItem label={"Select "+entityCat.current.value}>
                        <ComboBox ref={entityCatData}  placeholder="Select Entity Data" onChange={fetchRelevantGroups} style={{width: '50%'}}>
                            {entityListData && entityListData.map(row=>{
                                return <ComboBoxItem text={row.text+' ('+row.id+')'}/>
                            })}
                        </ComboBox>
                        <BusyIndicator active={entityBusyInd}>
                    </BusyIndicator>
                </FormItem>
                {/* <FormItem label="">
                    <Button style={{width:"50%"}} design='Emphasized'>Show Groups</Button>
                </FormItem> */}
                </>}
                </Form>
                <MessageStrip>Select the entity to view managae groups in that entity</MessageStrip>
                <div style={{height:"2vh"}}/>
                <Card 
                header={<CardHeader  avatar={<Icon name="group" />} status="Groups" subtitleText={(entityCat.current)?entityCat.current.value:""} titleText={entityNameText}/>}
                style={{
                    width: '80vw'
                }}
                >
                <List onItemClick={onItemClick}>
                    {groupList && groupList.map(row=>{
                        return <StandardListItem  icon="group" id={row.groupId} description={row.groupDescription}>
                        <Title level="H5">{row.groupName}</Title>
                        </StandardListItem>
                    })}
                    {/* <StandardListItem description="Software Architect">
                    Richard Wilson
                    </StandardListItem>
                    <StandardListItem description="Visual Designer">
                    Elena Petrova
                    </StandardListItem>
                    <StandardListItem description="Quality Specialist">
                    John Miller
                    </StandardListItem> */}
                </List>
                </Card>
            </Page>
        </div>
    )
}
export default GroupManagerLayout
const URLS = {
    READ_BLOCK : "api/metadata/getAllBlocks",
    READ_COUNTRY : "api/metadata/getAllCountries",
    READ_RCC: "api/metadata/getAllRccs",
    READ_AREA: "api/metadata/getAllAreas",
    READ_DIST: "api/metadata/getAllDistricts",
    READ_LA: "api/metadata/getAllLocalAssemblies",
    CREATE_COMM_ZONE:"api/memberGrouping/createCommunityZone",
    GET_PROF_CAT:"api/MetaProfessionCategories",
    CREATE_DISABILITY_TYPE:"api/DisabilityTypes",
    CREATE_PROF_CAT:"api/MetaProfessionCategories",
    DEL_DISABILITY_TYPE: "api/DisabilityTypes/",
    DEL_PROF_CAT: "api/MetaProfessionCategories/",
    READ_COMM_ZONE_BY_ENTITY:"api/memberGrouping/getZoneByEntityId?id=",
    CREATE_HOMECELL:"api/HomeCells",
    CREATE_GROUP:"api/Groups",
    READ_GROUP_BY_ENTITY:"api/memberGrouping/getGroupByEntityId?id=",
    READ_HOME_CELL_BY_ENTITY:"api/memberGrouping/getHomeCellsByEntityId?id=",
    DEL_COMM_ZONE:"api/memberGrouping/deleteCommunityZone?id=",
    DEL_HOME_CELL:"api/HomeCells/",
    DEL_GROUPS:"api/Groups/"
};