//id='5da9601f-b3d3-412c-9e27-c1c254935105'
import React, { useEffect, useRef } from "react";
import {
  CheckBox,
  Modals,
  MessageStrip,
  FlexBox,
  Text,
  Form,
  RadioButton,
  Avatar,
  FormItem,
  Button,
  Bar,
  ComboBox,
  ComboBoxItem,
  Label,
  Input,
  Page,
  FormGroup,
  Wizard,
  MultiInput,
  Token,
  WizardStep,
  Title,
  TextArea,
  TimePicker,
  DatePicker,
  IllustratedMessage,
} from "@ui5/webcomponents-react";
import {
  isEndTimeLessThanStartTime,
  convertAddressToString,
  convertToTimeSpanFormat,
  convertToDateTimeFormat,
} from "../../utility/Commons";
import "@ui5/webcomponents-icons/dist/idea-wall.js";
import "@ui5/webcomponents-icons/dist/gantt-bars.js";
import ReactHtmlParser from "react-html-parser";
import "@ui5/webcomponents-icons/dist/manager-insight.js";
import countries from "../../assets/countries.json";
import "@ui5/webcomponents-icons/dist/form.js";
import "@ui5/webcomponents-icons/dist/map.js";
import "@ui5/webcomponents-icons/dist/calendar.js";
import "@ui5/webcomponents-icons/dist/question-mark.js";
import "@ui5/webcomponents-icons/dist/approvals.js";
import "@ui5/webcomponents-icons/dist/people-connected.js";
import "@ui5/webcomponents-icons/dist/customer-and-contacts.js";
import "@ui5/webcomponents-fiori/dist/illustrations/SuccessHighFive.js";
import { useNavigate } from "react-router-dom";
import { motion, useResetProjection } from "framer-motion";
import $ from "jquery";
import ShellBarComponent from "../../components/ShellBarComponent";
import { useState } from "react";
import apis from "../../apis/api";
import helpTexts from "../../utility/Help";
var selectedEventType = "";
var selectedAssembly = "";
function CreateEvent(props) {
  const [daysVis, setDaysVis] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [address, setAddressVis] = useState(false);
  const [assembly, setAssemblyVis] = useState(false);
  const [wrap, setWrap] = useState("NoWrap");
  const [online, setOnlineVis] = useState(false);
  const [iconUrl, setIconUrl] = useState(
    "https://cdn-icons-png.flaticon.com/512/3908/3908236.png"
  );
  const [eventTypes, setEventTypes] = useState([]);
  const [assemblies, setAssemblies] = useState([]);
  const [AssemblyAddressString, setAssemblyAddress] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [fileData, setFileData] = useState(null);
  const showDialog = Modals.useShowDialog();
  const submitStep1Ref = useRef();
  const navigate = useNavigate();
  const [savedContacts, setSavedContacts] = useState([]);
  const [showSavedDetails, setShowSavedDetails] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [contacts, setContacts] = useState([
    { type: "Phone", description: "", data: "" },
  ]);
  const [rows, setRows] = useState([{ type: "", values: [] }]);
  const clearEventDate = useRef();
  const clearStartTime = useRef();
  const clearEndTime = useRef();
  const eventLinkRef = useRef(),
    eventPlatformRef = useRef(),
    eventTypeRef = useRef(),
    wiz = useRef(),
    assemblyRef = useRef(),
    eventName = useRef(),
    eventDesc = useRef(),
    step1 = useRef(),
    step2 = useRef(),
    step3 = useRef(),
    step4 = useRef(),
    step5 = useRef(),
    step6 = useRef();
  const addContactRow = () => {
    setContacts([...contacts, { type: "Phone", description: "", data: "" }]);
  };
  const deleteContactRow = (index) => {
    const newContacts = contacts.filter((_, i) => i !== index);
    setContacts(newContacts);
  };
  const [isEventPublished, setIsEventPublished] = useState(false);
  const showCustomDialog = (headerText, state, childrenContent) => {
    const { close } = showDialog({
      headerText,
      state,
      children: childrenContent,
      footer: (
        <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
      ),
    });
  };
  const onShowEventAttendeeHelp = () => {
    showCustomDialog(
      "Help",
      "Information",
      <div>{ReactHtmlParser(helpTexts.eventAttendee)}</div>
    );
  };
  const onShowEventTypeHelp = () => {
    showCustomDialog(
      "Help",
      "Information",
      <div>{ReactHtmlParser(helpTexts.eventType)}</div>
    );
  };
  const onShowEventVenueHelp = () => {
    showCustomDialog(
      "Help",
      "Information",
      <div>{ReactHtmlParser(helpTexts.eventVenue)}</div>
    );
  };
  const onShowEventDateTimeHelp = () => {
    showCustomDialog(
      "Help",
      "Information",
      <div>{ReactHtmlParser(helpTexts.eventDateTime)}</div>
    );
  };
  const onShowEventContactHelp = () => {
    showCustomDialog(
      "Help",
      "Information",
      <div>{ReactHtmlParser(helpTexts.eventContact)}</div>
    );
  };
  const onErrorOccurred = () => {
    showCustomDialog(
      "Error",
      "Error",
      "An error occurred while creating the event. Please try again later or contact our support team for assistance."
    );
  };

  const onMandatoryFieldsError = () => {
    showCustomDialog(
      "Incomplete",
      "Error",
      "Please fill all the mandatory fields to continue..."
    );
  };
  const isValidURL = (string) => {
    const regex =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[\w\-._~:\/?#[\]@!$&'()*+,;=]*)?$/;
    return regex.test(string);
  };

  const handleTypeChange = (index, event) => {
    const newRows = [...rows];
    newRows[index].type = event.target.value;
    newRows[index].values = [];
    setRows(newRows);
  };

  const handleValuesChange = (index, event) => {
    const newRows = [...rows];
    const tokens = event.target.tokens;
    const selectedItems = tokens.map((token) => token.text);
    newRows[index].values = selectedItems;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([...rows, { type: "", values: [] }]);
  };

  const deleteRow = (index) => {
    const newRows = rows.filter((row, rowIndex) => rowIndex !== index);
    setRows(newRows);
  };
  const validateEmail = (email) => {
    // Simple email validation regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const handleTokenDelete = (index, event) => {
    const newRows = [...rows];
    const deleteToken = event.detail.token.text;
    newRows[index].values = newRows[index].values.filter(
      (item) => item !== deleteToken
    );
    setRows(newRows);
  };
  const handleKeyPress = (index, event) => {
    if (event.key === "Enter") {
      const value = event.target.value.trim();
      if (validateEmail(value)) {
        const newRows = [...rows];
        newRows[index].values.push(value);
        setRows(newRows);
        event.target.value = "";
      }
    }
  };
  const renderInputControl = (type, index) => {
    if (["Group", "Community Zone", "Home Cell", "Assembly"].includes(type)) {
      return (
        <ComboBox
          onChange={(e) => handleValuesChange(index, e)}
          style={{ marginBottom: "1rem", width: "100%" }}
          placeholder={"Select " + type}
        >
          <ComboBoxItem text="Option 1" />
          <ComboBoxItem text="Option 2" />
          <ComboBoxItem text="Option 3" />
        </ComboBox>
      );
    } else if (type === "Individual") {
      return (
        <MultiInput
          placeholder="Hit enter after typing the email"
          style={{ marginBottom: "1rem", width: "100%" }}
          onKeyPress={(e) => handleKeyPress(index, e)}
          onTokenDelete={(e) => handleTokenDelete(index, e)}
          tokens={rows[index].values.map((value, idx) => (
            <Token key={idx} text={value} />
          ))}
        ></MultiInput>
      );
    }
    return null;
  };

  const [formData, setFormData] = useState({
    Id: "00000000-0000-0000-0000-000000000000",
    Name: "",
    Description: "",
    Type: "",
    Location: "00000000-0000-0000-0000-000000000000", // Update with actual location data
    Recurrence: "00000000-0000-0000-0000-000000000000", // Update with actual recurrence data
    RecurrenceType: "", // Update with actual recurrence type data
    ParentEventId: "00000000-0000-0000-0000-000000000000", // Update with actual parent event data
    Contact: "",
    Poster: "",
    Status: "",
    LastJobCycle: "2023-08-10T05:53:49.0873202+00:00",
    Capacity: "",
    Booked: "",
    WaitListEnabled: true,
    CreationState: 0,
    CreatedBy: "",
    LastChangedBy: "",
  });
  const [locationData, setLocationData] = useState({
    Id: "00000000-0000-0000-0000-000000000000",
    Type: "",
    LocationName: "",
    EventLink: "",
    InternalLocationId: "00000000-0000-0000-0000-000000000000",
    LocationId: "00000000-0000-0000-0000-000000000000",
    LocationAddress: {
      AddressId: "00000000-0000-0000-0000-000000000000",
      MemberId: "00000000-0000-0000-0000-000000000000",
      AddressLine1: "-",
      AddressLine2: "",
      Locality: "",
      City: "",
      Region: "",
      PostalCode: "-",
      Country: "",
    },
    EventId: formData.Id,
  });
  const [recurrenceData, setRecurrenceData] = useState({
    Id: "00000000-0000-0000-0000-000000000000",
    EventId: formData.Id,
    StartTime: "00:00:00.1234567",
    EndTime: "00:00:00.1234567",
    StartDate: "2010-08-13T04:05:07.9760017+00:00",
    EndDate: "2010-08-13T04:05:07.9760017+00:00",
    Type: "S",
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });

  const [contactFields, setContactFields] = useState([
    {
      contactDataType: "",
      contactData: "",
      contactDataDesc: "",
      disabled: false,
      saved: false,
    },
  ]);

  const validateValue = (field, value) => {
    switch (field) {
      case "Type":
        var Types = ["CLOSED", "OPEN", "REG"];
        if (Types.includes(value)) {
          eventTypeRef.current.valueState = "None";
          return true;
        } else {
          eventTypeRef.current.valueState = "Error";
          return false;
        }
        break;
      case "Name":
        if (value !== undefined) {
          if (value.length < 8) {
            eventName.current.valueState = "Error";
            return false;
          }
          for (let char of value) {
            if (!/[a-zA-Z0-9\s]/.test(char)) {
              return false;
            }
          }
          eventName.current.valueState = "None";
          return true;
        }

      case "Description":
        if (value !== undefined && value.length === 0) {
          eventDesc.current.valueState = "Error";
          return false;
        }
        eventDesc.current.valueState = "None";
        return true;
    }
  };
  const handleInputChangeContacts = (index, field, value) => {
    const newContacts = [...contacts];
    newContacts[index][field] = value;
    setContacts(newContacts);
  };
  const handleInputChange = (event) => {
    var { id, value } = event.target;
    // console.log(submitStep1Ref);

    if (id === "Type") {
      $.each(event.currentTarget.items, (k, v) => {
        if (v.selected) {
          value = v.id;
          return;
        }
      });
    }
    if (validateValue(id, value)) {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
      event.target.valueState = "None";
    } else {
      // event.target.valueState='Error';
    }
  };

  const clearForm = () => {
    clearEventDate.current.value = "";
    clearStartTime.current.value = "";
    clearEndTime.current.value = "";
    setRecurrenceData({
      Id: "00000000-0000-0000-0000-000000000000",
      EventId: formData.Id,
      StartTime: "00:00:00.1234567",
      EndTime: "00:00:00.1234567",
      StartDate: "2023-08-13T04:05:07.9760017+00:00",
      EndDate: "2023-08-13T04:05:07.9760017+00:00",
      Type: "",
      Sunday: false,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    });
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setUploadedFileName(file.name);
      // Perform other actions with the file if necessary
    }
  };

  const handleLocationDataChange = (event) => {
    var { id, value } = event.target;
    switch (locationData.Type) {
      case "ON":
        break;
      case "EX":
        break;
      default:
    }
    if (locationData.Type === "ON") {
      if (id === "LocationName") {
        switch (value) {
          case "Zoom":
            setIconUrl(
              "https://img.freepik.com/premium-vector/zoom-icon-popular-messenger-app-communications-platform_277909-457.jpg?w=360"
            );
            break;
          case "Microsoft Teams":
            setIconUrl(
              "https://i.pinimg.com/originals/ef/cd/49/efcd498fc9a5ba1e1bf7436b838d4e7d.jpg"
            );
            break;
          case "Google Meet":
            setIconUrl(
              "https://w7.pngwing.com/pngs/216/724/png-transparent-google-meet-social-network-meeting-communication-icon.png"
            );
            break;
        }
      }
    }
    if (id === "LocationName" || id === "EventLink") {
      setLocationData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    } else {
      locationData.LocationAddress[id] = value;
    }
  };
  const goBack = () => {
    navigate("/eventManager");
  };
  const recurringCheckBoxChanged = (e) => {
    if (e.target.checked) {
      recurrenceData.Type = "R";
      setDaysVis(true);
    } else {
      recurrenceData.Type = "S";
      recurrenceData.Monday = false;
      recurrenceData.Tuesday = false;
      recurrenceData.Wednesday = false;
      recurrenceData.Thursday = false;
      recurrenceData.Friday = false;
      recurrenceData.Saturday = false;
      recurrenceData.Sunday = false;
      setDaysVis(false);
    }
  };
  const recurrenceDaysChecked = (event) => {
    switch (event.target.id) {
      case "1":
        recurrenceData.Sunday = event.target.checked;
        break;
      case "2":
        recurrenceData.Monday = event.target.checked;
        break;
      case "3":
        recurrenceData.Tuesday = event.target.checked;
        break;
      case "4":
        recurrenceData.Wednesday = event.target.checked;
        break;
      case "5":
        recurrenceData.Thursday = event.target.checked;
        break;
      case "6":
        recurrenceData.Friday = event.target.checked;
        break;
      case "7":
        recurrenceData.Saturday = event.target.checked;
        break;
    }
  };
  const recurrenceTimePeriodChange = (event) => {
    var { id, value } = event.target;
    if (id === "Date") {
      recurrenceData.StartDate = convertToDateTimeFormat(value);
      recurrenceData.EndDate = convertToDateTimeFormat(value);
    } else if (id === "StartTime" || id === "EndTime") {
      recurrenceData[id] = convertToTimeSpanFormat(value);
    } else {
      setRecurrenceData((prevData) => ({
        ...prevData,
        [id]: convertToDateTimeFormat(value),
      }));
    }
  };
  const submitStep1 = async () => {
    if (
      !validateValue("Name", formData["Name"]) ||
      !validateValue("Description", formData["Description"]) ||
      !validateValue("Type", formData["Type"])
    ) {
      onMandatoryFieldsError();
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      ["CreationState"]: 1,
    }));

    try {
      //for testing
    //   setCurrentStep(2);
    //   step2.current.selected = true;
    //   return;
      /////////////

      const response = await apis.post("api/Events", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      });

      setCurrentStep(2);
      step2.current.selected = true;
      setFormData(response.data);
    } catch (error) {
      onErrorOccurred();
      return;
    }
  };
  const submitStep2 = async () => {
    locationData.EventId = formData.Id;
    if (locationData.Type === "") {
      onMandatoryFieldsError();
      return;
    }
    if (!validateLocationFields(locationData, locationData.Type)) {
      return;
    }
    // setCurrentStep(3);
    // step3.current.selected = true;
    // return;
    try {
      const response = await apis.post("api/Locations", locationData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      });

    
      setCurrentStep(3);
     step3.current.selected = true;
      setLocationData((prevData) => ({
        ...prevData,
        ["Id"]: response.data.Id, // Ensure the response has the data structure you expect
      }));

      setFormData((prevData) => ({
        ...prevData,
        ["Location"]: response.data.Id,
      }));
    } catch (error) {
      // console.log(error);
    }
  };

  const submitStep3 = async () => {
    recurrenceData.EventId = formData.Id;
    if (recurrenceData.Type === "") {
      onMandatoryFieldsError();
      return;
    }
    if (!validateRecurrenceFields(recurrenceData)) {
      return;
    }
    // setCurrentStep(4);
    // step4.current.selected = true;
    // return;
    const response = await apis
      .post("api/Recurrences", recurrenceData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      })
      .then((response) => {
        setRecurrenceData(response.data);
        formData.Recurrence = response.data.Id;
        setCurrentStep(4);
        step4.current.selected = true;
      })
      .catch((error) => {
      });
  };
  const submitStep4 = () => {
    const processedContacts = contacts
      .filter((contact) => contact.data.trim() !== "")
      .map((contact) => ({
        contactData: contact.data,
        contactDataType: contact.type,
        contactDataDesc: contact.description,
      }));
    if (processedContacts.length === 0) {
      showCustomDialog(
        "Error",
        "Error",
        "Please enter at least one valid contact information. This will help attendees reach out to you if they need any assistance."
      );
      return;
    }

    console.log(processedContacts);
    setCurrentStep(5);
    step5.current.selected = true;
    // You can then send `processedContacts` to your backend or handle it as needed.
  };
  const submitStep5 = () => {
    setCurrentStep(6);
    step6.current.selected = true;
  };
  const skipStep5 = () => {
    setCurrentStep(6);
    step6.current.selected = true;
  };
  const handleDeleteField = (event) => {
    var index = parseInt(event.currentTarget.id.split("--")[1]);
    apis
      .delete(
        "api/EventContacts?id=" +
          contactFields[index].contactData +
          "&eventId=" +
          formData.Id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
          },
        }
      )
      .then((response) => {
        const updatedFields = contactFields.filter((_, i) => i !== index);
        setContactFields(updatedFields);
      });
  };
  const internalAssemblySelect = (e) => {
    if (e.target.checked) {
      setAddressVis(false);
      setAssemblyVis(true);
      setOnlineVis(false);
      setAssemblyAddress("");
    }
  };
  const externalAssemblySelect = (e) => {
    if (e.target.checked) {
      setAddressVis(true);
      setAssemblyVis(false);
      setOnlineVis(false);
      locationData.Type = "EX";
    }
  };

  const onlineEventSelect = (e) => {
    if (e.target.checked) {
      setAddressVis(false);
      setAssemblyVis(false);
      setOnlineVis(true);
      locationData.Type = "ON";
    }
  };

  const loadAssemblies = async (event) => {
    const response = await apis.get("api/filter/getFilterData", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
      },
    });
    var assemblies = [];
    switch (event.target.value) {
      case "Local Assembly":
        response.data.assemblies.forEach((assembly) => {
          assemblies.push({
            Id: assembly.assemblyId,
            AddressId: assembly.assemblyAddress,
            Name: assembly.assemblyName,
          });
        });
        locationData.Type = "LA";
        break;
      case "District Assembly":
        response.data.districts.forEach((assembly) => {
          assemblies.push({
            Id: assembly.districtId,
            AddressId: assembly.districtAddress,
            Name: assembly.districtName,
          });
        });
        locationData.Type = "DI";
        break;
      case "Area Assembly":
        response.data.areas.forEach((assembly) => {
          assemblies.push({
            Id: assembly.areaId,
            AddressId: assembly.areaAddress,
            Name: assembly.areaName,
          });
        });
        locationData.Type = "AR";
        break;
    }
    setAssemblies(assemblies);
  };

  const goBackOne = () => {
    step1.current.disabled = false;
    step2.current.disabled = false;
    step1.current.selected = true;
    step2.current.selected = false;
  };

  const goBackTwo = () => {
    step2.current.disabled = false;
    step3.current.disabled = false;
    step2.current.selected = true;
    step3.current.selected = false;
  };

  const goBackThree = () => {
    step3.current.disabled = false;
    step4.current.disabled = false;
    step3.current.selected = true;
    step4.current.selected = false;
  };

  const validateLocationFields = (data, type) => {
    switch (type) {
      case "ON":
        if (locationData.LocationName === "") {
          eventPlatformRef.current.valueState = "Error";
          return false;
        } else {
          eventPlatformRef.current.valueState = "None";
        }
        if (
          locationData.EventLink === "" ||
          !isValidURL(locationData.EventLink)
        ) {
          eventLinkRef.current.valueState = "Error";
          return false;
        } else {
          eventLinkRef.current.valueState = "None";
        }
        return true;
      case "EX":
        if (
          locationData.LocationAddress.AddressLine1 === "-" ||
          locationData.LocationAddress.PostalCode === "-"
        ) {
          onMandatoryFieldsError();
          return false;
        }
        return true;
      default:
        if (locationData.InternalLocationId === "") {
          onMandatoryFieldsError();
          return false;
        }
        return true;
    }
  };
  const validateRecurrenceFields = (recurrenceData) => {
    if (
      recurrenceData.StartTime === "00:00:00.1234567" ||
      recurrenceData.EndTime === "00:00:00.1234567" ||
      recurrenceData.StartDate === "2010-08-13T04:05:07.9760017+00:00" ||
      recurrenceData.EndDate === "2010-08-13T04:05:07.9760017+00:00"
    ) {
      onMandatoryFieldsError();
      return false;
    }
    if (recurrenceData.Type === "R") {
      if (
        !recurrenceData.Sunday &&
        !recurrenceData.Monday &&
        !recurrenceData.Tuesday &&
        !recurrenceData.Wednesday &&
        !recurrenceData.Thursday &&
        !recurrenceData.Friday &&
        !recurrenceData.Saturday
      ) {
        onMandatoryFieldsError();
        return false;
      }
    }
    if (
      isEndTimeLessThanStartTime(
        recurrenceData.StartTime,
        recurrenceData.EndTime
      )
    ) {
      showCustomDialog(
        "Error",
        "Error",
        "The end time cannot be earlier than the start time. Please enter a valid start time and end time."
      );
      return false;
    }
    return true;
  };
  const loadAssemblyAddress = (id) => {
    apis
      .get("api/Addresses?Id=" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      })
      .then((response) => {
        setAssemblyAddress(convertAddressToString(response.data));
      })
      .catch((error) => {
        setAssemblyAddress("Could not fetch the assembly address.");
      });
  };
  const UploadPoster = (selectedImageData) => {
    var type = selectedImageData.split(";")[0].split("/");
    var dummyId = "932c9d53-1335-4eca-a051-066908f5df71";
    var uploadUrl =
      "api/dataManager/uploadEventPoster?type=." + type + "&eventId=" + dummyId; //formData.Id;
    const formData = new FormData();
    formData.append("Files", fileData);
    apis
      .post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      })
      .then((response) => {
        // console.log(response);
        // setUrl("https://copukcmsblobstorage.blob.core.windows.net/copcmsblobstore/"+props.memberId+".jpg?t="+new Date().getTime());
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handlePosterUpload = (event) => {
    const file = event.currentTarget.files[0];
    setFileData(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowPreview(true);
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const onPublishEvent = async () => {
    const eventData = {
      ...formData,
      locationData,
      recurrenceData,
      contactFields,
      // contactData: contactFields.filter(field => field.saved),
    };

    try {
      const response = await apis.post("api/Events", eventData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("ACCESS_TOKEN"),
        },
      });
      // console.log("Event created successfully", response.data);
      setIsEventPublished(true);
    } catch (error) {
      // console.error("Error creating event", error);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setWrap("Wrap");
      } else {
        setWrap("NoWrap");
      }
    };

    handleResize(); // Set the initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [props]);
  return (
    <React.Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ width: "100%", opacity: 1 }}
        exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
      >
        <ShellBarComponent />
        <Page
          header={
            <Bar
              startContent={
                <Button icon="nav-back" title="Go back" onClick={goBack} />
              }
            >
              <Label>Create Event</Label>
            </Bar>
          }
          style={{ height: "90vh" }}
        >
          <MessageStrip style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            No worries if the data isn't correct! You can always edit the
            details in the Manage Events app before publishing the event.
          </MessageStrip>
          <Wizard
            contentLayout="SingleStep"
            onStepChange={function ka() {}}
            ref={wiz}
            style={{
              height: "100%",
            }}
          >
            <WizardStep
              ref={step1}
              subtitleText="Basic Information"
              icon="form"
              selected="true"
              disabled={currentStep !== 1}
              titleText="Event Details"
            >
              <Button
                icon="question-mark"
                onClick={onShowEventTypeHelp}
                tooltip="What is this?"
                style={{ position: "absolute", top: "25px", right: "40px" }}
              />
              <Title level="H4">Event Details</Title>
              <Form>
                <FormGroup>
                  <FormItem label={<Label required>Event Name</Label>}>
                    <Input
                      value={formData.Name}
                      placeholder="Event Name"
                      id="Name"
                      ref={eventName}
                      onChange={handleInputChange}
                      style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                    >
                      <div slot="valueStateMessage">
                        Must be at least 8 characters long and can only contain
                        alphabets[ABC/abc...,] space or numbers[1,2,3...]
                      </div>
                    </Input>
                  </FormItem>
                  <FormItem
                    label={
                      <Label
                        required
                        style={{ alignSelf: "start", paddingTop: "0.25rem" }}
                      >
                        Event Description
                      </Label>
                    }
                    style={{
                      alignSelf: "start",
                    }}
                  >
                    <TextArea
                      ref={eventDesc}
                      id="Description"
                      placeholder="Enter a short description about the event"
                      rows={5}
                      style={{
                        "--_ui5_textarea_margin": 0,
                        width: wrap === "NoWrap" ? "50%" : "100%",
                      }}
                      value={formData.Description}
                      onChange={handleInputChange}
                    >
                      <div slot="valueStateMessage">
                        You need to enter a description...
                      </div>
                    </TextArea>
                  </FormItem>
                  <FormItem label={<Label required>Event Type</Label>}>
                    <ComboBox
                      id="Type"
                      placeholder="Open / Closed / Registrations Only"
                      style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                      ref={eventTypeRef}
                      onChange={handleInputChange}
                    >
                      <ComboBoxItem id="CLOSED" text="Closed" />
                      <ComboBoxItem id="OPEN" text="Open" />
                      <ComboBoxItem id="REG" text="Registration Required" />
                      <div slot="valueStateMessage"> Invalid Event Type </div>
                    </ComboBox>
                  </FormItem>
                </FormGroup>
              </Form>
              <FlexBox
                alignItems="Center"
                direction="Row"
                justifyContent="Center"
                style={{ padding: "1%" }}
                fitContainer="true"
                wrap="NoWrap"
              >
                <Button
                  ref={submitStep1Ref}
                  onClick={submitStep1}
                  design="Emphasized"
                  style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                >
                  {"Save & Continue"}
                </Button>
              </FlexBox>
            </WizardStep>
            <WizardStep
              ref={step2}
              disabled={currentStep !== 2}
              subtitleText="Church or Other addresses"
              icon="map"
              titleText="Venue"
            >
              <Form>
                <FormGroup titleText="Event Venue">
                  <FormItem label={<Label required>Venue</Label>}>
                    <FlexBox
                      alignItems="Start"
                      direction="Row"
                      justifyContent="Start"
                      fitContainer="false"
                      wrap={wrap}
                    >
                      <RadioButton
                        style={{ width: wrap === "NoWrap" ? "25%" : "100%" }}
                        onChange={internalAssemblySelect}
                        text="Assembly"
                        name="location"
                      />
                      <RadioButton
                        style={{ width: wrap === "NoWrap" ? "25%" : "100%" }}
                        onChange={externalAssemblySelect}
                        text="Other Address"
                        name="location"
                      />
                      <RadioButton
                        style={{ width: wrap === "NoWrap" ? "25%" : "100%" }}
                        onChange={onlineEventSelect}
                        text="Online"
                        name="location"
                      />
                    </FlexBox>
                    <Button
                      icon="question-mark"
                      onClick={onShowEventVenueHelp}
                      tooltip="What is this?"
                    />
                  </FormItem>
                  {assembly && (
                    <>
                      <FormItem label={<Label required>Assembly Type</Label>}>
                        <ComboBox
                          placeholder="Select Assembly Type"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                          onChange={loadAssemblies}
                        >
                          <ComboBoxItem id="LA" text="Local Assembly" />
                          <ComboBoxItem id="DI" text="District Assembly" />
                          <ComboBoxItem id="AR" text="Area Assembly" />
                        </ComboBox>
                      </FormItem>
                      <FormItem label={<Label required>Assembly</Label>}>
                        <ComboBox
                          placeholder="Select Assembly"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                          ref={assemblyRef}
                          onChange={(e) => {
                            $.each(e.currentTarget.items, (k, v) => {
                              if (v.selected) {
                                locationData.InternalLocationId =
                                  v.id.split("---")[0];
                                locationData.LocationName = v.text;
                                locationData.LocationId = v.id.split("---")[1];
                                loadAssemblyAddress(v.id.split("---")[1]);
                                return;
                              }
                            });
                          }}
                        >
                          {assemblies &&
                            assemblies.map((row) => {
                              return (
                                <ComboBoxItem
                                  id={row.Id + "---" + row.AddressId}
                                  text={row.Name}
                                />
                              );
                            })}
                        </ComboBox>
                      </FormItem>
                      <FormItem label="">
                        <Text wrapping="true">{AssemblyAddressString}</Text>
                      </FormItem>
                    </>
                  )}
                  {address && (
                    <>
                      <FormItem label={<Label>Event Address</Label>}>
                        <Input
                          id="LocationName"
                          onChange={handleLocationDataChange}
                          placeholder="Church / Building Name"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        />
                      </FormItem>
                      <FormItem label={<Label required></Label>}>
                        <Input
                          id="AddressLine1"
                          onChange={handleLocationDataChange}
                          placeholder="Address Line 1"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        />
                      </FormItem>
                      <FormItem label="">
                        <Input
                          id="AddressLine2"
                          onChange={handleLocationDataChange}
                          placeholder="Address Line 2"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        />
                      </FormItem>
                      <FormItem label="">
                        <Input
                          id="Locality"
                          onChange={handleLocationDataChange}
                          placeholder="Locality"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        />
                      </FormItem>
                      <FormItem label="">
                        <Input
                          id="Region"
                          onChange={handleLocationDataChange}
                          placeholder="Region"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        />
                      </FormItem>
                      <FormItem label="">
                        <Input
                          id="City"
                          onChange={handleLocationDataChange}
                          placeholder="City"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        />
                      </FormItem>
                      <FormItem label={<Label required></Label>}>
                        <Input
                          id="PostalCode"
                          onChange={handleLocationDataChange}
                          placeholder="Postal Code"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        />
                      </FormItem>
                      <FormItem label="">
                        {/* <Input ref={Country} placeholder="Country" style={{width: '50%'}} /> */}
                        <ComboBox
                          id="Country"
                          onChange={handleLocationDataChange}
                          placeholder="Country"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        >
                          {countries &&
                            countries.map((row) => {
                              return <ComboBoxItem text={row.country} />;
                            })}
                        </ComboBox>
                      </FormItem>
                    </>
                  )}
                  {online && (
                    <>
                      <FormItem label={<Label required>Platform</Label>}>
                        <ComboBox
                          id="LocationName"
                          placeholder="Zoom, MS Teams, Google Meet ..."
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                          ref={eventPlatformRef}
                          onChange={handleLocationDataChange}
                        >
                          <ComboBoxItem id="ZOOM" text="Zoom" />
                          <ComboBoxItem id="TEAMS" text="Microsoft Teams" />
                          <ComboBoxItem id="MEET" text="Google Meet" />
                          <div slot="valueStateMessage"> Invalid Platform </div>
                        </ComboBox>
                        <Avatar colorScheme="Accent6" shape="Square" size="XS">
                          <img src={iconUrl} alt="Image" />
                        </Avatar>
                      </FormItem>
                      <FormItem label={<Label required>Event Link</Label>}>
                        <Input
                          type="URL"
                          id="EventLink"
                          ref={eventLinkRef}
                          onChange={handleLocationDataChange}
                          placeholder="Link to join the event"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        >
                          <div slot="valueStateMessage">
                            Invlid meeting URL. Please enter a valid URL for
                            attendees to join the meeting.
                          </div>
                        </Input>
                      </FormItem>
                    </>
                  )}
                  {/* <FormItem label="Home address">
                                <CheckBox checked />
                            </FormItem> */}
                </FormGroup>
              </Form>
              <FlexBox
                alignItems="Center"
                direction="Row"
                justifyContent="Center"
                style={{ padding: "1%" }}
                fitContainer="true"
                wrap="NoWrap"
              >
                <Button
                  onClick={submitStep2}
                  design="Emphasized"
                  style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                >
                  {"Save & Continue"}
                </Button>
              </FlexBox>
            </WizardStep>
            <WizardStep
              ref={step3}
              disabled={currentStep !== 3}
              subtitleText="Event Date & Time"
              icon="calendar"
              titleText="Date & Time"
            >
              <Button
                icon="question-mark"
                onClick={onShowEventDateTimeHelp}
                tooltip="What is this?"
                style={{ position: "absolute", top: "25px", right: "40px" }}
              />

              <Form>
                <FormGroup titleText="">
                  <FormItem label="Recurring Event">
                    <CheckBox onChange={recurringCheckBoxChanged} />
                  </FormItem>
                  {daysVis && (
                    <>
                      <FormItem label={<Label required>Occurs every</Label>}>
                        <FlexBox wrap={wrap}>
                          <CheckBox
                            onChange={recurrenceDaysChecked}
                            style={{ width: "50%" }}
                            id="1"
                            text="Sunday"
                          />
                          <CheckBox
                            onChange={recurrenceDaysChecked}
                            style={{ width: "50%" }}
                            id="2"
                            text="Monday"
                          />
                          <CheckBox
                            onChange={recurrenceDaysChecked}
                            style={{ width: "50%" }}
                            id="3"
                            text="Tuesday"
                          />
                          <CheckBox
                            onChange={recurrenceDaysChecked}
                            style={{ width: "50%" }}
                            id="4"
                            text="Wednesday"
                          />
                          <CheckBox
                            onChange={recurrenceDaysChecked}
                            style={{ width: "50%" }}
                            id="5"
                            text="Thursday"
                          />
                          <CheckBox
                            onChange={recurrenceDaysChecked}
                            style={{ width: "50%" }}
                            id="6"
                            text="Friday"
                          />
                          <CheckBox
                            onChange={recurrenceDaysChecked}
                            style={{ width: "50%" }}
                            id="7"
                            text="Saturday"
                          />
                        </FlexBox>
                      </FormItem>
                      <FormItem label={<Label required>Start Date</Label>}>
                        <DatePicker
                          onChange={recurrenceTimePeriodChange}
                          id="StartDate"
                          placeholder="Event Start Date"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        />
                      </FormItem>
                      <FormItem label={<Label required>End Date</Label>}>
                        <DatePicker
                          onChange={recurrenceTimePeriodChange}
                          id="EndDate"
                          placeholder="Event End Date"
                          style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                        />
                      </FormItem>
                    </>
                  )}
                  {!daysVis && (
                    <FormItem label={<Label required>Event Date</Label>}>
                      <DatePicker
                        ref={clearEventDate}
                        onChange={recurrenceTimePeriodChange}
                        id="Date"
                        placeholder="Event Date"
                        style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                      />
                    </FormItem>
                  )}
                  <FormItem label={<Label required>Start Time</Label>}>
                    <TimePicker
                      ref={clearStartTime}
                      onChange={recurrenceTimePeriodChange}
                      formatPattern="HH:mm"
                      id="StartTime"
                      placeholder="Event Start Time"
                      style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                    />
                  </FormItem>
                  <FormItem label={<Label required>End Time</Label>}>
                    <TimePicker
                      ref={clearEndTime}
                      onChange={recurrenceTimePeriodChange}
                      formatPattern="HH:mm"
                      id="EndTime"
                      placeholder="Event End Time"
                      style={{ width: wrap === "NoWrap" ? "50%" : "100%" }}
                    />
                  </FormItem>
                </FormGroup>
              </Form>
              <Form style={{ alignItems: "center" }}>
                <FormItem label="">
                  <Button
                    onClick={submitStep3}
                    design="Emphasized"
                    style={{ width: "50%" }}
                  >
                    {"Save & Continue"}
                  </Button>
                </FormItem>
              </Form>
            </WizardStep>
            <WizardStep
              ref={step4}
              disabled={currentStep !== 4}
              subtitleText="Phone, Email, Social Media, etc.,"
              titleText="Contact Details"
              icon="customer-and-contacts"
            >
              <Button
                style={{ position: "absolute", top: "25px", right: "40px" }}
                icon="question-mark"
                onClick={onShowEventContactHelp}
                tooltip="What is this?"
              />{" "}
              {contacts.map((contact, index) => (
                <FlexBox
                  key={index}
                  direction="row"
                  justifyContent="SpaceBetween"
                  alignItems="Start"
                  wrap={wrap}
                  style={{
                    marginBottom: "1rem",
                    border: "1px solid #ccc",
                    padding: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  <ComboBox
                    onChange={(event) =>
                      handleInputChangeContacts(
                        index,
                        "type",
                        event.target.value
                      )
                    }
                    value={contact.type}
                    style={{ marginBottom: "1rem", width: "100%" }}
                  >
                    <ComboBoxItem text="Phone">Phone</ComboBoxItem>
                    <ComboBoxItem text="Email">Email</ComboBoxItem>
                    <ComboBoxItem text="Link">Link</ComboBoxItem>
                  </ComboBox>
                  <div style={{ width: "5%" }} />
                  <Input
                    onInput={(event) =>
                      handleInputChangeContacts(
                        index,
                        "description",
                        event.target.value
                      )
                    }
                    value={contact.description}
                    placeholder="Contact Description"
                    style={{ marginBottom: "1rem", width: "100%" }}
                  />
                  <div style={{ width: "5%" }} />
                  <Input
                    onInput={(event) =>
                      handleInputChangeContacts(
                        index,
                        "data",
                        event.target.value
                      )
                    }
                    value={contact.data}
                    placeholder="Contact Data"
                    style={{ marginBottom: "1rem", width: "100%" }}
                  />
                  <div style={{ width: "5%" }} />
                  <Button
                    design="Negative"
                    style={{ marginTop: "0.2rem", width: "100%" }}
                    onClick={() => deleteContactRow(index)}
                  >
                    Delete
                  </Button>
                </FlexBox>
              ))}
              <FlexBox
                alignItems="Center"
                direction="Row"
                justifyContent="Center"
                style={{ padding: "1%" }}
                fitContainer="true"
                wrap="NoWrap"
              >
                <Button
                  ref={submitStep1Ref}
                  design="Attention"
                  onClick={addContactRow}
                  style={{ width: "50%" }}
                >
                  {"Add Contact"}
                </Button>
                <div style={{ width: "5%" }} />
                <Button
                  style={{ width: "50%" }}
                  onClick={submitStep4}
                  design="Emphasized"
                >
                  {"Save & Continue"}
                </Button>
              </FlexBox>
            </WizardStep>
            <WizardStep
              ref={step5}
              disabled={currentStep !== 5}
              subtitleText="Invite Groups & Individuals"
              titleText="Attendees"
              icon="people-connected"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Button
                  style={{ position: "absolute", top: "25px", right: "40px" }}
                  icon="question-mark"
                  onClick={onShowEventAttendeeHelp}
                  tooltip="What is this?"
                />
                {rows.map((row, index) => (
                  <FlexBox
                    key={index}
                    direction="row"
                    justifyContent="SpaceBetween"
                    alignItems="Start"
                    wrap={wrap}
                    style={{
                      marginBottom: "1rem",
                      border: "1px solid #ccc",
                      padding: "1rem",
                      borderRadius: "5px",
                    }}
                  >
                    <ComboBox
                      placeholder="Select Attendee Type"
                      value={row.type}
                      onChange={(e) => handleTypeChange(index, e)}
                      style={{ marginBottom: "1rem", width: "100%" }}
                    >
                      <ComboBoxItem text="Group" />
                      <ComboBoxItem text="Community Zone" />
                      <ComboBoxItem text="Home Cell" />
                      <ComboBoxItem text="Assembly" />
                      <ComboBoxItem text="Individual" />
                    </ComboBox>
                    <div style={{ width: "5%" }} />
                    {renderInputControl(row.type, index)}
                    <div style={{ width: "5%" }} />
                    <Button
                      design="Negative"
                      style={{ marginTop: "0.2rem", width: "100%" }}
                      onClick={() => deleteRow(index)}
                    >
                      Delete
                    </Button>
                  </FlexBox>
                ))}
                <FlexBox
                  alignItems="Center"
                  direction="Row"
                  justifyContent="Center"
                  style={{ padding: "1%" }}
                  fitContainer="true"
                  wrap="NoWrap"
                >
                  <Button
                    design="Attention"
                    onClick={addRow}
                    style={{ width: "50%" }}
                  >
                    Add Row
                  </Button>
                  <div style={{ width: "5%" }} />
                  <Button style={{ width: "50%" }} onClick={submitStep5}>
                    {"Skip this step"}
                  </Button>
                  <div style={{ width: "5%" }} />
                  <Button
                    style={{ width: "50%" }}
                    onClick={skipStep5}
                    design="Emphasized"
                  >
                    {"Save & Continue"}
                  </Button>
                </FlexBox>
              </div>
            </WizardStep>
            <WizardStep
              ref={step6}
              disabled={currentStep !== 6}
              subtitleText="Finalize & Publish"
              titleText="Publish"
              icon="approvals"
            >
              <IllustratedMessage
                size="Auto"
                titleLevel="H5"
                name="SuccessHighFive"
                titleText="Your event is ready to be published!"
                subtitleText="To edit the event details, please use the Manage Events app. The event will be visible to attendees only after it is published. Please make all the necessary edits before publishing the event."
              />
              <FlexBox
                alignItems="Center"
                direction="Row"
                justifyContent="Center"
                style={{ padding: "1%" }}
                fitContainer="true"
                wrap="NoWrap"
              >
                <Button
                  ref={submitStep1Ref}
                  design="Attention"
                  onClick={addContactRow}
                  style={{ width: "50%" }}
                >
                  {"Edit Event"}
                </Button>
                <div style={{ width: "5%" }} />
                <Button
                  style={{ width: "50%" }}
                  onClick={submitStep4}
                  design="Emphasized"
                >
                  {"Publish"}
                </Button>
              </FlexBox>
            </WizardStep>
          </Wizard>
        </Page>
      </motion.div>
    </React.Fragment>
  );
}
export default CreateEvent;
