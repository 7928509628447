import React, { useState, useRef, useEffect } from "react";
import { Dialog, Title, MessageStrip, Text, Modals, Toast, Form, FormItem, FormGroup, Input, Bar, ComboBoxItem, ComboBox, FlexBoxDirection, FlexBoxJustifyContent, Button, FlexBox, Label, Page, Panel, BusyIndicator } from '@ui5/webcomponents-react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import apis from '../../apis/api';
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import UserSpecMetadataLayout from '../Metadata/UserSpecificMetadataLayout';
import MetaGlobalMiscLayout from "./MetaGlobalMiscLayout";
import EventMetadataLayout from "./EventMetadataLayout";
import countries from '../../assets/countries.json';
import languages from '../../assets/languages.json';
import MemberGroupingLayout from '../Metadata/MemberGroupingLayout';
import MemberFilterLayout from "../../components/MemberFilterLayout";
import MemberListLayout from '../../components/MemberListLayout';
import EditMetadataLayout from "./EditMetadataLayout";
import ShellBarComponent from "../../components/ShellBarComponent";
import questionMark from "@ui5/webcomponents-icons/dist/question-mark.js";
import $ from 'jquery'; 
var selectedEntity = "";
var selectedParentEntity = "";
var selectedAssemblyType = "";
function MetadataManagerLayout() {

    useEffect(() => {
        setTimeout(() => setMessage(""), 8000);
    });
    const showDialog = Modals.useShowDialog();
    const [error, setError] = useState(false);
    const [entName, setEntName] = useState("");
    const [entDesc, setEntDesc] = useState("");

    const groupBox = useRef();
    const entityBox = useRef();
    const operationBox = useRef();
    const createPane = useRef();
    const managePane = useRef();
    const assignMemberDialog = useRef();
    const editMetaDialog = useRef();
    const manageUserSpec = useRef();
    const createUserSpec = useRef();
    const createGroupingView = useRef();
    const manageGroupingView = useRef();
    const entityName = useRef();
    const entityDescription = useRef();
    const entityUser = useRef();
    const assemblyType = useRef();
    const parentUnit = useRef();
    const langSpoken = useRef();
    const addressPane = useRef();
    const Address1 = useRef();
    const Address2 = useRef();
    const Locality = useRef();
    const Region = useRef();
    const Pcode = useRef();
    const createGlobalMetaView = useRef();
    const manageGlobalMetaView = useRef();
    const [GlobalMetaContent, setGlobalMetaContent] = useState();
    const [assemblyTypesArray, setAssemblyTypesArray] = useState([]);
    const [assemblySpecificFields, setAssemblySpecificFields] = useState(false);
    const [entityId, setSelectedEntityId] = useState();
    const City = useRef();
    const [url, setUrl] = useState("");
    const [parentUrl, setParentUrl] = useState("");
    const [random, setRandom] = useState();
    const Country = useRef();
    const navigate = useNavigate();
    const [entityList, setEntityList] = useState([]);
    const [entityRole, setEntityRole] = useState("");
    const [busy, setBusy] = useState(false);
    const [parentEntity, setParentEntity] = useState("");
    const [parentEntityData, setParentEntityData] = useState("");
    const [Columns, setColumns] = useState([]);
    const [userSpecContent, setuserSpecContent] = useState();
    const [MemberGroupingContent, setMemberGroupingContent] = useState();
    const toastRef = useRef();
    const showToast = () => {
        toastRef.current.show();
    };
    const [message, setMessage] = useState("");
    const [header, setHeader] = useState("");
    const [operation, setOperation] = useState("");
    const [entity, setEntity] = useState("");
    const[rows, setRows] = useState([]);
    const [memberDataResponse, setResponse] = useState([]);
    const dialogClose = () => {
        assignMemberDialog.current.close();
    };
    const metaDialogClose = () => {
        editMetaDialog.current.close();
        routeToTarget();
    };

    const loadMemberData = async (filters) => {
        var payload = filters;
        apis.post('api/MemebershipManager/getFilteredMembers',payload,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
              }
        }).then(response =>{
            setResponse(response.data);
        }).catch(err =>{
            console.log(err);
        })
    };
    const onFilterGo = (filterJson) => {
        loadMemberData(filterJson);
    };
    const onGroupSelect = async (e) =>{
        var selectedItem = e.target.value;
        entityBox.current.value = '';
        var response = {};
        if(selectedItem === 'Organizational Structure'){
            var allowedStructures = [];
            if(localStorage.getItem("FILTER_DATA")){
               response["data"] = JSON.parse(localStorage.getItem("FILTER_DATA"));
            } else {
                response = await apis.get("api/filter/getFilterData",{
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                    },
                });
                localStorage.setItem("FILTER_DATA", JSON.stringify(response.data));
            }
            
            
            if(response.data.block){
                allowedStructures.push("Block")
            }
            if(response.data.countries){
                allowedStructures.push("Country")
            }
            if(response.data.rccs){
                allowedStructures.push("RCC")
            }
            if(response.data.areas){
                allowedStructures.push("Area")
            }
            if(response.data.districts){
                allowedStructures.push("District")
            }
            if(response.data.assemblies){
                allowedStructures.push("Local Assembly")
            }
            setEntityList(allowedStructures);
        } else if(selectedItem === "User Specific"){
            setEntityList(["Professional Category", "Disability Types", "Traditional Position Type (Royal Ministry)", "Career Type", "Business Type"]);
        } else if(selectedItem === "Member Grouping"){
            setEntityList(["Community Zone", "Home Cell", "Group"]);
        } else if (selectedItem === "Misc. Global Metadata"){
            setEntityList(["Assembly Type", "Group Position", "Educational Qualification Level", "External Assemblies"]);
        } else if (selectedItem === "Event Metadata"){
            setEntityList(["Attendance Type"]);
        }
    };
    const routeToTarget = (e) => {
        var validity = true;
        var entity = entityBox.current.value;
        var operation = operationBox.current.value;
        var group = groupBox.current.value;
        var errorMessage = 'Invalid ';
        if(entity===''){
            validity=false;
            entityBox.current.valueState = "Error";
            errorMessage+='Entity ';
        }else{
            entityBox.current.valueState = "None"
        }
        if(operation===''){
            validity=false;
            operationBox.current.valueState = "Error"
            errorMessage+='Operation ';
        }else{
            operationBox.current.valueState = "None"
        }
        if(group===''){
            validity=false;
            groupBox.current.valueState = "Error"
            errorMessage+='Group ';
        }else{
            groupBox.current.valueState = "None"
        }
        if(!validity){
            setMessage(errorMessage);
            showToast();
            managePane.current.hidden = true;
            createPane.current.hidden = true;
            createUserSpec.current.hidden = true;
            manageUserSpec.current.hidden = true;
            manageGroupingView.current.hidden = true;
            createGroupingView.current.hidden = true;

            return;
        } else{
            setHeader(operation+" "+entity);
            setOperation(operation);
            setEntity(entity);
            setBusy(true);
            selectedEntity = entity;
            if(group === "Organizational Structure"){
                if(operation === 'Create'){
                    createPane.current.hidden = false;
                    managePane.current.hidden = true;
                    createUserSpec.current.hidden = true;
                    manageUserSpec.current.hidden = true;
                    manageGroupingView.current.hidden = true;
                    createGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = true;
                    manageGlobalMetaView.current.hidden = true;
                    prepareCreateComponent(entity);
                }
                else{
                    createPane.current.hidden = true;
                    managePane.current.hidden = false;
                    createUserSpec.current.hidden = true;
                    manageUserSpec.current.hidden = true;
                    manageGroupingView.current.hidden = true;
                    createGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = true;
                    manageGlobalMetaView.current.hidden = true;
                    prepareViewModifyComponent(entity);
                }
            } else if(group === "User Specific"){
                if(operation === 'Create'){
                    createPane.current.hidden = true;
                    managePane.current.hidden = true;
                    createUserSpec.current.hidden = false;
                    manageUserSpec.current.hidden = true;
                    manageGroupingView.current.hidden = true;
                    createGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = true;
                    manageGlobalMetaView.current.hidden = true;
                    setBusy(false);
                    setuserSpecContent(<UserSpecMetadataLayout operation="Create" entity={selectedEntity}/>);
                } else {
                    createPane.current.hidden = true;
                    managePane.current.hidden = true;
                    createUserSpec.current.hidden = true;
                    manageUserSpec.current.hidden = false;
                    manageGroupingView.current.hidden = true;
                    createGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = true;
                    manageGlobalMetaView.current.hidden = true;
                    setBusy(false);
                    setuserSpecContent(<UserSpecMetadataLayout operation="Manage / View" entity={selectedEntity}/>);
                }
            } else if(group === "Member Grouping"){
                if(operation === 'Create'){
                    createPane.current.hidden = true;
                    managePane.current.hidden = true;
                    createUserSpec.current.hidden = true;
                    manageUserSpec.current.hidden = true;
                    manageGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = true;
                    manageGlobalMetaView.current.hidden = true;
                    createGroupingView.current.hidden = false;
                    setBusy(false);
                    if(entity==="Community Zone"){
                        setMemberGroupingContent(<MemberGroupingLayout operation="Create" entity="Community Zone"/>);
                    }else if(entity==="Home Cell"){
                        setMemberGroupingContent(<MemberGroupingLayout operation="Create" entity="Home Cell"/>);
                    }else if(entity === "Group"){
                        setMemberGroupingContent(<MemberGroupingLayout operation="Create" entity="Group"/>);
                    }
                } else {
                    createPane.current.hidden = true;
                    managePane.current.hidden = true;
                    createUserSpec.current.hidden = true;
                    manageUserSpec.current.hidden = true;
                    manageGroupingView.current.hidden = false;
                    createGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = true;
                    manageGlobalMetaView.current.hidden = true;
                    setBusy(false);
                    if(entity==="Community Zone"){
                        setMemberGroupingContent(<MemberGroupingLayout operation="Manage / View" entity="Community Zone"/>);
                    }else if(entity==="Home Cell"){
                        setMemberGroupingContent(<MemberGroupingLayout operation="Manage / View" entity="Home Cell"/>);
                    }else if(entity === "Group"){
                        setMemberGroupingContent(<MemberGroupingLayout operation="Manage / View" entity="Group"/>);
                    }
                }
            } else if(group === "Misc. Global Metadata"){
                if(operation === 'Create'){
                    createPane.current.hidden = true;
                    managePane.current.hidden = true;
                    createUserSpec.current.hidden = true;
                    manageUserSpec.current.hidden = true;
                    manageGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = false;
                    manageGlobalMetaView.current.hidden = true;
                    createGroupingView.current.hidden = true;
                    setBusy(false);
                    setGlobalMetaContent(<MetaGlobalMiscLayout operation="Create" entity={entity}/>);
                    
                } else {
                    createPane.current.hidden = true;
                    managePane.current.hidden = true;
                    createUserSpec.current.hidden = true;
                    manageUserSpec.current.hidden = true;
                    manageGroupingView.current.hidden = true;
                    createGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = true;
                    manageGlobalMetaView.current.hidden = false;
                    setBusy(false);
                    setGlobalMetaContent(<MetaGlobalMiscLayout operation="Manage / View" entity={entity}/>);
                }
            } else if(group === "Event Metadata"){
                if(operation === "Create"){
                    createPane.current.hidden = true;
                    managePane.current.hidden = true;
                    createUserSpec.current.hidden = true;
                    manageUserSpec.current.hidden = true;
                    manageGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = false;
                    manageGlobalMetaView.current.hidden = true;
                    createGroupingView.current.hidden = true;
                    setBusy(false);
                    setGlobalMetaContent(<EventMetadataLayout operation="Create" entity={entity}/>);
                } else {
                    createPane.current.hidden = true;
                    managePane.current.hidden = true;
                    createUserSpec.current.hidden = true;
                    manageUserSpec.current.hidden = true;
                    manageGroupingView.current.hidden = true;
                    createGroupingView.current.hidden = true;
                    createGlobalMetaView.current.hidden = true;
                    manageGlobalMetaView.current.hidden = false;
                    setBusy(false);
                    setGlobalMetaContent(<EventMetadataLayout operation="Manage / View" entity={entity}/>);
                }
            }
        }
    };
    const prepareViewModifyComponent = (entity)=>{
        switch(entity){
            case "Block":
                setColumns(["Name", "Description", "Coordinator", "", ""]);
                setEntityRole("Coordinator");
                readEntityContent(URLS.READ_BLOCK, entity, "V");
                setParentEntity("");
                break;
            case "Country":
                setColumns(["Name", "Description", "Head", "Block", "", ""]);
                setEntityRole("Head");
                setParentEntity("Block");
                readEntityContent(URLS.READ_COUNTRY, entity, "V");
                break;
            case "RCC":
                setColumns(["Name", "Description", "Coordinator", "Country", "", ""]);
                setEntityRole("Coordinator");
                setParentEntity("Country");
                readEntityContent(URLS.READ_RCC, entity, "V");
                break;
            case "Area":
                setColumns(["Name", "Description", "Head", "RCC", "Address", "", ""]);
                setEntityRole("Head");
                setParentEntity("RCC");
                readEntityContent(URLS.READ_AREA, entity, "V");
                break;
            case "District":
                setColumns(["Name", "Description", "Pastor", "Area", "Address", "", ""]);
                setEntityRole("Pastor");
                setParentEntity("Area");
                readEntityContent(URLS.READ_DIST, entity, "V");
                break;
            case "Local Assembly":
                setColumns(["Name", "Description", "Presiding Elder", "District", "Address", "", ""]);
                setEntityRole("Presiding Elder");
                setParentEntity("District");
                readEntityContent(URLS.READ_LA, entity, "V");
                break;
            default:
                break;
        }
    };
    const deleteConfirm = (e)=>{
        var recId = e.currentTarget.id;
        const {close} = showDialog({
            state: 'Warning',
            headerText: 'Delete ' + entity,
            children: "Are you sure you want to delete this block?",
            footer: <Bar endContent={<><Button id={recId} design="Negative" onClick={()=>{onDeleteItem(recId); close();}}>Delete</Button><Button onClick={() => close()}>Cancel</Button></>} />
          });
    }
    const onDeleteRecord = async function(url, id, entity){
        try{
          const response = await apis.delete(url+id, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            }
          });
          prepareViewModifyComponent(entity);
          setMessage(entity+' Deleted');
        }
        catch(err){
          if (!err?.response) {
            setMessage('No Server Response');
          } else if (err.response?.status === 400) {
            setMessage('Email or Username not available');
          } else {
            prepareViewModifyComponent(entity);
            setMessage(entity+' Deleted');
          }
        }
        showToast();
    };
    const onDeleteItem = (e)=>{
        var recId = e;
        switch(selectedEntity){
            case "Block":
                onDeleteRecord(URLS.DEL_BLOCK,recId, selectedEntity);
                break;
            case "Country":
                onDeleteRecord(URLS.DEL_COUNTRY,recId, selectedEntity);
                break;
            case "RCC":
                onDeleteRecord(URLS.DEL_RCC,recId, selectedEntity);
                break;
            case "Area":
                onDeleteRecord(URLS.DEL_AREA,recId, selectedEntity);
                break;
            case "District":
                onDeleteRecord(URLS.DEL_DIST,recId, selectedEntity);
                break;
            case "Local Assembly":
                onDeleteRecord(URLS.DEL_LA,recId, selectedEntity);
                break;
            default:
                break;
        }
    };
    const onEditItem = (e)=>{
        var recId = e.currentTarget.id;
        setSelectedEntityId(recId);
        setRandom(Math.random());
        switch(selectedEntity){
            case "Block":
                setUrl(URLS.EDIT_BLOCK);
                setParentUrl("");
                break;
            case "Country":
                setUrl(URLS.EDIT_COUNTRY);
                setParentUrl(URLS.READ_BLOCK);
                break;
            case "RCC":
                setUrl(URLS.EDIT_RCC);
                setParentUrl(URLS.READ_COUNTRY);
                break;
            case "Area":
                setUrl(URLS.EDIT_AREA);
                setParentUrl(URLS.READ_RCC);
                break;
            case "District":
                setUrl(URLS.EDIT_DIST);
                setParentUrl(URLS.READ_AREA);
                break;
            case "Local Assembly":
                setUrl(URLS.EDIT_LA);
                setParentUrl(URLS.READ_DIST);
                break;
            default:
                break;
        }
        editMetaDialog.current.show();
    };
    const readEntityContent = async (url, entity, operation) => {
        // try{
            const response = await apis.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
                }
            });
            var processedData = [];
            if(operation!=="V"){
                if(entity==="Block"){
                    response.data.forEach((index)=>{
                        processedData.push({id:index.blockId, text:index.blockName})
                    });
                }
                else if(entity==="Country"){
                    response.data.forEach((index)=>{
                        processedData.push({id:index.countryId, text:index.countryName})
                    });
                } 
                else if(entity==="RCC"){
                    response.data.forEach((index)=>{
                        processedData.push({id:index.rccId, text:index.rccName})
                    });
                }
                else if(entity==="Area"){
                    response.data.forEach((index)=>{
                        processedData.push({id:index.areaId, text:index.areaName})
                    });
                }
                else if(entity==="District"){
                    response.data.forEach((index)=>{
                        processedData.push({id:index.districtId, text:index.districtName})
                    });
                }
                else if(entity==="LA"){
                    response.data.forEach((index)=>{
                        processedData.push({id:index.assemblyId, text:index.assemblyName})
                    });
                }
                setParentEntityData(processedData);
            }
            else{
                if(entity==="Block"){
                    response.data.forEach((i)=>{
                        processedData.push(
                            <Tr>
                                <Td>{i.blockName}</Td>
                                <Td>{i.blockDescription.split('//')[0]}</Td>
                                <Td>{i.blockDescription.split('//')[1]}</Td>
                                <Td>
                                        <Button onClick={onEditItem} id={i.blockId}>Edit</Button>
                                        <Button onClick={deleteConfirm} id={i.blockId} design="Negative">Delete</Button>
                                </Td>
                            </Tr>
                        )
                    });
                    setRows(processedData);
                }
                else if(entity==="Country"){
                    response.data.forEach((i)=>{
                        processedData.push(
                            <Tr>
                                <Td>{i.countryName}</Td>
                                <Td>{i.countryDescription.split("//")[0]}</Td>
                                <Td>{i.countryDescription.split("//")[1]}</Td>
                                <Td>{i.block.blockName} ({i.block.blockDescription})</Td>
                                <Td>
                                    <Button onClick={onEditItem} id={i.countryId}>Edit</Button>
                                    <Button onClick={deleteConfirm} id={i.countryId} design="Negative">Delete</Button>
                                </Td>
                            </Tr>
                        )
                    });
                    setRows(processedData);
                } 
                else if(entity==="RCC"){
                    response.data.forEach((i)=>{
                        processedData.push(
                            <Tr>
                                <Td>{i.rccName}</Td>
                                <Td>{i.rccDescription.split("//")[0]}</Td>
                                <Td>{i.rccDescription.split("//")[1]}</Td>
                                <Td>{i.country.countryName} ({i.country.countryDescription})</Td>
                                <Td>
                                    <Button onClick={onEditItem} id={i.rccId}>Edit</Button>
                                    <Button onClick={deleteConfirm} id={i.rccId} design="Negative">Delete</Button>
                                </Td>
                            </Tr>
                        )
                    });
                    setRows(processedData);
                }
                else if(entity==="Area"){
                    response.data.forEach((i)=>{
                        processedData.push(
                            <Tr>
                                <Td>{i.areaName}</Td>
                                <Td>{i.description.split("//")[0]}</Td>
                                <Td>{i.description.split("//")[1]}</Td>
                                <Td>{i.rcc.rccName}</Td>
                                <Td>
                                    <Button onClick={onEditItem} id={i.areaId}>Edit</Button>
                                    <Button onClick={deleteConfirm} id={i.areaId} design="Negative">Delete</Button>
                                </Td>
                            </Tr>
                       
                        )
                    });
                    setRows(processedData);
                }
                else if(entity==="District"){
                    response.data.forEach((i)=>{
                        processedData.push(
                            <Tr>
                                <Td>{i.districtName}</Td>
                                <Td>{i.description.split("//")[0]}</Td>
                                <Td>{i.description.split("//")[1]}</Td>
                                <Td>{i.area.areaName}</Td>
                                <Td>
                                    <Text wrapping="true">{i.districtAddress.addressLine1}, {i.districtAddress.addressLine2}, {i.districtAddress.addressLine1}, {i.districtAddress.locality}, {i.districtAddress.city}, {i.districtAddress.region}, {i.districtAddress.country} - {i.districtAddress.postalCode}</Text>
                                </Td>
                                <Td>
                                    <Button onClick={onEditItem} id={i.districtId}>Edit</Button>
                                    <Button onClick={deleteConfirm} id={i.districtId} design="Negative">Delete</Button>
                                </Td>
                            </Tr>
                        )
                    });
                    setRows(processedData);
                }
                else if(entity==="Local Assembly"){
                    response.data.forEach((i)=>{
                        if(i.description === null){
                            i.description = "";
                        }
                        processedData.push(
                            <Tr>
                                <Td>{i.assemblyName}</Td>
                                <Td>{(i.description.split("//").length===2)?i.description.split("//")[0]:i.description}</Td>
                                <Td>{(i.description.split("//").length===2)?i.description.split("//")[1]:""}</Td>
                                <Td>{i.district.districtName}</Td>
                                <Td>
                                    <Text wrapping="true">{i.assemblyAddress.addressLine1}, {i.assemblyAddress.addressLine2}, {i.assemblyAddress.addressLine1}, {i.assemblyAddress.locality}, {i.assemblyAddress.city}, {i.assemblyAddress.region}, {i.assemblyAddress.country} - {i.assemblyAddress.postalCode}</Text>
                                </Td>
                                <Td>
                                    <Button onClick={onEditItem} id={i.assemblyId}>Edit</Button>
                                    <Button onClick={deleteConfirm} id={i.assemblyId} design="Negative">Delete</Button>
                                </Td>
                            </Tr>
                        )
                    });
                    setRows(processedData);
                }
                setBusy(false);
            }
        // } catch(err){
        //     processedData = [];
        //     setMessage("Server error. Please contact your administrator.");
        //     showToast();
        // }
    }
    const prepareCreateComponent = (entity) => {
        setAssemblySpecificFields(false);
        switch(entity){
            case "Block":
                setError(false);
                setParentEntity("");
                setMessage("");
                entityName.current.hidden = false;
                entityDescription.current.hidden = false;
                entityUser.current.hidden = false;
                addressPane.current.hidden = true;
                // parentUnit.current.then(val=>{parentUnit.current.hidden=true});
                // unit.hidden = true;
                setEntityRole("Coordinator");
                setBusy(false);
                break;
            case "Country":
                setError(false);
                setMessage("");
                setParentEntity("Block");
                entityName.current.hidden = false;
                entityDescription.current.hidden = false;
                entityUser.current.hidden = false;
                addressPane.current.hidden = true;
                // parentUnit.current.hidden = false;
                setEntityRole("Head");
                readEntityContent(URLS.READ_BLOCK, "Block");
                setBusy(false);
                break;
            case "RCC":
                setError(false);
                setMessage("");
                setParentEntity("Country");
                entityName.current.hidden = false;
                entityDescription.current.hidden = false;
                entityUser.current.hidden = false;
                addressPane.current.hidden = true;
                // parentUnit.current.hidden = false;
                setEntityRole("Coordinator");
                readEntityContent(URLS.READ_COUNTRY, "Country");
                setBusy(false);
                break;
            case "Area":
                setError(false);
                setMessage("");
                setParentEntity("RCC");
                entityName.current.hidden = false;
                entityDescription.current.hidden = false;
                entityUser.current.hidden = false;
                addressPane.current.hidden = false;
                // parentUnit.current.hidden = false;
                setEntityRole("Head");
                readEntityContent(URLS.READ_RCC, "RCC");
                setBusy(false);
                break;
            case "District":
                setError(false);
                setParentEntity("Area");
                setMessage("");
                entityName.current.hidden = false;
                entityDescription.current.hidden = false;
                entityUser.current.hidden = false;
                addressPane.current.hidden = false;
                // parentUnit.current.hidden = false;
                setEntityRole("Pastor");
                readEntityContent(URLS.READ_AREA, "Area");
                setBusy(false);
                break;
            case "Local Assembly":
                setError(false);
                setMessage("");
                setParentEntity("District");
                entityName.current.hidden = false;
                entityDescription.current.hidden = false;
                entityUser.current.hidden = false;
                addressPane.current.hidden = false;
                // parentUnit.current.hidden = false;
                setEntityRole("Presiding Elder");
                fetchAssemblyTypes();
                setAssemblySpecificFields(true);
                readEntityContent(URLS.READ_DIST, "District");
                setBusy(false);
                break;
            default:
                break;
        }
    };

    
    //CReate new record
    const handleCreateOrg = async () =>{
        setBusy(true);
        const guid = "de519e41-ad5a-4cf6-a8a8-4d9b291d4ad3";
        let payLoad = null,  url = "";
        setError(false);
        setMessage("");
        var pEntityCheck = false;
        var errorObjects = [];
        if(parentUnit.current){
            if(parentUnit.current.value.length === 0){
            pEntityCheck =  true;
            errorObjects.push(parentUnit);
            }
        }
        if(entityName.current.value.length===0){
            errorObjects.push(entityName);
        }
        if(entityDescription.current.value.length===0){
            errorObjects.push(entityDescription);
        }
        if(addressPane.current){
            if(!addressPane.current.hidden){
                if(Address1.current.value.length===0){
                    errorObjects.push(Address1);
                }
                if(City.current.value.length===0){
                    errorObjects.push(City);
                }
                if(Pcode.current.value.length === 0){
                    errorObjects.push(Pcode);
                }
                if(Country.current.value.length === 0){
                    errorObjects.push(Country);
                }
            }
        }
        if (errorObjects.length!=0){
            setBusy(false);
            for(var i=0; i<errorObjects.length;i++){
                errorObjects[i].current.valueState="Error";
            }
        } else{
            switch(selectedEntity){
                case "Block":
                    payLoad = [{
                        BlockId: guid,
                        BlockName: entityName.current.value,
                        BlockDescription: entityDescription.current.value,
                        UserId: (entityUser.current.value!=="Select Block Coordinator")?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                      }];
                    url = URLS.CREATE_BLOCK;
                    break;
                case "Country":
                    payLoad = {
                        CountryId: guid,
                        CountryName: entityName.current.value,
                        CountryDescription:  entityDescription.current.value,
                        UserId: (entityUser.current.value!=="Select Country Head")?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                        BlockId: selectedParentEntity
                      };
                    url = URLS.CREATE_COUNTRY;
                    break;
                case "RCC":
                    payLoad = {
                        RccId: guid,
                        RccName: entityName.current.value,
                        RccDescription: entityDescription.current.value,
                        UserId: (entityUser.current.value!=="Select RCC Coordinator")?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                        CountryId: selectedParentEntity,
                    };
                    url = URLS.CREATE_RCC;
                    break;
                case "Area":
                    payLoad = {
                        AreaId: guid,
                        AreaName: entityName.current.value,
                        Description:entityDescription.current.value,
                        AreaAddress: {
                            AddressId: guid,
                            MemberId: guid,
                            AddressLine1: Address1.current.value,
                            AddressLine2: Address2.current.value,
                            Locality: Locality.current.value,
                            City: City.current.value,
                            Region: Region.current.value,
                            PostalCode: Pcode.current.value,
                            Country: Country.current.value
                        },
                        UserId: (entityUser.current.value!=="Select Area Head")?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                        RccId: selectedParentEntity,
                    };
                    var array = [];
                    array.push(payLoad);
                    payLoad = array;
                    url = URLS.CREATE_AREA;
                    break;
                case "District":
                    payLoad= {
                        DistrictId: guid,
                        DistrictName:  entityName.current.value,
                        Description:entityDescription.current.value,
                        DistrictAddress: {
                            AddressId: guid,
                            MemberId: guid,
                            AddressLine1: Address1.current.value,
                            AddressLine2: Address2.current.value,
                            Locality: Locality.current.value,
                            City: City.current.value,
                            Region: Region.current.value,
                            PostalCode: Pcode.current.value,
                            Country: Country.current.value
                        },
                        UserId: (entityUser.current.value!=="Select District Pastor")?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                        AreaId: selectedParentEntity,
                    };
                    var array = [];
                    array.push(payLoad);
                    payLoad = array;
                    url = URLS.CREATE_DIST;
                    break;
                case "Local Assembly":
                    payLoad= {
                        AssemblyId: guid,
                        AssemblyName:  entityName.current.value,
                        Description:entityDescription.current.value,
                        AssemblyAddress: {
                            AddressId: guid,
                            MemberId: guid,
                            AddressLine1: Address1.current.value,
                            AddressLine2: Address2.current.value,
                            Locality: Locality.current.value,
                            City: City.current.value,
                            Region: Region.current.value,
                            PostalCode: Pcode.current.value,
                            Country: Country.current.value
                        },
                        UserId: (entityUser.current.value!=="Select Local Assembly Presiding Elder")?entityUser.current.id:"00000000-0000-0000-0000-000000000000",
                        DistrictId: selectedParentEntity,
                        LangSpoken: langSpoken.current.value,
                        AssemblyTypeId: (selectedAssemblyType!=="")?selectedAssemblyType:"00000000-0000-0000-0000-000000000000",
                    };
                    var array = [];
                    array.push(payLoad);
                    payLoad = array;
                    url = URLS.CREATE_LA;
                    break;
                default:
                    break;
            }
                await apis.post(url, payLoad, {
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
                    },
                }).then(res=>{
                    setBusy(false);
                    handleClearInput();
                    const{close}=
                        showDialog({
                        headerText: 'Success',
                        state:"Success",
                        children: selectedEntity+" was created successfully.",
                        footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
                        });
                }).catch(err=>{
                    setBusy(false);
                    setMessage("Server error. Please contact your administrator.");
                    showToast();
                });
        }  

    };
    const goHome = ()=>{
        navigate('/');
    };
    const fetchAssemblyTypes = async () => {
        const response = await apis.get("api/metadata/getAllLocalAssemblyTypes", {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        }).then(response=>{
            setAssemblyTypesArray(response.data);
        }).catch(err=>{
            console.log(err);
        });
    };
    const handleClearInput = () => {
        entityName.current.value = '';
        entityName.current.valueState = 'None';
        entityDescription.current.value = '';
        entityDescription.current.valueState = 'None';
        if(!entityUser.current.value.includes("Select")){entityUser.current.value = '';}
        if(parentUnit.current)parentUnit.current.value = '';
        if(parentUnit.current)parentUnit.current.valueState="None";
        Address1.current.value = '';
        Address1.current.valueState = 'None';
        Address2.current.value = '';
        Country.current.value = '';
        Country.current.valueState = 'None';
        Pcode.current.value = '';
        Pcode.current.valueState = 'None';
        City.current.value = '';
        City.current.valueState = 'None';
        Region.current.value = '';
        Locality.current.value = '';
        setError(false);
        
    };
    const selectMember = (e) => {
        assignMemberDialog.current.show();
    };
    
    useEffect(() => {
        managePane.current.hidden = true;
        createPane.current.hidden = true;
        createUserSpec.current.hidden = true;
        manageUserSpec.current.hidden = true;
        manageGroupingView.current.hidden = true;
        createGroupingView.current.hidden = true;
        createGlobalMetaView.current.hidden = true;
        manageGlobalMetaView.current.hidden = true;
        document.title = 'CMS - Metadata Manager';
      }, []);
  return (
    <>
    <ShellBarComponent/>
        <Page style={{height:"100vh"}} header={<Bar startContent={<Button icon="nav-back" design="Transparent" title="Go back" onClick={goHome}/>}><Title level="H6">Metadata Manager</Title><Button icon="question-mark" tooltip="Need help?" design="Transparent"/></Bar>}>
            <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween} wrap="Wrap">
                <FlexBox direction={FlexBoxDirection.Column}>
                    <Label>Group</Label>
                    <ComboBox ref={groupBox} onChange={onGroupSelect}>
                        <ComboBoxItem text="Organizational Structure" />
                        <ComboBoxItem text="User Specific"/>
                        <ComboBoxItem text="Member Grouping"/>
                        <ComboBoxItem text="Misc. Global Metadata"/>
                        <ComboBoxItem text="Event Metadata"/>
                    </ComboBox>
                </FlexBox>
                <FlexBox direction={FlexBoxDirection.Column}>
                    <Label>Data Type</Label>
                    <ComboBox ref={entityBox}>
                        {entityList && entityList.map(row=>{
                            return <ComboBoxItem text={row}/>
                        })}
                    </ComboBox>
                </FlexBox>
                <FlexBox direction={FlexBoxDirection.Column}>
                    <Label>Operation</Label>
                    <ComboBox ref={operationBox} filter="Contains">
                        <ComboBoxItem text="Create"/>
                        <ComboBoxItem text="Manage / View"/>
                    </ComboBox>
                </FlexBox>
                <FlexBox direction={FlexBoxDirection.Column}>
                <Label></Label>
                    <Button onClick={routeToTarget}>Submit</Button>
                </FlexBox>
            </FlexBox>
            <div style={{height:"3%"}}></div>
            <Panel fixed="true" ref={createPane} headerText={header}>     
            <Form style={{padding: "1%",alignItems: 'center'}}>
                <FormGroup titleText="Basic Details">
                    {/* {!error ? <FormItem label=""> <span style={{color: "Green"}}>{message}</span></FormItem> : "" } */}
                    <FormItem label="">
                        <Input onChange={e=>setEntName(e.target.value)} ref={entityName} placeholder={entity + " Name"} style={{width: '80%'}}>
                        <div slot="valueStateMessage">{entity + " Name"} cannot be empty</div>
                            </Input>
                    </FormItem>
                    {/* {error && entName.length=="" ? <FormItem label=""> <span style={{color: "Red"}}>{entity +" name cannot be empty"}</span></FormItem> : "" } */}
                    <FormItem label="">
                        <Input onChange={e=>setEntDesc(e.target.value)} ref={entityDescription} placeholder={entity + " Description"} style={{width: '80%'}} >
                        <div slot="valueStateMessage">{entity + " Description"} cannot be empty</div>
                        </Input>
                    </FormItem>
                    {/* {error && entDesc.length=="" ? <FormItem label=""> <span style={{color: "Red"}}>{entity +" description cannot be empty"}</span></FormItem> : "" } */}
                   
                    <FormItem label="">
                        <Input  ref={entityUser} disabled = "true" style={{width: '60%'}} value={"Select "+entity +" "+entityRole}>
                        <div slot="valueStateMessage">{"You can also assign a "+entity+" "+entityRole+" later."} </div>
                            </Input>
                        <Button onClick={selectMember} style={{width: '20%'}}>Select Member</Button>
                    </FormItem>
                    {parentEntity!=="" && <FormItem label="">
                        <ComboBox ref={parentUnit} placeholder={"Parent"+ " " +parentEntity} style={{width: '80%'}} onChange={(e)=>{$.each(e.currentTarget.items,(k,v)=>{if(v.selected){selectedParentEntity=v.id;return;}})}}>
                        
                            {parentEntityData && parentEntityData.map(row=>{
                                return <ComboBoxItem id={row.id} text={row.text}/>
                            })}
                            <div slot="valueStateMessage">{"Parent "+parentEntity} cannot be empty</div>
                        </ComboBox>
                    </FormItem>}
                    {assemblySpecificFields&&<>
                    <FormItem label = "">
                        <ComboBox ref={langSpoken} placeholder="Language Spoken" style={{width: '80%'}}>
                            {languages && languages.map(row => {
                                    return <ComboBoxItem id={row.name} text={row.name}/>
                            })}
                        </ComboBox> 
                    </FormItem>
                    <FormItem label = "">
                        <ComboBox ref={assemblyType} placeholder="Assembly Type" style={{width: '80%'}} onChange={(e)=>{$.each(e.currentTarget.items,(k,v)=>{if(v.selected){selectedAssemblyType=v.id;return;}})}}>
                            {assemblyTypesArray && assemblyTypesArray.map(row => {
                                    return <ComboBoxItem id={row.assemblyTypeId} text={row.assemblyTypeName}/>
                            })}
                        </ComboBox> 
                    </FormItem></>}
                    <FormItem label = "">

                    </FormItem>
              </FormGroup>
              </Form>
              <Form   ref={addressPane} style={{padding: "1%",alignItems: 'center'}}>
                <FormGroup titleText={entity +" Address"}>
                        <FormItem label="">
                            <Input ref={Address1} placeholder="Address Line 1" style={{width: '80%'}}>
                                <div slot="valueStateMessage">Address line cannot be empty</div>
                            </Input>
                            
                        </FormItem>
                        <FormItem label="">
                            <Input ref={Address2} placeholder="Address Line 2" style={{width: '80%'}} />
                        </FormItem>
                        <FormItem label="">
                            <Input ref={Locality} placeholder="Locality" style={{width: '80%'}} />
                        </FormItem>
                        <FormItem label="">
                            <Input ref={Region} placeholder="Region" style={{width: '80%'}} />
                        </FormItem>
                        <FormItem label="">
                            <Input ref={City} placeholder="City" style={{width: '80%'}}>
                                <div slot="valueStateMessage">City cannot be empty</div>
                            </Input>
                        </FormItem>
                        <FormItem label="">
                            <Input ref={Pcode} placeholder="Postal Code" style={{width: '80%'}}>
                                <div slot="valueStateMessage">Postal Code cannot be empty</div>
                            </Input>
                        </FormItem>
                        <FormItem label="">
                            {/* <Input ref={Country} placeholder="Country" style={{width: '50%'}} /> */}
                            <ComboBox ref={Country} placeholder="Country" style={{width: '80%'}}>
                                {countries && countries.map(row => {
                                    return <ComboBoxItem text={row.country} />
                                })}
                                <div slot="valueStateMessage">Country cannot be empty</div>
                            </ComboBox>
                        </FormItem>
                </FormGroup>
              </Form>
              <Form style={{padding: "1%",alignItems: 'center'}}>
               
                <FormItem label="">
                    <Button design="Emphasized" style={{width: '40%'}} onClick={handleCreateOrg}>
                        {"Create "+entity}
                    </Button>
                    <Button style={{width: '40%'}} onClick={handleClearInput}>
                        Clear
                    </Button>
                </FormItem>
            </Form>
            <Toast duration="3000" ref={toastRef}>{message}</Toast>
            </Panel>
            <BusyIndicator active={busy} style={{width:'100%'}}/>   

            <Panel fixed="true" headerText={header} ref={managePane}>
            <BusyIndicator active={busy}/>
                <Table>
                    <Thead>
                        <Tr>
                        {Columns && Columns.map(
                            row => {
                                return(
                                    <Th>{row}</Th>
                                )
                            }
                        )}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows}
                    </Tbody>
                </Table>
            </Panel>
            <Panel fixed="true" headerText={header} ref={createUserSpec}>
                {userSpecContent}
            </Panel>
            <Panel fixed="true" headerText={header} ref={manageUserSpec}>
                {userSpecContent}
            </Panel>
            <Panel fixed="true" headerText={header} ref={createGroupingView}>
                {MemberGroupingContent}
            </Panel>
            <Panel fixed="true" headerText={header} ref={manageGroupingView}>
                {MemberGroupingContent}
            </Panel>
            <Panel fixed="true" headerText={header} ref={createGlobalMetaView}>
                {GlobalMetaContent}
            </Panel>
            <Panel fixed="true" headerText={header} ref={manageGlobalMetaView}>
                {GlobalMetaContent}
            </Panel>
            <Toast duration="3000" ref={toastRef}>{message}</Toast>
            {ReactDOM.createPortal(
            <Dialog stretch="true" draggable="true" resizable="true" ref={assignMemberDialog} 
            header={<Bar endContent={<Button design="Negative" onClick={dialogClose}>x</Button>}><Title>Assign Member Id</Title></Bar>}
            >
            <MessageStrip>
                Use the filters to select a member to assign as {entityRole}
            </MessageStrip>
            <MemberFilterLayout parentCallBack = {onFilterGo}/>
            <MemberListLayout closeDialog = {dialogClose} memberArray = {memberDataResponse} source="OrgUnit" returnRef = {entityUser}/>
            <div style={{height:"2%"}}/>
            <Toast ref={toastRef}>{message}</Toast>
            </Dialog>, document.body)}
            {ReactDOM.createPortal(
            <Dialog stretch="true" draggable="true" resizable="true" ref={editMetaDialog} 
            header={<Bar endContent={<Button design="Negative" onClick={metaDialogClose}>x</Button>}><Title>Edit {entity}</Title></Bar>}
            >
            <MessageStrip>
                Select {entity} {entityRole} using the select member button. You cannot enter the member Id manually
            </MessageStrip>
            <EditMetadataLayout entity = {entity} id={entityId} url = {url} parentUrl = {parentUrl} random={random} close={metaDialogClose}/>
            <div style={{height:"2%"}}/>
            <Toast ref={toastRef}>{message}</Toast>
            </Dialog>, document.body)}
        </Page>
    </>
  );
}

export default MetadataManagerLayout;
const URLS = {
    CREATE_BLOCK : "api/metadata/createBlock",
    READ_BLOCK : "api/metadata/getAllBlocks",
    DEL_BLOCK : "api/metadata/deleteBlock?id=",
    CREATE_COUNTRY : "api/metadata/createCountry",
    READ_COUNTRY : "api/metadata/getAllCountries",
    DEL_COUNTRY : "api/metadata/deleteCountry?id=",
    CREATE_RCC: "api/metadata/createRcc",
    READ_RCC: "api/metadata/getAllRccs",
    DEL_RCC : "api/metadata/deleteRcc?id=",
    CREATE_AREA: "api/metadata/createArea",
    READ_AREA: "api/metadata/getAllAreas",
    DEL_AREA: "api/metadata/deleteAreaById?id=",
    CREATE_DIST: "api/metadata/createDistrict",
    READ_DIST: "api/metadata/getAllDistricts",
    DEL_DIST : "api/metadata/deleteDistrict?id=",
    CREATE_LA: "api/metadata/createLocalAssembly",
    READ_LA: "api/metadata/getAllLocalAssemblies",
    DEL_LA : "api/metadata/deleteLocalAssembly?id=",
    EDIT_BLOCK:"api/metadata/UpdateBlockById?id=",
    EDIT_COUNTRY:"api/metadata/updateCountry?id=",
    EDIT_RCC:"api/metadata/updateRcc?id=",
    EDIT_AREA:"api/metadata/updateAreaById?id=",
    EDIT_DIST:"api/metadata/updateDistrict?id=",
    EDIT_LA:"api/metadata/updateLocalAssebly?id="
};