import React, { useRef, useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import AuthContext from "../../../contexts/AuthProvider";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from "react-loader-spinner";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../../../assets/coplogoblack.png';
import background from '../../../assets/image.png';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { motion } from "framer-motion";



const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '100vh',
        minWidth: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  palette: {
    primary: {
      main: '#000000', // Black
    },
    secondary: {
      main: '#FFFFFF', // White
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    h6: {
      fontSize: '1rem',
      letterSpacing: '0.1rem',
      color: '#000000',
      textShadow: '2px 2px 4px rgba(255, 255, 255, 0.2)',
    },
  },
});


const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  useEffect(() => {
    setAuth(localStorage);
    document.title = "CMS - Login";
    setLoading(true);
    setTimeout(()=>setLoading(false), 1000);
    // userRef.current.focus();
    localStorage.clear();
  }, []);
  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const details = {
      username: username,
      password: password,
      grant_type: "password",
      isLoggedIn: true,
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    // console.log(formBody);

    await axios
      // .post("https://testcopukservices.azurewebsites.net/oauth/token", formBody, {
        .post("https://copuk.azurewebsites.net/oauth/token", formBody, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const accessToken = response?.data.access_token;
        const expires_in = response?.data.expires_in;

        localStorage.setItem("ACCESS_TOKEN", accessToken);
        localStorage.setItem("EXPIRES_IN", expires_in);
        localStorage.setItem("USER", username);
        Cookies.set("COP_USER", accessToken, expires_in);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        setAuth({ user, accessToken, expires_in });
        setUser("");
        setPassword("");
        setSuccess(true);
        setLoading(false);
        console.clear();
        navigate("/");
      })
      .catch((err) => {
        if (!err?.response) {
          setLoading(false);
          setErrMsg("No Server Response");
          setInfoMessage("Incorrect username or password. Please try again.");
        } else if (err.response?.status === 400) {
          setLoading(false);
          setErrMsg("Missing Username or Password");
          setInfoMessage("Incorrect username or password. Please try again.");
        } else if (err.response?.status === 401) {
          setLoading(false);
          setInfoMessage("Incorrect username or password. Please try again.");
          setErrMsg("Unauthorized");
        } else {
          setLoading(false);
          setInfoMessage("We are currently experienceing some issues. Please try again later or contact your administrator.");
          setErrMsg("Login Failed");
        }
        // errRef.current.focus();
      });
  };
  const handleForgotPasswordClick = () => {
    navigate("/forgotpassword");
  };

  return (
    <div>
      {loading ? ( <div style={{display:'flex', justifyContent:'center', height:'100vh', alignItems:'center'}}><div style={{textAlign:'center'}}><Grid
  visible={true}
  height="100"
  width="100"
  color="blue"
  ariaLabel="grid-loading"
  radius="10"
  wrapperStyle={{}}
  wrapperClass="grid-wrapper"
  /><p style={{color:'black', padding:'10%'}}>Loading...</p></div></div>
) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{backgroundImage: `url(${background})`, backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'}}
      className="hold-transition  d-flex"
    >
      
    <ThemeProvider theme={theme}>
      <Container  component="main" maxWidth="xs">
        <Paper elevation={5} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
          <img style={{ width: '10rem' }} src={logo} alt="Logo" />
          <Typography component="h6" variant="h6" sx={{ mb: 3 }}>
            Welcome! Log in to manage and oversee church operations.
          </Typography>
          
          <Accordion defaultExpanded={false} sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none', mt: 3 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ color: theme.palette.secondary.main }}
            >
              <Typography variant="h6">Announcements</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', color: 'blue' }}>
              <ul>
              <li>
              <Typography sx={{ fontSize: '0.8rem', lineHeight: '1.5', mb: 2 }}>
                Welcome to your Church Management System. Please use the username and password provided to login. If you are having difficulties logging in please reach out to the COPCMS Dev team or cyril.johnson@copuk.org
              </Typography>
              </li>

              </ul>
              {/* Add more announcement content as needed */}
            </AccordionDetails>
          </Accordion>
          {infoMessage && (
                  <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                    {infoMessage}
                  </Typography>
          )}
          <form>
            {/* Login Form */}
            <TextField
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              id="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    style={{ color: theme.palette.primary.main }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLogin}
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          </form>

          {/* Forgot Password Link */}
          <a
            onClick={handleForgotPasswordClick}
            variant="body2"
            style={{cursor:'pointer'}}
          >
            Forgot Password?
          </a>
        </Paper>
      </Container>
    </ThemeProvider>

    </motion.div>
    )}
    </div>
  );
};

export default Login;
