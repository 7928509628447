import React, { useRef, useState, useEffect } from "react";
import { Avatar, Input, Modals, Menu, MenuItem, Bar, Button, ShellBar, StandardListItem } from '@ui5/webcomponents-react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/coplogoblack.png';
import axios from "axios";
// import image from '../assets/profilepic.jpg';
import Cookies from "js-cookie";

function ShellBarComponent() {
  const navigate = useNavigate();
  const menuRef = useRef();
  const avatarRef = useRef();
  const showDialog = Modals.useShowDialog();
  const menuItemClick = (e) => {
    var selectedItem = e.detail.text;
    if (selectedItem === "Logout") {
      localStorage.clear();
      Cookies.remove("COP_USER");
      Cookies.remove("LOGGEDON_USER");
      delete  axios.defaults.headers.common['Authorization'];
      navigate("/login");
    } else if (selectedItem === "Settings") {
      navigate("/settings");
    }
  };
  const findInPage = function (e) {
      debugger;
      var searchText = e.currentTarget.value;
      var content = document.getElementById("root");
   }

  const isMobileDevice = function() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };
  const onShowCoPilot = function(){
    const{close}=
    showDialog({
      headerText: 'Currently Unavailable',
      state:"Information",
      children: "Your CMS Copilot is currently unavailable...",
      footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
    });
  }
  const [title, setTitle] = useState();

  useEffect(() => {
  }, []);



  return (
    <>
      <ShellBar
        showCoPilot="true"
        logo={<img style={{transform: 'scale(1.1)'}} src={logo} />}
        onLogoClick={function noRefCheck() { navigate('/'); }}
        // menuItems={<><StandardListItem data-key="1">CMS Launchpad</StandardListItem></>}
        onProfileClick={(e) => { menuRef.current.showAt(avatarRef.current) }}
        // primaryTitle="CMS Launchpad"
        profile={<Avatar ref={avatarRef}
          colorScheme="Accent10"
          icon="employee"
          shape="Circle"
          size="XS"
          style={{
            border: '2px rgb(4, 3, 150)', 
            background: 'transparent',
            width: '42px', 
            height: '42px',
            borderRadius: '50%'
          }}
        >
          {/* <img src={image} alt="Profile" /> */}
        </Avatar>}
        onCoPilotClick={onShowCoPilot}
        showSearchField={!isMobileDevice()}
        showNotifications="false"
        searchField={<Input style={{borderRadius:"20px"}} showClearIcon onInput={findInPage} />}
        showProductSwitch="false"
      />
      <Menu ref={menuRef} onItemClick={menuItemClick}>
        <MenuItem disabled="true" text="My Profile" icon="employee"/>
        <MenuItem text="Settings" disabled="true" icon="settings" />
        <MenuItem text="Logout" icon="decline" />
      </Menu>
 
    </>
  );
};

export default ShellBarComponent;