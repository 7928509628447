import React, { useEffect, useState } from 'react';
import apis from '../../apis/api';
import { motion } from 'framer-motion';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import ShellBarComponent from '../../components/ShellBarComponent';
import { BusyIndicator, Link, Text, ObjectPageSection, Bar, Page, Token, FormGroup, FormItem, MessageStrip, ObjectStatus, ObjectPage, Button, Form, FlexBox, Label, DynamicPageHeader, DynamicPageTitle } from '@ui5/webcomponents-react';
import { formatDateDDHHHYYYY, formatTime } from "../../utility/Commons";
const EventDetail = ({ selectedItem }) => {
    const [busy, setBusy] = useState(true);
    const [event, setEvent] = useState({ "id": "", "name": "", "description": "", "type": "", "location": "", "recurrence": "", "recurrenceType": "", "parentEventId": "", "poster": "", "": "", "lastJobCycle": "", "capacity": "", "": "", "waitListEnabled": false, "creationState": 0, "createdBy": "", "lastChangedBy": "" });
    const [location, setLocation] = useState({ "id": "", "type": "", "locationName": "", "internalLocationId": "", "locationAddress": "" });
    const [address, setAddress] = useState({ "addressId": "", "memberId": "", "addressLine1": "", "addressLine2": "", "locality": "", "city": "", "region": "", "postalCode": "", "country": "" });
    const [recurrence, setRecurrence] = useState({ "id": "", "eventId": "", "startTime": "", "endTime": "", "startDate": "", "endDate": "", "type": "", "sunday": false, "monday": false, "tuesday": false, "wednesday": false, "thursday": false, "friday": false, "saturday": false });
    const navigate = useNavigate();
    const { eventId } = useParams();
    const locationObj = useLocation();
    const { eventData } = locationObj.state || {};
    const [occurenceText, setOccurenceText] = useState();
    

   
    
    const readEventDetail = async function (id) {
        var links = [], emails = [], phones = [];
        var recurrenceData = null;
        var buildRecurText = "";
        var days = [];
        setBusy(true);
        const response = await apis.get("/api/getEventDetails?EventId=" + id, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN'),
            }
        });
        // debugger;
        setEvent(response.data.event);
        setLocation(response.data.location);
       
        setAddress(response.data.address);
        setRecurrence(response.data.recurrence);
       
        
        setOccurenceText (response.data.occurenceText)
        recurrenceData = response.data.recurrence;
        if (recurrenceData.type === "R") {
            buildRecurText = "Occurs every ";
            if (recurrenceData.sunday) {
                days.push("Sunday");
            }
            if (recurrenceData.monday) {
                days.push("Monday");
            }
            if (recurrenceData.tuesday) {
                days.push("Tuesday");
            }
            if (recurrenceData.wednesday) {
                days.push("Wednesday");
            }
            if (recurrenceData.thursday) {
                days.push("Thursday");
            }
            if (recurrenceData.friday) {
                days.push("Friday");
            }
            if (recurrenceData.saturday) {
                days.push("Saturday");
            }
            if (days.length > 0) {
                if (days.length === 1) {
                    buildRecurText = buildRecurText + days[0] + " between " + formatTime(recurrenceData.startTime) + " and " + formatTime(recurrenceData.endTime);
                } else {
                    var lastDay = days.pop();
                    days.forEach((day) => {
                        buildRecurText = buildRecurText + day + ", "
                    });
                    buildRecurText = buildRecurText + lastDay + " between " + formatTime(recurrenceData.startTime) + " and " + formatTime(recurrenceData.endTime);
                }
            }
        } else if (recurrenceData.type === "S" || recurrenceData.type === "") {
            buildRecurText = "The event happens on " + formatDateDDHHHYYYY(recurrenceData.startDate) + " between " + formatTime(recurrenceData.startTime) + " and " + formatTime(recurrenceData.endTime);
        }
        setOccurenceText(buildRecurText);
        
        
        setTimeout(() => {
            setBusy(false);
        }, 2000);
    };
    const goBack = () => {
        navigate("manageEventNew");
    };
    const readData = function(id){
        readEventDetail(id)
    };
    useEffect(() => {
        if (selectedItem?.id) {
            readData(selectedItem.id);
        }
    }, [selectedItem?.id]);

    const [phones, setPhones] = useState([]);
    const [emails, setEmails] = useState([]);
    const [links, setLinks] = useState([]);

    async function getAllEventContactDetails(eventId) {
        try {
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem('ACCESS_TOKEN')
            };
    
            const response = await apis.get(`eventContacts?eventId=${eventId}`, { headers });
            
            console.log('API Response:', response.data); // For debugging
    
            const tempPhones = [];
            const tempEmails = [];
            const tempLinks = [];
    
            response.data.forEach(contact => {
                switch (contact.contactDataType) {
                    case "Phone": // Make sure this matches the API response
                        tempPhones.push(contact.contactData);
                        break;
                    case "E-Mail": // Make sure this matches the API response
                        tempEmails.push(contact.contactData);
                        break;
                    case "Link": // Make sure this matches the API response
                        tempLinks.push(contact.contactData);
                        break;
                    default:
                        console.log('Unhandled contact type:', contact.contactDataType); 
                }
            });
    
            setPhones(tempPhones);
            setEmails(tempEmails);
            setLinks(tempLinks);
        } catch (error) {
            console.error('Error fetching event contact details:', error);
        }
    }
    
    // Call this function with the correct eventId
    useEffect(() => {
        debugger;
        if (selectedItem?.id) {
            getAllEventContactDetails(selectedItem.id);
        }
    }, [selectedItem?.id]);
    


    return (
        <React.Fragment>
        <motion.div initial={{ opacity: 0 }} animate={{ width: "100%", opacity: 1 }} exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}>
            <ShellBarComponent />
            <Page header={<Bar startContent={<Button icon="nav-back" title="Go back" onClick={goBack} />}><Label>Manage Events</Label></Bar>} style={{ height: "90vh" }}>
        <BusyIndicator active={busy} style={{
            height: '100%',
            width: '100%'
        }}>
            {!busy &&
                <ObjectPage
                    // footer={<Bar design="FloatingFooter" endContent={<><Button design="Positive">Accept</Button><Button design="Negative">Reject</Button></>} />}
                    headerContent={<DynamicPageHeader>
                        {(occurenceText !== "") && <MessageStrip stule={{ width: "100%" }}>{occurenceText}</MessageStrip>}
                        <FlexBox direction='Column' alignItems="Center" wrap="Wrap">
                            <FlexBox direction='Column'><Text wrapping="true">{event.description}</Text></FlexBox>
                            <FlexBox direction="Row"><Link href={"https://www.google.com/search?q=The Church Of Pentecost "+location.locationName} target="_blank">{location.locationName} ({(location.type === "EX") ? "External" : (location.type === "LA") ? "Local Assembly" : (location.type === "DI") ? "District" : (location.type === "AR") ? "Area" : ""})</Link>
                                <Link>{address.addressLine1}, {address.city}</Link>
                                <Link>{address.country} - {address.postalCode}</Link>
                            </FlexBox></FlexBox>
                    </DynamicPageHeader>}
                    headerContentPinnable
                    headerTitle={<DynamicPageTitle header={event.name} actions={<ObjectStatus state="Success">{(event.recurrenceType === "R") ? "Recurring" : "Single Occurance"}</ObjectStatus>}></DynamicPageTitle>}

                    onPinnedStateChange={function ka() { }}
                    onSelectedSectionChange={function ka() { }}
                    onToggleHeaderContent={function ka() { }}
                    selectedSectionId="goals"
                    showHideHeaderButton
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                >
                    <ObjectPageSection
                        aria-label="Date & Time"
                        id="Location"
                        titleText="Date & Time"
                    >
                        <Form
                            columnsL={1}
                            columnsM={1}
                            columnsXL={1}
                            labelSpanL={2}
                            labelSpanM={2}
                            labelSpanXL={2}
                            style={{
                                alignItems: 'baseline'
                            }}
                        >
                            
                            <FormItem label="Start Date">
                                <Text>
                                    {formatDateDDHHHYYYY(recurrence.startDate) }
                                </Text>
                            </FormItem>
                            <FormItem label="End Date">
                                <Text>
                                    {formatDateDDHHHYYYY(recurrence.endDate)}
                                </Text>
                            </FormItem>
                            <FormItem label="Start Time">
                                <Text>
                                    {formatTime(recurrence.startTime)}
                                </Text>
                            </FormItem>
                            <FormItem label="End Time">
                                <Text>
                                    {formatTime(recurrence.endTime)}
                                </Text>
                            </FormItem>
                            {/* {(recurrence.type === "R")&&<div> */}
                            <FormItem label="Occurs Every">
                                {recurrence.sunday && <Token
                                    readonly="true"
                                    text="Sunday"
                                />}
                                {recurrence.monday && <Token
                                    readonly="true"
                                    text="Monday"
                                />}
                                {recurrence.tuesday && <Token
                                    readonly="true"
                                    text="Tuesday"
                                />}
                                {recurrence.wednesday && <Token
                                    readonly="true"
                                    text="Wednesday"
                                />}
                                {recurrence.thursday && <Token
                                    readonly="true"
                                    text="Thursday"
                                />}
                                {recurrence.friday && <Token
                                    readonly="true"
                                    text="Friday"
                                />}
                                {recurrence.saturday && <Token
                                    readonly="true"
                                    text="Saturday"
                                />}
                            </FormItem>
                            {/* </div>} */}
                        </Form>
                    </ObjectPageSection>
                    <ObjectPageSection
                        aria-label="Location"
                        id="Location"
                        titleText="Location">
                        <Form
                            columnsL={1}
                            columnsM={1}
                            columnsXL={1}
                            labelSpanL={2}
                            labelSpanM={2}
                            labelSpanXL={2}
                            style={{
                                alignItems: 'baseline'
                            }}
                        >
                            <FormItem label="Location Type">
                                <Text>
                                    {(location.type === "EX") ? "External" : (location.type === "LA") ? "Local Assembly" : (location.type === "DI") ? "District" : (location.type === "AR") ? "Area" : ""}
                                </Text>
                            </FormItem>
                            <FormItem label="Location Name">
                                <Text>
                                    {location.locationName}
                                </Text>
                            </FormItem>
                            <FormItem label="Address Line 1">
                                <Text>
                                    {address.addressLine1}
                                </Text>
                            </FormItem>
                            <FormItem label="Address Line 2">
                                <Text>
                                    {address.addressLine2}
                                </Text>
                            </FormItem>
                            <FormItem label="Locality">
                                <Text>
                                    {address.locality}
                                </Text>
                            </FormItem>
                            <FormItem label="City">
                                <Text>
                                    {address.city}
                                </Text>
                            </FormItem>
                            <FormItem label="Region">
                                <Text>
                                    {address.region}
                                </Text>
                            </FormItem>
                            <FormItem label="Country & Postal Code">
                                <Text>
                                    {address.country} - {address.postalCode}
                                </Text>
                            </FormItem>
                        </Form>
                    </ObjectPageSection>
                    <ObjectPageSection
                        aria-label="Contact"
                        id="Contact"
                        titleText="Contact Details">
                        <Form
                            columnsL={1}
                            columnsM={1}
                            columnsXL={1}
                            labelSpanL={2}
                            labelSpanM={2}
                            labelSpanXL={2}
                            style={{
                                alignItems: 'baseline'
                            }}
                        >
                            
                            <FormGroup titleText="Phone Numbers">
                                {phones.map((phone, index) => (
                                    <FormItem label={`Phone Number ${index + 1}`} key={index}>
                                        <Text>{phone}</Text> 
                                    </FormItem>
                                ))}
                            </FormGroup>

                            <FormGroup titleText="E-mail">
                                {emails.map((email, index) => (
                                    <FormItem label={`Email ${index + 1}`} key={index}>
                                        <Text>{email}</Text> 
                                    </FormItem>
                                ))}
                            </FormGroup>

                            <FormGroup titleText="Links">
                                {links.map((link, index) => (
                                    <FormItem label={`Link ${index + 1}`} key={index}>
                                        <Text>{link}</Text> 
                                    </FormItem>
                                ))}
                            </FormGroup>

                        </Form>
                    </ObjectPageSection>
                </ObjectPage>
            }
                    {/* <div className="detail-page">
                        <h2>Detail Page</h2>
                            <img style={{ maxWidth: '100%', maxHeight: '400px', margin: '10px 0' }} />
                        <p>Selected Item</p>
                    </div> */}
        </BusyIndicator>
         </Page>
         </motion.div>
     </React.Fragment>
    );
};

export default EventDetail;
