import React, { useRef, useState, useEffect } from "react";
import MemberFilterLayout from '../../components/MemberFilterLayout';
import { Token, Toast, Form, Modals, FormItem, Input, Page, Button, Bar, Label, Title, TableColumn, Dialog, MessageStrip, Table, TableRow, Text, TableCell, FormGroup } from '@ui5/webcomponents-react';
import { Link, useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import MemberListLayout from '../../components/MemberListLayout';
import "@ui5/webcomponents-icons/dist/nav-back.js";
import apis from '../../apis/api';
const USERS_URL = "api/accounts/users";
const DELETE_USER = "api/accounts/user/";
function ManageUsersLayout(props) {
  const toastRef = useRef();
  const hiddenRef = useRef();
  const showToast = () => {
    toastRef.current.show();
  };
  const [message, setMessage] = useState("");
  const showDialog = Modals.useShowDialog();
  const url = '/api/Accounts/ForgetPassword?email=';
  const verifyUrl = '/api/Accounts/ConfirmEmail?email=';
  const [userId, setUserId] = useState("");
  const userIdEmailInput = useRef();
  const [memberDataResponse, setResponse] = useState([]);
  const assignMemberDialog = useRef();
  const memberListDialog = useRef();
  const [userList, setUserList] = useState([]);
  const entityUser = useRef();
  const selectMember = () => {
    memberListDialog.current.show();
  };
  const onFilterGo = (filterJson) => {
    loadMemberData(filterJson);
  };
  const loadMemberData = async (filters) => {
    var payload = filters;
    apis.post('api/MemebershipManager/readMembers?returnType=L',payload,{
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
          }
    }).then(response =>{
        setResponse(response.data);
    }).catch(err =>{
        console.log(err);
    })
  };
  const fetchData = async () => {
    const response = await apis.get(USERS_URL, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
      },
    });
    setUserList(response.data);
    // console.log(response.data);
    // console.log(userList);
  }
  const listDialogClose = () => {
    memberListDialog.current.close();
  };
  const dialogClose = () => {
    assignMemberDialog.current.close();
  };
  const onUserMemberAssign = async (e) => {
    var payload = {
      "Username": userIdEmailInput.current.value,
      "MemberId": e.currentTarget.id
    };
    try{

      const response = await apis.post("api/MemberUsers", payload, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        }
      });
      dialogClose();
      setMessage('Member assignment successfull');
    }
    catch(err){
      if (!err?.response) {
        setMessage('No Server Response');
      } else if (err.response?.status === 400) {
        setMessage('Bad Request');
      } else {
        setMessage('Server Error');
      }
    }
    showToast();
  };
  const onDeleteItem = async function(e){
    var recId = e.currentTarget.id;
    try{
      const response = await apis.delete(DELETE_USER+recId, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem('ACCESS_TOKEN')
        }
      });
      fetchData();
      setMessage('User Deleted');
    }
    catch(err){
      if (!err?.response) {
        setMessage('No Server Response');
      } else if (err.response?.status === 400) {
        setMessage('Email or Username not available');
      } else {
        setMessage('Registration Failed')
      }
    }
    showToast();
  };
  useEffect(() => {
    fetchData(); //children function of interest
  }, [props.refresh]);
  useEffect(() => {
    try {
      fetchData();
    } catch (err) {
      if (!err.response) {

        setMessage("No Server Response");
      } else if (err.response.status === 400) {

        setMessage("Bad request.");
      } else if (err.response.status === 401) {

        setMessage("Authorization has been denied for this request.");
      } else {

        setMessage("Failed to fetch bloc data");
      }
      showToast()
    }
  }, []);
  const onAssignMemberId = (e) => {
    var id = e.target.id;
    setUserId(id);
    assignMemberDialog.current.show();
  };
  const onVerifyEmailTrigger = (e) => {
    var id = e.target.id;
    try{
      const response = apis.get(verifyUrl+id);
      response.then(console.log(response));
      }
      catch(e){
        console.log(e);
      }
      const{close}=
      showDialog({
        headerText: 'Email Verification Triggered',
        children: "An email verification link has been sent to the users email",
        footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
      });
  };
  const onResetPasseword = (e) => {
    var id = e.target.id;
    try{
      const response = apis.get(url+id);
      response.then(console.log(response));
      }
      catch(e){
        console.log(e);
      }
      const{close}=
      showDialog({
        headerText: 'Reset Password',
        children: "A reset password email has been sent to the registered email address. Follow the steps to reset your password.",
        footer: <Bar endContent={<Button onClick={() => close()}>Close</Button>} />
      });
  };
  return (
    <>
<Page
style={{height: '500px'}}>
  <Bar endContent={<Button design="Transparent" onClick={fetchData}>Refresh</Button>}></Bar>
    <Table 
      columns={<><TableColumn ><Label>Id</Label></TableColumn>
      <TableColumn ><Label>Email</Label></TableColumn>
      <TableColumn demandPopin="true" minWidth={800}><Label>Roles</Label></TableColumn>
      <TableColumn demandPopin="true" minWidth={800}><Label>Reset Password</Label></TableColumn>
      <TableColumn demandPopin="true" minWidth={800}><Label>Trigger Verification</Label></TableColumn>
      <TableColumn demandPopin="true" minWidth={800}><Label>Assign Member</Label></TableColumn>
      <TableColumn demandPopin="true" minWidth={800}><Label>Delete</Label></TableColumn></>}
      onLoadMore={function noRefCheck(){}}
      onPopinChange={function noRefCheck(){}}
      onSelectionChange={function noRefCheck(){}}
    >
    {userList && userList.map(row => {
        return (
        <TableRow>
            <TableCell style={{textAlign:'start'}}>
            <Text wrapping="true">
            {row.userName}
            </Text>
            </TableCell>
            <TableCell style={{textAlign:'start'}}>
            <Text wrapping="true">
            {row.email}
            </Text>
            </TableCell>
            <TableCell style={{textAlign:'start'}}>
            {row.roles && row.roles.map(row => {
             return (
              <Token readonly="true" text={row}/>
             );
            })}
            </TableCell>
            <TableCell style={{textAlign:'start'}}>
            <Button id={row.userName} onClick={onResetPasseword}>
            Reset Password
            </Button>
            </TableCell>
            <TableCell style={{textAlign:'start'}}>
              <Button id={row.userName} onClick={onVerifyEmailTrigger}>
              Verify Email
              </Button>
            </TableCell>
            <TableCell style={{textAlign:'start'}}>
            <Button id={row.userName} onClick={onAssignMemberId} design="Emphasized">
            Assign Member Id
            </Button>
            </TableCell>
            <TableCell style={{textAlign:'start'}}>
            <Button id={row.id} onClick={onDeleteItem} design="Negative">
            Delete
            </Button>
            </TableCell>
        </TableRow>
        );
    })}
 
</Table>
{ReactDOM.createPortal(
    <Dialog stretch="true" draggable="true" resizable="true" ref={memberListDialog} 
    header={<Bar endContent={<Button design="Negative" onClick={listDialogClose}>x</Button>}><Title>Assign Member Id</Title></Bar>}
    footer={<Bar design="Footer" 
     endContent={<Button design="Emphasized" >Unassign</Button>}/>}>
      <MessageStrip>
          Use the filters to select a member to assign to {userId}
      </MessageStrip>
      <MemberFilterLayout parentCallBack = {onFilterGo} count = {memberDataResponse.length}/>
      <div style={{height:"2%"}}/>
      <MemberListLayout closeDialog = {listDialogClose} memberArray = {memberDataResponse} source="OrgUnit" returnRef = {entityUser} returnHiddenRef={hiddenRef}/>
      <Toast ref={toastRef}>{message}</Toast>
    </Dialog>, document.body)}
    {ReactDOM.createPortal(
    <Dialog stretch="true" draggable="true" resizable="true" ref={assignMemberDialog} 
    header={<Bar endContent={<Button  design="Negative" onClick={dialogClose}>x</Button>}><Title>Assign Member Id</Title></Bar>}
    footer={<Bar design="Footer" 
     endContent={<Button design="Emphasized" ref={hiddenRef} onClick={onUserMemberAssign}>Assign Member</Button> }/>}>
      <MessageStrip>
          Click on select member to assign a member profile to {userId}
      </MessageStrip>
      <Form style={{padding: "1%",alignItems: 'center'}}>
          <FormGroup titleText="Basic Details">
              <FormItem label="Email / User Id">
                  <Input value={userId}  ref={userIdEmailInput} placeholder="Email / User Id" style={{width: '50%'}} />
              </FormItem>
              <FormItem label="Member Profile">
                  <Input ref={entityUser} disabled = "true" style={{width: '35%'}} value={"Select Member"}/>
                  <Button onClick={selectMember} style={{width: '15%'}}>Select Member</Button>
              </FormItem>
              </FormGroup>
          </Form>
      <div style={{height:"2%"}}/>
      <Toast ref={toastRef}>{message}</Toast>
    </Dialog>, document.body)}
    <Toast ref={toastRef}>{message}</Toast>
  </Page>
  </>
  );
}

export default ManageUsersLayout;
